import React, { useState, useEffect, useContext } from 'react';
import AppContext from "../../context/appContext";
import TitleSection from "../../components/shared/TitleSection/titleSection";
import { getCurrentAuthenticatedUser } from '../../utils/utils';
import ConciergeScreen from '../Concierge/Concierge.screen';
import SubHeaderComponent from '../../components/shared/Subheader/subHeader.component';
import HeaderBottom from '../../components/shared/HeaderBottom/HeaderBottom.component';
import { Link, useNavigate } from 'react-router-dom';

interface IConciergeInnerScreenProps {
  data?: any;
};

const ConciergeInnerScreen = ({ data }: IConciergeInnerScreenProps) => {
  const { concierge } = useContext(AppContext);
  const [somaUser, setSomaUser] = useState<any>({});
  const [token, setToken] = useState<string>('');
  const navigate = useNavigate();

  const handlePress = async (from: string) => {
    try {
      let msj = 'I would like to ';
      let action = '';
      const { name, lastName, _id } = somaUser;

      switch (from) {
        case 'detailShopping':
        case 'handsFreeShopping':
          action = 'book a personal shopper.';
          break;
        case 'specialAssistance':
          action = 'require a special assistance.';
          break;
        default:
          action = 'get more information.';
          break;
      }

      const phoneNumber = '+525566097673'; // Reemplaza esto con el número real
      const whatsappUrl = `whatsapp://send?phone=${phoneNumber}&text=¡Hi! ${msj} ${action} \nMy name is ${(name + ' ' + lastName) || ''} - ${_id || ''}`;
      await window.open(whatsappUrl);
    } catch (error) {
      console.log('Error to open WhatsApp:', error);
    }
  };

  const getAuth = async () => {
    const authUser = await getCurrentAuthenticatedUser();
    return {
      somaUser: authUser?.somaUser || {},
      token: authUser?.jwtToken || '',
    };
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getAuth().then((authData) => {
      const { somaUser, token } = authData;
      setSomaUser(somaUser);
      setToken(token);
    });
  }, []);

  return (<section className="dashboard-layout">
    <HeaderBottom
      children={
        <Link to="#" className="back-link" onClick={goBack}>
          <span className="material-icons-outlined">chevron_left</span>
          Regresar
        </Link>
      }
    />
    <section className="module">
      <div className="container">
        <div className="row">
          <div className='col-12 mx-auto'>
            {concierge?.subtitle && <TitleSection text={concierge?.subtitle} />}
            <article className="mb-5">
              {concierge?.title && <h1 className="h3 mb-3">{concierge?.title}</h1>}
              {concierge?.description && (
                <p className='mb-4'>{concierge?.description}</p>
              )}
              {concierge?.button && <button className="btn btn--type1 btn--100" onClick={concierge?.button?.onClick} > {concierge?.button?.label}</button>}
            </article>
            {concierge?.content && (
              <article className="concierge-inner mb-5 py-4">
                {concierge?.content?.title && (
                  <span className='h3 mb-6'>{concierge?.content?.title}</span>
                )}
                {concierge?.content?.description && (
                  <p>{concierge?.content?.description}</p>
                )}
              </article>
            )}
          </div>
        </div>
      </div>
    </section>
  </section >);
}

export default ConciergeInnerScreen; 