import React from "react";

interface InfoModalProps {
  data: {
    title?: string;
    image?: string;
    description?: string;
    show: boolean;
    setShow: any;
  };
}

// This modal works locally
const InfoModal = ({ data }: any) => {
  const { title, image, description, show, setShow } = data;

  const handleClose = async () => {
    setShow(false);
  };

  if (!show) return null;

  return (
    <div className="modal">
      <div className="modal__inner">
        <button type="button" className="modal__close" onClick={handleClose}>
          <img src="assets/images/icon/close.svg" alt="close" />
        </button>
        <div className="modal__body">
          {data?.attributes?.image?.data?.attributes?.url && (
            <figure className="modal__image">
              <img src={data?.attributes?.image?.data?.attributes?.url} alt="Onboarding" />
            </figure>
          )}
          {data?.attributes?.Title && <h3>{data?.attributes?.Title}</h3>}
          {data?.attributes?.description && <p>{data?.attributes?.description}</p>}
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
