import { useContext } from "react";
import AppContext from "../../../context/appContext";
import { NavLink } from "react-router-dom";
import { drawerOptions, menuOptions } from "./DrawerData";
import { signOut } from "aws-amplify/auth";
import { deleteSession } from "../../../utils/helpers";
import { hideHeader, routeList } from "../../../utils/validations";

interface DrawerProps {
  updateUserInfo: (info: any) => void;
  showDrawer?: boolean;
}

const Drawer = (props: DrawerProps) => {
  const { userInfo, handleUserInfo, showDrawer, handleShowDrawer } = useContext(AppContext);
  const isMobile = window.innerWidth <= 768;

  const handleOptionOnclick = () => {
    handleShowDrawer(false);
  };

  const handleOnClick = (event: any) => {
    event.preventDefault();
    handleShowDrawer(true);
  };

  const handleLogout = async () => {
    signOut();
    deleteSession();
    handleUserInfo(null);
    localStorage.removeItem("userInfo");
    window.location.href = "/login";
  };

  const _renderMenuOption = (options: any) =>
    options.map((option: any, index: number) => (
      <li key={index}>
        <NavLink
          to={option.slug}
          onClick={handleOptionOnclick}
          className="option"
        >
          {option.image && (
            <img src={option.image} alt={option.name || "Menú option"} />
          )}
          {option.name && <span>{option.name}</span>}

          {option.type && option.type === "notifications" && (
            <>
              {(userInfo?.notificationCount || 0) >= 1 && (
                <span
                  className="notification-counter"
                  data-notification={userInfo?.notificationCount || 0}
                ></span>
              )}
            </>
          )}
        </NavLink>
      </li>
    ));

  if (!userInfo || hideHeader(routeList)) return null;

  return (
    <>
      {/* Drawer */}
      <aside className="drawer">
        <nav className="drawer-nav">
          <button
            className="burger-button"
            type="button"
            onClick={handleOnClick}
          >
            <img src="/assets/images/icon/burger.svg" alt="Menu" />
          </button>

          {isMobile && (
            <figure className="drawer-logo">
              <NavLink
                to={"/events"}
                onClick={handleOptionOnclick}
                className="drawer-menu-logo"
              >
                <img src="/assets/images/logo/soma-white.svg" alt="SOMA+" />
              </NavLink>
            </figure>
          )}

          <ul className="drawer-list">
            {_renderMenuOption(drawerOptions)}
          </ul>
        </nav>

        {!isMobile && (
          <figure className="drawer-logo">
            <img src="/assets/images/logo/iso.svg" alt="SOMA+" />
          </figure>
        )}
      </aside>

      {/* Drawer menu */}
      <div className={`drawer-menu-container ${showDrawer ? "open" : ""}`}>
        <div className="drawer-menu">
          <nav className="drawer-menu-nav">
            <ul className="drawer-menu-list">
              <li>
                <NavLink
                  to={"/"}
                  onClick={handleOptionOnclick}
                  className="drawer-menu-logo"
                >
                  <img src="/assets/images/logo/iso.svg" alt="SOMA+" />
                </NavLink>
              </li>
              {_renderMenuOption(menuOptions)}
              <li>
                <button
                  className="drawer-menu-logout"
                  type="button"
                  onClick={handleLogout}
                >
                  Cerrar sesión
                </button>
              </li>
            </ul>
          </nav>
        </div>
        <div className="drawer-menu-overlay" onClick={() => handleShowDrawer(false)}></div>
      </div>
    </>
  );
};

export default Drawer;
