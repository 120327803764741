import { useLocation, useNavigate } from "react-router-dom";
import SubHeaderComponent from "../../../../components/shared/Subheader/subHeader.component";

const AuthSuccessScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = () => {
    navigate("/signup-complete", {
      state: location.state
    });
  };

  return (
    <>
      <SubHeaderComponent isBackButton buttonText={"Regresar"} href={""} />
      <section className="auth-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-12 mx-auto">
              <div className="auth-head">
                <figure className="auth-head__icon">
                  <img
                    src="/assets/images/icon/check-success.svg"
                    alt="Success"
                  />
                </figure>
                <h1 className="h3 text-center">¡Felicidades!</h1>
              </div>
              <div className="auth-body">
                <article>
                  <p className="h3 text-center">
                    Tienes un año gratis, cortesía de la casa.
                  </p>
                  <p className="h3 text-center">
                    Estás listo para tu registro.
                  </p>
                </article>
                <div className="form-row row--center row--last">
                  <button className="btn btn--type1" onClick={handleSubmit}>
                    Siguiente
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AuthSuccessScreen;
