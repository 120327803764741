import React, { useEffect, useState, useContext } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import SignUpCodeScreen from "./pages/Auth/SignupCode/signupCode.screen";
import "bootstrap/dist/css/bootstrap.min.css";
import "./sass/App.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AppContext from "./context/appContext";
import AuthSuccessScreen from "./pages/Auth/Authalert/Success/authSuccess.screen";
import SignUpCompleteScreen from "./pages/Auth/SignupComplete/SignupComplete.screen";
import LoginScreen from "./pages/Auth/Login/Login.screen";
import LogoutScreen from "./pages/Auth/Logout/logout.screen";
import AuthCodeScreen from "./pages/Auth/AuthCode/AuthCode.screen";
import AuthCodeSuccessScreen from "./pages/Auth/Authalert/Success/codeSuccess.screen";
import BrandsExperienceScreen from "./pages/Auth/Favorites/Brand/brand.screen";
import PlacesExperienceScreen from "./pages/Auth/Favorites/PlacesToEat/places.screen";
import StudiosExperienceScreen from "./pages/Auth/Favorites/Studios/studios.screen";
import InteriorsExperienceScreen from "./pages/Auth/Favorites/Interiors/interiors.screen";
import NewProfileScreen from "./pages/Auth/NewProfile/newProfile.screen";
import RecoverStep1Screen from "./pages/Auth/RecoverPassword/RecoverStep1.screen";
import RecoverStep1SuccessScreen from "./pages/Auth/Authalert/Success/recoverStep1Success";
import RecoverStep2Screen from "./pages/Auth/RecoverPassword/RecoverStep2.screen";
import RecoverStep2SuccessScreen from "./pages/Auth/Authalert/Success/recoverStep2Success";
import RecoverStep1ErrorScreen from "./pages/Auth/Authalert/Error/recoverStep1Error.screen";
import MembershipChooseScreen from "./pages/Membership/Options/options.screen";
import AuthCodeErrorScreen from "./pages/Auth/Authalert/Error/codeError";
import GeolocationExperienceScreen from "./pages/Auth/Settings/geolocation.screen";
import ProfileSuccessScreen from "./pages/Auth/Authalert/Success/profileSuccess.screen";
import DashboardScreen from "./pages/Dashboard/Dashboard.screen";
import NotificationsScreen from "./pages/Notifications/Notifications.screen";
import ValetParkingScreen from "./pages/ValetParking/ValetParking.screen";
import ValetSuccessScreen from "./pages/ValetParking/ValetAlert/Success/valetSuccess.screen";
import ConciergeScreen from "./pages/Concierge/Concierge.screen";
import BeautyScreen from "./pages/PromosLanding/Beauty.screen";
import ShoppingScreen from "./pages/PromosLanding/Shopping.screen";
import DinnerScreen from "./pages/PromosLanding/Dinner.screen";
import EntertainmentScreen from "./pages/PromosLanding/Entertainment.screen";
import StudiosScreen from "./pages/PromosLanding/Studios.screen";
import RequestScreen from "./pages/Request/Request.screen";
import RequestMovieScreen from "./pages/Request/RequestMovie.screen";
import PromoDetailScreen from "./pages/PromoDetail/PromoDetail.screen";
import ProfileScreen from "./pages/Profile/Profile.screen";
import EditInfoScreen from "./pages/Profile/EditInfo.screen";
import EditPhoneMailScreen from "./pages/Profile/EditPhoneMail.screen";
import EditPasswordScreen from "./pages/Profile/EditPassword.screen";
import EditMembershipScreen from "./pages/Profile/EditMembership.screen";
import AccountStatementsScreen from "./pages/Profile/AccountStatements.screen";
import AccountStatementDetailScreen from "./pages/Profile/AccountStatementDetail.screen";
import EditNotificationsScreen from "./pages/Profile/EditNotifications.screen";
import EditLocationScreen from "./pages/Profile/EditLocation.screen";
import SimpleContentScreen from "./pages/Content/SimpleContent.screen";
import ConciergeInnerScreen from "./pages/ConciergeInner/ConciergeInner.screen";
import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import "aws-amplify/auth/enable-oauth-listener";
import * as amplifyConfig from "./amplifyconfiguration.json";
import HeaderComponent from "./components/shared/Header/Header.component";
import Drawer from "./components/shared/Drawer/Drawer.component";
import EventsScreen from "./pages/Events/Events.screen";
import CategoryScreen from "./pages/Category/Category.screen";
import SubcategoryScreen from "./pages/Subcategory/Subcategory.screen";
import { socialAuthEvent } from "./utils/utils";
import AllEventsScreen from "./pages/AllEvents/AllEvents.screen";
import InteractiveMap from "./pages/InteractiveMap/InteractiveMap.screen";
import Layout from "./components/Layout/Layout";
import InactiveMembershipScreen from "./pages/InactiveMembership/InactiveMembership.screen";
import Settings from "./pages/settings/settings.screen";
import RequestStudioScreen from "./pages/Request/RequestStudio.screen";
import DetectDeviceScreen from "./pages/DetectDevice/DetectDevice.screen";

Amplify.configure(amplifyConfig);

function App() {
  const [showDrawer, setShowDrawer] = useState(
    useContext(AppContext).showDrawer
  );
  const [userInfo, setUserInfo] = useState(useContext(AppContext).userInfo);
  const [loadingAction, setLoadingAction] = useState(
    useContext(AppContext).loadingAction
  );
  const [concierge, setConcierge] = useState(useContext(AppContext).concierge);

  const ProtectedRoute = ({ user, children }: any) => {
    if (!user && !localStorage.getItem("userInfo")) {
      return <Navigate to="/" replace />;
    }

    if (user && user.activeMembership === false) {
      const { stripeCustomerId, nickname, stripeSubscription } = user;
      if (!stripeCustomerId || !nickname) {
        return <Navigate to="/new-profile" replace />;
      }
      if (stripeSubscription && stripeSubscription.length) {
        return <Navigate to="/inactive-membership" replace />;
      }
      if (stripeSubscription && !stripeSubscription.length) {
        return <Navigate to="/select-membership" replace />;
      }
      return children;
    }

    return children;
  };

  const NotRegisteredUser = ({ user, children }: any) => {
    if (user || localStorage.getItem("userInfo")) {
      return <Navigate to="/events" replace />;
    }

    return children;
  };

  useEffect(() => {
    Hub.listen("auth", async (evenData) => {
      await socialAuthEvent(
        evenData,
        () => false,
        () => false,
        (route: string) => (window.location.href = route)
      );
    });
  }, []);

  return (
    <AppContext.Provider
      value={{
        showDrawer: showDrawer,
        handleShowDrawer: setShowDrawer,
        loadingAction: loadingAction,
        handleLoadingAction: setLoadingAction,
        userInfo: userInfo,
        handleUserInfo: setUserInfo,
        concierge: concierge,
        handleConcierge: setConcierge,
      }}
    >
      <section className="app">
        <BrowserRouter>
          <Drawer
            updateUserInfo={(data) => {
              setUserInfo(data);
            }}
          />
          <Layout>
            {/* <HeaderComponent
              updateUserInfo={(data) => {
                setUserInfo(data);
              }}
            /> */}
            <Routes>
              <Route
                path="/"
                element={
                  <NotRegisteredUser user={userInfo}>
                    <SignUpCodeScreen />
                  </NotRegisteredUser>
                }
              />
              <Route
                path="/signup-success"
                element={
                  <>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <AuthSuccessScreen />
                  </>
                }
              />
              <Route
                path="/signup-complete"
                element={<SignUpCompleteScreen />}
              />
              <Route
                path="/login"
                element={
                  <NotRegisteredUser user={userInfo}>
                    <LoginScreen />
                  </NotRegisteredUser>
                }
              />
              <Route
                path="/detect-device"
                element={
                  <NotRegisteredUser user={userInfo}>
                    <DetectDeviceScreen />
                  </NotRegisteredUser>
                }
              />
              <Route path="/logout" element={<LogoutScreen />} />
              <Route path="/auth-code" element={<AuthCodeScreen />} />
              <Route
                path="/auth-code-success"
                element={<AuthCodeSuccessScreen />}
              />
              <Route
                path="/auth-code-error"
                element={<AuthCodeErrorScreen />}
              />
              <Route
                path="/favorites-experience"
                element={<BrandsExperienceScreen />}
              />
              <Route
                path="/favorites-places-to-eat"
                element={<PlacesExperienceScreen />}
              />
              <Route
                path="/favorites-studios"
                element={<StudiosExperienceScreen />}
              />
              <Route
                path="/favorites-interiors"
                element={<InteriorsExperienceScreen />}
              />
              <Route
                path="/experience-geolocation"
                element={<GeolocationExperienceScreen />}
              />
              <Route path="/new-profile" element={<NewProfileScreen />} />
              <Route
                path="/new-profile-success"
                element={<ProfileSuccessScreen />}
              />
              <Route
                path="/recover-password-step1"
                element={<RecoverStep1Screen />}
              />
              <Route
                path="/recover-password-step2"
                element={<RecoverStep2Screen />}
              />
              <Route
                path="/recover-password-step1-success"
                element={<RecoverStep1SuccessScreen />}
              />
              <Route
                path="/recover-password-step1-error"
                element={<RecoverStep1ErrorScreen />}
              />
              <Route
                path="/recover-password-step2-success"
                element={<RecoverStep2SuccessScreen />}
              />
              <Route
                path="/select-membership"
                element={<MembershipChooseScreen />}
              />
              <Route
                path="/events"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent />
                    <EventsScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/events/:category"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <AllEventsScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <DashboardScreen
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/concierge-inner"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <ConciergeInnerScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/notifications"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <NotificationsScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/valet-parking"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <ValetParkingScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/valet-parking-success"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <ValetSuccessScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/concierge"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <ConciergeScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/shopping"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <ShoppingScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/beauty"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <BeautyScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/dinner"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <DinnerScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/studios"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <StudiosScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":option/cinemex"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <EntertainmentScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":option/detail/:category/:id"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <PromoDetailScreen showInColumns={false} />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":option/request/:type"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <RequestScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":option/request/:type/:promo"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <RequestScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":option/request-studios/:type/:promo"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <RequestStudioScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":option/request/movie/:movieId"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent />
                    <RequestMovieScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/mi-perfil/"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent />
                    <ProfileScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/mi-perfil/info"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent />
                    <EditInfoScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/mi-perfil/phone-mail"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent />
                    <EditPhoneMailScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/mi-perfil/password"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <EditPasswordScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/mi-perfil/membership"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <EditMembershipScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/mi-perfil/statements"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <AccountStatementsScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/mi-perfil/statements/:id"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <AccountStatementDetailScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/mi-perfil/notifications"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <EditNotificationsScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/mi-perfil/location"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <EditLocationScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/settings"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <Settings />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/category/:id"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <CategoryScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/:option/:category/:subcategory"
                element={
                  <ProtectedRoute user={userInfo}>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <SubcategoryScreen />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/interactive-map"
                element={
                  <ProtectedRoute>
                    <HeaderComponent
                      updateUserInfo={(data) => {
                        setUserInfo(data);
                      }}
                    />
                    <InteractiveMap />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/inactive-membership"
                element={
                  <ProtectedRoute>
                    <InactiveMembershipScreen />
                  </ProtectedRoute>
                }
              />
              <Route path="/about" element={<SimpleContentScreen />} />
              <Route path="/terms" element={<SimpleContentScreen />} />
              <Route path="/privacy" element={<SimpleContentScreen />} />
            </Routes>
          </Layout>
        </BrowserRouter>
      </section>
    </AppContext.Provider>
  );
}

export default App;
