import React, { useState, useEffect } from "react";
import SubHeaderComponent from "../../../components/shared/Subheader/subHeader.component";
import { useNavigate } from "react-router-dom";
import { StripeProduct } from "../../../types/stripe";
import { IUser } from "../../../types/user";
import {
  checkAndAskForInviteCode,
  getCurrentAuthUserAttributes,
} from "../../../utils/utils";
import {
  getStripeProducts,
  newStripeCheckoutSession,
} from "../../../api/stripeApi";
import Swal from "sweetalert2";
import LoaderComponent from "../../../components/shared/Loader/Loader.component";

const MembershipChooseScreen = () => {
  const navigate = useNavigate();
  const [somaUser, setSomaUser] = useState<Partial<IUser>>({});
  const [token, setToken] = useState("");
  const [selected, setSelected] = useState("");
  const [products, setProducts] = useState<StripeProduct[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const setError = (error: string) => {
    console.log("error", error);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: error,
    });
  };

  const fetchUser = async () => {
    try {
      const user = await getCurrentAuthUserAttributes();
      if (!user) return navigate("/login");
      const { somaUser, jwtToken } = user;
      const { stripeSubscription } = somaUser;
      if (stripeSubscription && stripeSubscription.length) {
        const hasSomeActive = stripeSubscription.some(
          (sub) => sub.status === "active"
        );
        if (hasSomeActive) {
          // reload the page
          window.location.href = "/";
        }
      }
      await checkAndAskForInviteCode(somaUser, jwtToken);
      console.log("user", user);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStripeProducts = async () => {
    try {
      const user = await getCurrentAuthUserAttributes();
      if (!user) return navigate("/login");
      setSomaUser(user.somaUser);
      setToken(user.jwtToken);
      const products = await getStripeProducts(user?.jwtToken);
      if (products) {
        setProducts(products);
        setIsLoading(false);
      }
    } catch (error) {
      setError((error as any).message);
    }
  };

  const getStripeSubscription = async () => {
    try {
      if (!selected) return setError("Please select a membership");
      const body = {
        customer: somaUser.stripeCustomerId,
        price: selected,
        successUrl: `${window.location.origin}/mi-perfil/membership`,
      };
      const response = await newStripeCheckoutSession(token, body);
      if (response) {
        if (!response.sessionUrl) return setError("Error creating session");
        window.location.href = response.sessionUrl;
      }
    } catch (error) {
      setError((error as any).message);
    }
  };

  useEffect(() => {
    fetchStripeProducts();
    setTimeout(() => {
      fetchUser();
    }, 500);
  }, []);

  return (
    <>
      <SubHeaderComponent href={""} buttonText={"Regresar"} isBackButton />
      <section className="pt-5 mt-5 membership-layout">
        <div className="container">
          <div className="row membership--top">
            <div className="membership__col-left col-md-4 col-12">
              <div className="form-row">
                <h1 className="h3">
                  Obtén beneficios exclusivos y funciones adicionales con SOMA+.
                </h1>
              </div>
              {isLoading && (
                <div className="mb-4">
                  <LoaderComponent isLoading={isLoading} />
                </div>
              )}
              {products
                .map((product) =>
                  product.prices.data.filter((price) => {
                    const { metadata } = price;
                    if (metadata) {
                      const { payment_type } = metadata;
                      return (
                        payment_type === "one_time" ||
                        payment_type === "installments"
                      );
                    }
                    return false;
                  })
                )
                .flat() // Aplanamos el array de arrays
                .sort((a, b) => {
                  const { metadata: metadataA } = a;
                  const { metadata: metadataB } = b;
                  if (metadataA && metadataB) {
                    const { order: orderA } = metadataA;
                    const { order: orderB } = metadataB;
                    if (orderA && orderB) {
                      return orderA - orderB;
                    }
                  }
                  return 0;
                })
                .map((price, index) => {
                  const { metadata } = price;
                  return (
                    <div className="form-row" key={index}>
                      <div
                        className={`form-check check--left check--block ${
                          selected === price.id ? "active" : ""
                        }`}
                      >
                        <input
                          className="form-check-input"
                          type="radio"
                          name="membership"
                          value="yearly"
                          id={`membership-${index}`}
                          checked={selected === price.id}
                          onChange={() => setSelected(price.id)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`membership-${index}`}
                        >
                          {`${metadata.Description}`}
                        </label>
                      </div>
                    </div>
                  );
                })}
            </div>
            <aside className="membership__col-right col-md-8 col-12">
              <figure className="image">
                <img
                  src="/assets/images/elements/frame-membership.png"
                  alt="Membership"
                />
              </figure>
            </aside>
          </div>
          <div className="row membership--bottom">
            <div className="mx-auto mb-3 text-center col-md-8 col-12">
              <button
                type="button"
                className="btn btn--type1 btn--large"
                disabled={selected !== "" ? false : true}
                onClick={getStripeSubscription}
              >
                Continuar
              </button>
            </div>
            <div className="mx-auto text-center col-md-8 col-12">
              <a href="/dashboard" className="btn btn--type2 btn--large">
                Completar más tarde
              </a>
              <p className="mt-4 gray-color700">
                <small>
                  Renovación automática. Cancela al menos 24 horas antes de la
                  fecha de renovación.
                </small>
              </p>
            </div>
            <div className="col-12">
              <ul className="pb-5 list-terms">
                <li>
                  <a href="/terms">Términos de uso</a>
                </li>
                <li>
                  <a href="/privacy">Aviso de privacidad</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MembershipChooseScreen;
