import api from "./index";
import { IUserProfileFavorites } from "../types/favorites";

const commonHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const profileFavorites = async (
  body: Partial<IUserProfileFavorites>,
  token: string
) => {
  console.log("------ profileFavorites ------");
  const response = await api.post<IUserProfileFavorites>(
    `/brandsFavorites`,
    body,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const searchProfileFavorites = async (userId: string, token: string) => {
  const response = await api.post(
    `/brandsFavorites/search`,
    { userId },
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
