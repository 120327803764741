import React from 'react';

interface TitleSectionProps {
  text: string;
}

const TitleSection: React.FC<TitleSectionProps> = ({ text }) => {

  return (
    <div className="title-section">
      <h1 className="h5">{text}</h1>
    </div>
  );
};

export default TitleSection;
