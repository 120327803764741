import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { IUser } from "../../types/user";
import { getCurrentAuthenticatedUser } from "../../utils/utils";
import { getUrl } from "aws-amplify/storage";
import { updateUser } from "../../api/usersApi";
import PhotoPicComponent from "../../components/Form/PhotoPic/photoPic";
import HeaderBottom from "../../components/shared/HeaderBottom/HeaderBottom.component";
import AppContext from "../../context/appContext";
import SubHeaderComponent from "../../components/shared/Subheader/subHeader.component";

const ProfileScreen = () => {
  const [currentUser, setCurrentUser] = useState<Partial<IUser>>({});
  const { userInfo, handleUserInfo } = useContext(AppContext);

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
  };

  const handleImageSelected = (hasImage: boolean, imageSaved: string) => {
    setCurrentUser({
      ...currentUser,
      avatar: imageSaved,
    });
    handleSubmit(imageSaved);
  };

  const fetchImage = async (image: string) => {
    const signedUrl = await getUrl({
      key: image,
      options: {
        expiresIn: 3600, // 1 hour
      },
    });
    return signedUrl.url.toString();
  };

  const getCurrentUser = async () => {
    try {
      const result = await getCurrentAuthenticatedUser();
      if (result) {
        const { somaUser, jwtToken } = result;
        if (somaUser) {
          setCurrentUser({
            ...somaUser,
            token: jwtToken,
          });
          if (userInfo?.avatar) {
            fetchImage(somaUser.avatar).then((signedImage) => {
              setCurrentUser({
                ...somaUser,
                token: jwtToken,
                signedImage,
              });

              if (userInfo) {
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify({ ...userInfo, signedImage })
                );
                handleUserInfo({ ...userInfo, signedImage });
              }
            });
          }
        }
      }
    } catch (error) {
      console.log(error as any);
    }
  };

  const handleSubmit = (imageSaved: string) => {
    if (currentUser._id && imageSaved) {
      return updateUser(
        currentUser._id,
        { ...currentUser, avatar: imageSaved },
        currentUser.token || ""
      )
        .then((updatedUser) => {
          setCurrentUser({ ...updatedUser });
          if (updatedUser?.avatar) {
            fetchImage(updatedUser.avatar).then((signedImage) => {
              if (userInfo) {
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify({
                    ...userInfo,
                    avatar: updatedUser.avatar,
                    signedImage,
                  })
                );
                handleUserInfo({
                  ...userInfo,
                  avatar: updatedUser.avatar,
                  signedImage,
                });
              }
            });
          }
          Swal.fire("Imagen actualizada", "", "success");
        })
        .catch((error) => setError(`${error}`));
    }
    return setError("All fields are required");
  };

  useEffect(() => {
    if (userInfo) {
      getCurrentUser();
    }
  }, []);

  return (
    <>
      <SubHeaderComponent isBackButton={true} buttonText={"Regresar"} href={""} logo={false} />
      <HeaderBottom >Mi perfil</HeaderBottom>

      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-12 mx-auto mb-5">
                <PhotoPicComponent
                  onImageSelected={handleImageSelected}
                  avatar={userInfo?.signedImage || ""}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12 mx-auto mb-5">
                <i className="note text-center">
                  Para garantizar su seguridad, necesitamos una fotografía suya de frente. Esto ayuda a prevenir la suplantación de indentidad y garantiza que reciba sus beneficios sin problemas.
                </i>
              </div>
            </div>
            <div className="row">
              <div className="profile col-lg-4 col-md-6 col-sm-10 col-12 mx-auto">
                <div className="profile__module">
                  <strong>Perfil</strong>
                  <Link to="/mi-perfil/info" className="profile__link">
                    Editar información
                  </Link>
                </div>
                <div className="profile__module">
                  <strong>Seguridad</strong>
                  <Link to="/mi-perfil/phone-mail" className="profile__link">
                    Teléfono / Correo electrónico
                  </Link>
                  <Link to="/mi-perfil/password" className="profile__link">
                    Contraseña
                  </Link>
                </div>
                <div className="profile__module">
                  <strong>Mi membresía</strong>
                  <Link to="/mi-perfil/membership" className="profile__link">
                    membresía
                  </Link>
                  <Link to="/mi-perfil/payment" className="profile__link">
                    Método de pago
                  </Link>
                  {/* <Link to="/mi-perfil/statements" className="profile__link">
                    Estados de cuenta
                  </Link> */}
                </div>
                <div className="profile__module">
                  <strong>Configuración</strong>
                  <Link to="/mi-perfil/notifications" className="profile__link">
                    Notificaciones
                  </Link>
                  <Link to="/mi-perfil/location" className="profile__link">
                    Ubicación
                  </Link>
                </div>
                <div className="profile__module">
                  <strong>SOMA +</strong>
                  <Link to="/about" className="profile__link">
                    Acerca de nosotros
                  </Link>
                  <Link to="/terms" className="profile__link">
                    Términos y condiciones
                  </Link>
                  <Link to="/privacy" className="profile__link">
                    Aviso de privacidad
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default ProfileScreen;
