import { useNavigate } from "react-router-dom";
import SubHeaderComponent from "../../../../components/shared/Subheader/subHeader.component";
import { getCurrentAuthUserAttributes } from "../../../../utils/utils";
import { useEffect, useState } from "react";
import LoaderComponent from "../../../../components/shared/Loader/Loader.component";

const ProfileSuccessScreen = () => {
  const navigate = useNavigate();
  const [nextStep, setNextStep] = useState("/select-membership");
  const [isLoading, setIsLoading] = useState(true);

  const fetchUser = async () => {
    try {
      const user = await getCurrentAuthUserAttributes();
      if (!user) return navigate("/login");
      const {
        somaUser: { stripeSubscription },
      } = user;
      if (stripeSubscription && stripeSubscription.length) {
        const hasSomeActive = stripeSubscription.some(
          (sub) => sub.status === "active"
        );
        if (hasSomeActive) setNextStep("/dashboard");
      }
      console.log("user", user);
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>
      <SubHeaderComponent isBackButton buttonText={"Regresar"} href={""} />
      <section className="auth-layout">
        <div className="container">
          {isLoading ? (
            <div className="mb-4">
              <LoaderComponent isLoading={isLoading} />
            </div>
          ) : (
            <div className="row">
              <div className="mx-auto col-md-4 col-12">
                <div className="auth-head">
                  <figure className="auth-head__icon">
                    <img
                      src="/assets/images/icon/check-success.svg"
                      alt="Success"
                    />
                  </figure>
                  <h1 className="text-center h3">¡Listo!</h1>
                </div>
                <div className="auth-body">
                  <article>
                    <p className="text-center h3">
                      Recuerda que puedes cambiar tus preferencias en cualquier
                      momento que desees.
                    </p>
                  </article>
                  <div className="form-row row--center row--last">
                    <a href={nextStep} className="btn btn--type1">
                      Siguiente
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default ProfileSuccessScreen;
