import axios from "axios";

export default axios.create({
  baseURL: "https://api.somaplus.com",
  // baseURL: 'https://api-dev.somaplus.com',
  // baseURL: 'http://localhost:5002',
  headers: {
    "Content-Type": "application/json",
  },
});
