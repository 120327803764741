import React, { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getCatalogue } from "../../strapi/brands";
import Swal from "sweetalert2";
import { BrandsResult, Reservations } from "../../types/entidades";
import { getUnixTime, set, format } from 'date-fns';
import { newReservation } from "../../api/reservationsApi";
import { IUser } from "../../types/user";
import { getCurrentAuthenticatedUser } from "../../utils/utils";
import HeaderBottom from "../../components/shared/HeaderBottom/HeaderBottom.component";
import AppContext from "../../context/appContext";
import LoaderComponent from "../../components/shared/Loader/Loader.component";
import SubHeaderComponent from "../../components/shared/Subheader/subHeader.component";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";

const RequestScreen = () => {
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [brands, setBrands] = useState<BrandsResult[]>();
  const [user, setUser] = useState<Partial<IUser>>({});
  const { userInfo, handleUserInfo } = useContext(AppContext);
  const [resvType, setResv] = useState("");
  const [optionMsgType, setOptionMsgType] = useState("Selecciona una opción");
  const [successRequest, setSuccessRequest] = useState<boolean | null>(null);
  const [placeOptions, setPlaceOptions] = useState<string[]>([]);
  const navigate = useNavigate();
  const { option, type, promo } = useParams();
  const [selectedPromo, setSelectedPromo] = useState<any>(null);
  const [timeRange, setTimeRange] = useState<any>([]);
  const [reservation, setReservation] = useState<Partial<Reservations>>({
    place: promo || '',
    attendees: 1,
    events: {
      people: 1
    }
  });
  const [includeTimes, setIncludeTimes] = useState<any>([]);
  const attr = (type === 'Events') ? 'Title' : 'Name';

  registerLocale("es", es);
  const [time, setTime] = useState<Date | null>(null);

  const setError = (errorMessage: string) => {
    Swal.fire("Error", `${errorMessage}`, "error");
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleSelectPromo = (attr: string, options: any, selected?: string) => {
    setSelectedPromo(options.find((b: any) => b[attr] === (selected ? selected : promo)));
  };

  const _getCatalogue = async (category: string) => {
    setIsloading(true);
    const catalogueRes: any[] = [];
    const resType = type === 'Events' ? 'events' : 'brands';

    const result = await getCatalogue(resType, category, 1)
      .then((result) => {
        if (result && result.data && result.data.length) {
          catalogueRes.push(...result.data.map((b) => b.attributes));
        }
        return result.data;
      })
      .catch((error) => setError(`${error}`));

    setBrands(catalogueRes as BrandsResult[]);

    if (type === 'Events') {
      setPlaceOptions(catalogueRes.map((b) => b.Title));
      handleSelectPromo('Title', catalogueRes);
    } else {
      setPlaceOptions(catalogueRes.map((b) => b.Name));
      handleSelectPromo('Name', catalogueRes);
    }

    if (type === 'Brands') {
      setReservation((res: any) => ({
        ...res,
        place: 'Personal Shopper'
      }));
      setTimeRange({
        start: catalogueRes.find((b) => b.Name === 'Personal Shopper')?.TimeInitial,
        end: catalogueRes.find((b) => b.Name === 'Personal Shopper')?.TimeFinal
      })
    }

    setIsloading(false);
  };

  const getCurrentUser = async () => {
    try {
      const result = await getCurrentAuthenticatedUser();
      if (result) {
        const { somaUser, jwtToken } = result;
        if (somaUser) {
          setUser({
            ...somaUser,
            token: jwtToken,
          });
          setReservation(
            (res: any) => ({
              ...res,
              userId: somaUser._id,
            }));
        }
      }
    } catch (error) {
      console.log(error as any);
    }
  };

  const filterPassedTime = (time: any) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    const savedDate = reservation?.date ? new Date(reservation?.date) : null;

    if (savedDate && savedDate.getDate() !== currentDate.getDate()) return true;
    return currentDate.getTime() < selectedDate.getTime();
  };

  // convert Time 6:00:00 to new Date
  const convertTime = (time: string, formatDate: any) => {
    const date = new Date(formatDate);
    //add time to date
    date.setHours(parseInt(time.split(":")[0]), parseInt(time.split(":")[1]), parseInt(time.split(":")[2]));
    return date;
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const resReservation = await newReservation(reservation, user.token || "")
      .then((result) => {
        if (result) {
          setSuccessRequest(true);
        }
      })
      .catch((error) => setError(`${error}`));
  };

  const onChangeTime = (selectedDate: Date | null) => {
    if (selectedDate !== null) {
      setReservation((res: any) => ({
        ...res,
        time: getUnixTime(selectedDate),
        type: resvType,
      }));
      setTime(selectedDate);
    }
  };

  const _renderOptions = (options: any) => options.map((opt: any) => {
    // If type is events
    if (promo) {
      if (opt === promo) {
        return (
          <option value={opt} key={opt}>
            {opt}
          </option>
        );
      }

      return (
        <option value={opt} key={opt}>
          {opt}
        </option>
      );
    }

    if (type === 'Brands' && opt === 'Personal Shopper') {
      return (<option value={opt} key={opt}>
        {opt}
      </option>)
    }

    return (
      <option value={opt} key={opt}>
        {opt}
      </option>
    )

  });

  const getIcludedTimes = (date: any) => {
    // Create array with all hour of the day width 15 minutes interval
    const allHours: any[] = [];

    // Loop
    Array.from({ length: 24 }, (_, i) => i).filter((h) => {
      const formattedHour = format(set(new Date(), { hours: h, minutes: 0, seconds: 0 }), 'HH:mm:ss');

      // If formatedHour is greater than timeRange.start and less than timeRange.end
      if (timeRange.start && timeRange.end) {
        if (formattedHour >= timeRange.start && formattedHour <= timeRange.end) {
          allHours.push(
            setHours(
              setMinutes(new Date(), 0),
              h
            ),
          );
        }

        return;
      }

      if (formattedHour >= '09:00:00' && formattedHour <= '22:00:00') {
        allHours.push(
          setHours(
            setMinutes(new Date(), 0),
            h
          ),
        );
      }


    });

    setIncludeTimes(allHours);
    console.log('arrHours', allHours)

  };

  useEffect(() => {
    getCurrentUser();
    if (type) {
      let category = type;

      if (type === 'Dining') {
        category = 'Restaurants'?.charAt(0).toUpperCase() + 'Restaurants'?.slice(1);
      } else {
        category = type?.charAt(0).toUpperCase() + type?.slice(1);
      }
      _getCatalogue(category);
    }

    if (promo) {
      setReservation((res) => ({ ...res, place: promo }));
    }

    if (type === "shopping") {
      setResv("Brands");
      setOptionMsgType("Elige una marca");
    } else if (type === "Dining") {
      setResv("Restaurants");
      setOptionMsgType("Elige un restaurante");
    } else if (type === "entertainment") {
      setResv("Entretainment");
      setOptionMsgType("Elige un entreteniemiento");
    } else if (type === "studios") {
      setResv("Studios");
      setOptionMsgType("Elige un studio");
    } else if (type === "beauty") {
      setResv("Beautys");
      setOptionMsgType("Elige una salón");
    } else if (type === "Events") {
      setResv("Events");
      setOptionMsgType("Elige un evento");
    } else {
      setResv("Brands");
      setOptionMsgType("Elige una opción");
    }
  }, [type, promo]);


  // If there is no promo or Brand, select the first option
  useEffect(() => {
    if (type && !promo) {
      if (placeOptions.length > 0 && brands && brands?.length > 0) {
        handleSelectPromo(attr, brands, placeOptions[0]);
      }
    }
  }, [type, placeOptions, brands]);

  useEffect(() => {
    if (selectedPromo) {
      if (type === 'Events') {
        // Fix -1 day error change "-" to "/"
        const fixDate = selectedPromo?.Date.replace(/-/g, '/');
        const formatDate = convertTime(selectedPromo?.Time, fixDate);

        setReservation(
          (res: any) => (
            {
              ...res,
              date: formatDate,
              time: onChangeTime(formatDate),
            }
          )
        );
      }
    }

  }, [selectedPromo]);

  if (isLoading) return <LoaderComponent isLoading={isLoading} />;

  return (
    <>
      <SubHeaderComponent isBackButton logo={false} buttonText="Regresar" href="" />
      <HeaderBottom children={<>Nueva Reservación</>} />

      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              {!successRequest && (
                <>
                  {type === 'Events' && (
                    <div className="col-md-7 col-12 mx-auto mb-4 mb-md-0">
                      <img className="promo-image" src={selectedPromo?.image?.data?.attributes?.url} alt={selectedPromo?.Title} />
                    </div>
                  )}
                  <div className="col-md-5 col-12 mx-auto d-flex align-items-center">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 mb-4">
                          <div className="form-control-select">
                            <select
                              className="form-control"
                              value={reservation?.place}
                              required={true}
                              onChange={(e) => {
                                setReservation({
                                  ...reservation,
                                  place: e.target.value,
                                  type: resvType,
                                });
                                handleSelectPromo(attr, brands, e.target.value);

                              }}
                              disabled={type === 'Brands'}
                            >
                              <option value={""} disabled>{optionMsgType}</option>

                              {placeOptions && _renderOptions(placeOptions)}
                            </select>
                            <span />
                          </div>
                        </div>
                        <div className="col-12 mb-4">
                          <div className="form-control-picker">
                            <DatePicker
                              startDate={new Date()}
                              minDate={new Date()}
                              selected={reservation?.date}
                              onChange={(date: any) => {
                                setReservation({
                                  ...reservation,
                                  date: date,
                                  type: resvType,
                                });
                                getIcludedTimes(date);
                              }
                              }
                              className="form-control"
                              placeholderText="Seleccionar fecha"
                              required={true}
                              locale={"es"}
                              disabled={type === 'Events'}
                              showDisabledMonthNavigation
                            />
                            <span className="icon date" />
                          </div>
                          <div className="alert alert-danger hidden" role="alert">
                            La hora es obligatoria
                          </div>
                        </div>
                        <div className="position-relative col-12 mb-4">
                          <div className="form-control-picker">
                            <DatePicker
                              selected={time}
                              onChange={(time) => onChangeTime(time)}
                              filterTime={filterPassedTime}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Hora"
                              dateFormat="HH:mm"
                              className="form-control"
                              placeholderText="Seleccionar hora"
                              includeTimes={includeTimes}
                              required={true}
                              disabled={type === 'Events'}
                              locale={"es"}
                            />
                            <span className="icon time" />
                          </div>
                        </div>
                        {type !== 'Brands' && (
                          <div className="col-12 mb-4">
                            <div className="field-number">
                              <button
                                type="button"
                                className="field-number__left"
                                onClick={() => setReservation((rsv => ({
                                  ...rsv,
                                  attendees: rsv.attendees ? rsv.attendees - 1 : 0,
                                  ...(type === 'Events') && { events: { people: rsv.attendees ? rsv.attendees - 1 : 0 } }
                                })))}
                              >
                                -
                              </button>
                              <input
                                type="number"
                                max={20}
                                value={reservation?.attendees}
                                className="form-control col-12"
                                placeholder="No. de personas"
                                onChange={(e: any) => {
                                  setReservation({
                                    ...reservation,
                                    attendees: e.target.value,
                                    type: resvType,
                                    ...(type === 'Events') && { events: { people: e.target.value } }
                                  });
                                }}
                              />
                              <button
                                type="button"
                                className="field-number__right"
                                onClick={() => setReservation((rsv => ({
                                  ...rsv,
                                  attendees: rsv.attendees ? rsv.attendees + 1 : 1,
                                  ...(type === 'Events') && { events: { people: rsv.attendees ? rsv.attendees + 1 : 1 } }

                                })))}
                              >
                                +
                              </button>
                            </div>
                          </div>
                        )}

                        <div className="col-12">
                          <textarea
                            rows={5}
                            value={reservation?.comments}
                            className="form-control"
                            style={{ height: "80px" }}
                            placeholder={type === 'Dining' ? "Agregar comentarios, salón o terraza, alergias, restricciones de comida, etc." : "Agregar comentario"}
                            onChange={(e: any) => {
                              setReservation({
                                ...reservation,
                                comments: e.target.value,
                                type: resvType,
                              });
                            }}
                          />
                        </div>
                        {type === 'Brands' && (
                          <div className="col-12 mx-auto mt-4 text-center">
                            <span className="note">
                              Al continuar, autorizas cargos por cancelación*
                            </span>
                            <i className="note">
                              *Cargos aplican en caso de no presentarse a la cita agendada o cancelación fuera de tiempo.
                            </i>
                          </div>
                        )}
                        <div className="col-12 mx-auto mt-4">
                          <button className="btn btn--type1 btn--100">
                            Reservar
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
              {successRequest && (
                <>
                  <figure className="col-8 mx-auto my-4 text-center">
                    <img
                      src="/assets/images/icon/check-success.svg"
                      alt="Success"
                      className="image"
                    />
                  </figure>

                  <div className="col-12 col-md-6 mx-auto mb-5 text-center">
                    <h3>
                      Recibimos tu solicitud, tu concierge estará en contacto
                      a la brevedad con tu información.
                    </h3>
                  </div>

                  <div className="col-8 col-md-3 mx-auto mb-5 clearfix">
                    <Link
                      to={"/events"}
                      className="btn btn--type1 btn--100"
                    >
                      Continuar
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default RequestScreen;
