import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SubHeaderComponent from "../../../../components/shared/Subheader/subHeader.component";
import { IUser } from "../../../../types/user";
import Swal from "sweetalert2";
import { resendSignUpCode } from "aws-amplify/auth";

const AuthCodeErrorScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<Partial<IUser>>({});

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
  };

  const resendSignUp = async () => {
    try {
      const { phone } = user;
      if (phone) {
        await resendSignUpCode({ username: phone });
        navigate("/auth-code", {
          state: {
            user: {
              ...user,
            },
          },
        });
      }
    } catch (error) {
      setError((error as any).message);
    }
  };

  const back = () => {
    navigate("/auth-code", {
      state: {
        user: {
          ...user,
        },
      },
    });
  };

  useEffect(() => {
    setUser({
      ...location.state.user,
    });
  }, []);

  return (
    <>
      <SubHeaderComponent
        isBackButton
        buttonText={"Regresar"}
        href={"/auth-code"}
      />
      <section className="auth-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-12 mx-auto">
              <div className="auth-head">
                <figure className="auth-head__icon">
                  <img src="/assets/images/icon/error.svg" alt="Error" />
                </figure>
                <h1 className="h3 text-center">
                  Disculpa, el código ingresado es incorrecto.
                </h1>
              </div>
              <div className="auth-body">
                <article className="text-center">
                  <button className="btn--bold" onClick={resendSignUp}>
                    Reenviar código
                  </button>
                </article>
                <div className="form-row row--center row--last">
                  <button className="btn btn--type1" onClick={back}>
                    Regresar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AuthCodeErrorScreen;
