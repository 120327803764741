import { Link } from 'react-router-dom';
import HeaderBottom from '../../components/shared/HeaderBottom/HeaderBottom.component';
import SubHeaderComponent from '../../components/shared/Subheader/subHeader.component';

const AccountStatementsScreen = () => {

  return (
    <>
      <SubHeaderComponent isBackButton={true} buttonText={"Regresar"} href={""} logo={false} />
      <HeaderBottom>Estados de cuenta</HeaderBottom>
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 mx-auto">
                <div className="account-statement">
                  <div className="account-statement__balance">
                    <h4>No tienes saldo pendiente</h4>
                    <p>Gracias por tu pago puntual</p>
                    <strong className="h3">$0.00</strong>
                  </div>
                  <div className="account-statement__charges">
                    <strong>Cargos del mes</strong>
                    <div className="account-statement__charges-months">
                      <div className="active">Jan-Feb</div>
                      <div>Dec-Jan</div>
                    </div>
                  </div>
                  <div className="account-statement__detail">
                    <div>
                      <strong>Consumo o servicios adquiridos</strong>
                      <span className="arrow">
                        <Link to={'/mi-perfil/statements/123'}>
                          $500
                        </Link>
                      </span>
                    </div>
                    <div>
                      <strong>Descuentos y promociones</strong>
                      <span>$19.38</span>
                    </div>
                    <div>
                      <strong>Membresía</strong>
                      <span>$3000</span>
                    </div>
                    <div>
                      <strong>Total</strong>
                      <span>$3519.38</span>
                    </div>
                    <p>Pagar antes de 28 Septiembre de 2023</p>
                    <Link to={'/mi-perfil/statements/123'} className="profile__link">
                      Ver detalles
                    </Link>
                  </div>

                  <div className='row'>
                    <div className='col-6'>
                      <button className="btn btn--type1 btn--100">Pagar</button>
                    </div>
                    <div className='col-6'>
                      <button className="btn btn--type1 btn--100">Pago automático</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}

export default AccountStatementsScreen;
