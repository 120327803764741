import React from 'react';
import { Link } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

const Slide = ({ data }: any) => {
  const { id, attributes } = data;
  const date = format(parseISO(attributes.Date), "d 'de' MMMM, yyyy", { locale: es });

  return (
    <Link to={`/events/detail/Events/${id}`} className='main-slide'>
      <img className="main-slide__image" src={attributes.image.data.attributes.url} alt={data.title} />

      <div className='main-slide__content'>
        <h3 className="main-slide__title">{attributes.Title}</h3>

        <div className='main-slide__footer'>
          <div className="main-slide__location">
            <img src="/assets/images/icon/location.svg" alt="Location" />
            <span>{attributes.name.data.attributes.name}</span>
          </div>

          <div className="main-slide__date">
            <img src="/assets/images/icon/date.svg" alt="Calendar" />
            <span>{date}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Slide;