export const drawerOptions = [
  {
    slug: '/notifications',
    image: '/assets/images/icon/notifications.svg',
    type: 'notifications'
  },
  {
    slug: '/valet-parking',
    image: '/assets/images/icon/car.svg',
  }
];

export const menuOptions = [
  {
    name: 'Inicio',
    slug: '/events',
  },
  {
    name: 'Perfil',
    slug: '/mi-perfil',
  },
  {
    name: 'Eventos',
    slug: '/events/Eventos',
  },
  {
    name: 'Concierge',
    slug: '/concierge',
  },
  {
    name: 'Mapa Artz',
    slug: '/interactive-map',
  },
  // {
  //   name: 'Ajustes',
  //   slug: '/settings',
  // },
  {
    name: 'Acerca de SOMA+',
    slug: '/about',
  },
  // {
  //   name: 'Invita a tu círculo a tu SOMA+',
  //   slug: '/',
  // },
];