import React, { useState, useEffect, useContext } from "react";
import AppContext from "../../../context/appContext";
import {
  signIn,
  fetchAuthSession,
  fetchUserAttributes,
  signInWithRedirect,
  signOut,
} from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import "aws-amplify/auth/enable-oauth-listener";
import { getUrl } from "aws-amplify/storage";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-number-input";
import { NavLink, useNavigate } from "react-router-dom";
import SubHeaderComponent from "../../../components/shared/Subheader/subHeader.component";
import OnboardingComponent from "../../../components/shared/Onboarding/onboarding.component";
import { IUser } from "../../../types/user";
import { getUser } from "../../../api/usersApi";
import "react-phone-number-input/style.css";
import { searchProfileFavorites } from "../../../api/userBrandsFavorites";
import { saveSession } from "../../../utils/helpers";
import {
  getCurrentAuthUserAttributes,
  getCurrentAuthenticatedUser,
  socialAuthEvent,
} from "../../../utils/utils";

const LoginScreen = () => {
  const { handleUserInfo } = useContext(AppContext);
  const [showPassword, setShowPassword] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumberOrSymbol, setHasNumberOrSymbol] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({
    phone: "",
    password: "",
  });
  const navigate = useNavigate();

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
    setIsLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event: any) => {
    const newPassword = event.target.value;
    setUser({
      ...user,
      password: event.target.value,
    });

    const hasUpperCaseLetter = /[A-Z]/.test(newPassword);
    const hasLowerCaseLetter = /[a-z]/.test(newPassword);
    const hasNumberOrSymbol = /[0-9!@#$%^&*]/.test(newPassword);

    setHasUpperCase(hasUpperCaseLetter);
    setHasLowerCase(hasLowerCaseLetter);
    setHasNumberOrSymbol(hasNumberOrSymbol);

    setFormIsValid(
      newPassword.length >= 8 &&
        hasUpperCaseLetter &&
        hasLowerCaseLetter &&
        hasNumberOrSymbol
    );
  };

  const checkRedirect = async () => {
    try {
      const user = await getCurrentAuthUserAttributes();
      if (!user) return navigate("/login");
      const {
        somaUser: { stripeSubscription },
      } = user;
      if (stripeSubscription && stripeSubscription.length) {
        const hasSomeActive = stripeSubscription.some(
          (sub) => sub.status === "active"
        );
        if (hasSomeActive) {
          navigate("/events");
          // navigate("/dashboard");
        } else {
          navigate("/select-membership");
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    console.log("handleSubmit", user);
    await signInUser();
    await checkRedirect();
  };

  const fetchImage = async (image: string) => {
    const signedUrl = await getUrl({
      key: image,
      options: {
        expiresIn: 3600, // 1 hour
      },
    });

    return signedUrl.url.toString();
  };

  useEffect(() => {
    const isValid =
      user.phone?.trim() !== "" &&
      (user.password?.length || 0) >= 8 &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumberOrSymbol;

    setFormIsValid(isValid);
  }, [
    user.password,
    user.phone,
    hasUpperCase,
    hasLowerCase,
    hasNumberOrSymbol,
  ]);

  useEffect(() => {
    Hub.listen("auth", async (evenData) => {
      console.log("evenData", evenData);
      await socialAuthEvent(evenData, setIsLoading, setError, navigate);
    });
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getCurrentAuthenticatedUser();
      if (user) {
        window.location.href = "/";
      }
    };
    setTimeout(() => {
      console.log("fetchUser");
      fetchUser();
    }, 500);
  }, []);

  const handlePasswordBlur = () => {
    setPasswordTouched(true);
  };

  const signInUser = async () => {
    setIsLoading(true);
    try {
      const { phone, password } = user;
      if (phone && password) {
        const userSignIn = await signIn({
          username: phone,
          password: password,
        }).catch((err) => {
          console.log("err", err);
        });

        console.log("userSignIn", userSignIn);

        if (userSignIn) {
          const authSession = await fetchAuthSession();
          const userAttributes = await fetchUserAttributes();

          console.log("authSession", authSession);

          if (authSession && userAttributes) {
            const somaPlusUserId =
              userAttributes["custom:somaPlusUserId"] || "";
            const jwtToken = authSession.tokens?.accessToken.toString() || "";

            const somaPlusUser = await getUser(somaPlusUserId, jwtToken);

            const { stripeSubscription } = somaPlusUser;
            if (stripeSubscription && stripeSubscription.length) {
              const hasSomeActive = stripeSubscription.some(
                (sub) => sub.status === "active"
              );
              if (hasSomeActive) {
                const userFavs = await searchProfileFavorites(
                  somaPlusUser._id || "",
                  jwtToken
                );

                const userImage = await fetchImage(somaPlusUser.avatar || "");

                // Save user info in local storage and context
                localStorage.setItem(
                  "userInfo",
                  JSON.stringify({
                    ...somaPlusUser,
                    token: jwtToken,
                    signedImage: userImage,
                  })
                );

                handleUserInfo({
                  ...somaPlusUser,
                  token: jwtToken,
                  signedImage: userImage,
                });

                await saveSession(somaPlusUser);

                if (!somaPlusUser?.activeMembership) {
                  navigate("/inactive-membership");
                  return;
                }

                userFavs && userFavs.length > 0
                  ? navigate("/events")
                  : navigate("/favorites-experience");

                return somaPlusUser;
              }
            }
          }
        }
      }
    } catch (err) {
      const { code } = err as any;
      if (code) {
        switch (code) {
          case "UserNotConfirmedException":
            setIsLoading(false);
            break;
          default:
            setError((err as any).message);
            break;
        }
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <OnboardingComponent />
      <SubHeaderComponent
        href={""}
        buttonText={"Regresar a SOMA+"}
        isBackButton
      />
      <section className="auth-layout">
        <div className="container">
          <div className="row">
            <div className="mx-auto col-lg-4 col-md-6 col-12">
              <div className="auth-head">
                <div className="auth-tabs">
                  <h3 className="text-center">Bienvenido</h3>
                </div>
              </div>
              <div className="auth-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <label htmlFor="phone">Ingresa número celular</label>
                    <PhoneInput
                      id="phone"
                      placeholder="Enter phone number"
                      value={user.phone}
                      defaultCountry="MX"
                      international
                      smartCaret
                      className="form-control"
                      countryCallingCodeEditable={false}
                      limitMaxLength={true}
                      tabIndex={1}
                      onChange={(evt) => {
                        setUser({
                          ...user,
                          phone: evt,
                        });
                      }}
                    />
                    <div className="hidden alert alert-danger" role="alert">
                      El teléfono es obligatorio.
                    </div>
                  </div>

                  <div className="form-row">
                    <label htmlFor="password">Contraseña</label>
                    <div className="form--password">
                      <button
                        type="button"
                        className="btn--password-show"
                        tabIndex={3}
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <i className="fa-regular fa-eye-slash"></i>
                        ) : (
                          <i className="fa-regular fa-eye"></i>
                        )}
                      </button>
                      <input
                        id="password"
                        tabIndex={2}
                        type={showPassword ? "text" : "password"}
                        placeholder="Contraseña"
                        className="form-control"
                        value={user.password}
                        onChange={handlePasswordChange}
                        onBlur={handlePasswordBlur}
                        required
                        minLength={8}
                      />
                    </div>
                    <div
                      className={`alert alert-danger ${
                        passwordTouched &&
                        ((user.password?.length || 0) < 8 ||
                          !hasUpperCase ||
                          !hasLowerCase ||
                          !hasNumberOrSymbol)
                          ? ""
                          : "hidden"
                      }`}
                    >
                      {passwordTouched && (user.password?.length || 0) < 8 ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              "La contraseña debe tener al menos <strong>8 caracteres</strong>.",
                          }}
                        ></div>
                      ) : (
                        ""
                      )}
                      {passwordTouched && !hasUpperCase ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              "La contraseña debe contener al menos <strong>una letra mayúscula</strong>. ",
                          }}
                        ></div>
                      ) : (
                        ""
                      )}
                      {passwordTouched && !hasLowerCase ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              "La contraseña debe contener al menos <strong>una letra minúscula</strong>. ",
                          }}
                        ></div>
                      ) : (
                        ""
                      )}
                      {passwordTouched && !hasNumberOrSymbol ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              "La contraseña debe contener al menos <strong>un número o un símbolo</strong>. ",
                          }}
                        ></div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-row row--double">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="checkDefault"
                        tabIndex={4}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkDefault"
                      >
                        Recuérdame
                      </label>
                    </div>
                    <a
                      href="/recover-password-step1"
                      className="btn--bold"
                      tabIndex={5}
                    >
                      ¿Olvidaste tu contraseña?
                    </a>
                  </div>

                  <div className="form-row row--center row--last">
                    <button
                      type="submit"
                      className="btn btn--type1 btn--100"
                      disabled={!formIsValid}
                      tabIndex={6}
                    >
                      Iniciar sesión
                    </button>
                  </div>
                  <div className="form-row">
                    <div className="social-signup">
                      <p>o iniciar sesión con</p>
                      <button
                        className="btn btn--social"
                        onClick={(e) => {
                          e.preventDefault();
                          signInWithRedirect({
                            provider: "Facebook",
                          }).catch((err) => {
                            signOut({
                              global: true,
                            });
                            console.error(err);
                            setError("Error signing in with Facebook");
                          });
                        }}
                      >
                        <i className="icon icon--facebook"></i>
                      </button>
                      <button
                        className="btn btn--social"
                        onClick={(e) => {
                          e.preventDefault();
                          signInWithRedirect({
                            provider: "Google",
                          }).catch((err) => {
                            signOut({
                              global: true,
                            });
                            console.error(err);
                            setError("Error signing in with Facebook");
                          });
                        }}
                      >
                        <i className="icon icon--google"></i>
                      </button>
                    </div>
                  </div>

                  <div className="form-row text-center">
                    <NavLink to="/" className="text-center">
                      ¿Aún no tienes una cuenta?
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginScreen;
