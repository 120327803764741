export const menuOptions = [
  {
    name: 'Home',
    slug: '/events',
    image: '/assets/images/icon/home.svg',
  },
  {
    name: 'Dining',
    slug: '/category/dining',
    image: '/assets/images/icon/dining.svg',
  },
  {
    name: 'Brands',
    slug: '/category/brands',
    image: '/assets/images/icon/shopping.svg',
  },
  {
    name: 'Entertainment',
    slug: '/category/entertainment',
    image: '/assets/images/icon/entertainment.svg',
  },
  {
    name: 'Studios',
    slug: '/category/studios',
    image: '/assets/images/icon/studios.svg',
  },
];