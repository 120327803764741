import React, { useState, useEffect } from "react";
import SubHeaderComponent from "../../../components/shared/Subheader/subHeader.component";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { resetPassword } from "aws-amplify/auth";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const RecoverStep1Screen = () => {
  const [formIsValid, setFormIsValid] = useState(false);
  const [phone, setPhone] = useState<string | undefined>("");
  const navigate = useNavigate();

  const setError = async (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    intentRecover();
  };

  const intentRecover = async () => {
    if (!phone) {
      return setError("Ingresa el teléfono registrado");
    }
    try {
      console.log(phone);
      const user = await resetPassword({ username: phone });
      console.log(user);
      if (user) {
        navigate("/recover-password-step1-success", {
          state: {
            user: {
              phone: phone,
            },
            email: user,
          },
        });
      }
    } catch (error) {
      navigate("/recover-password-step1-error");
    }
  };

  useEffect(() => {
    const isValid = phone?.trim() !== "";
    setFormIsValid(isValid);
  }, [phone]);

  return (
    <>
      <SubHeaderComponent href={""} buttonText={"Regresar"} isBackButton />
      <section className="auth-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-12 mx-auto">
              <div className="auth-head">
                <h1 className="h3 text-center">
                  Ingresa el teléfono registrado
                </h1>
              </div>
              <div className="auth-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={phone}
                      defaultCountry="MX"
                      international
                      smartCaret
                      className="form-control"
                      countryCallingCodeEditable={false}
                      limitMaxLength={true}
                      onChange={(evt) => {
                        setPhone(evt);
                      }}
                    />
                  </div>
                  <div className="form-row row--last row--center">
                    <button
                      disabled={!formIsValid}
                      type="submit"
                      className="btn btn--type1"
                    >
                      Continuar
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RecoverStep1Screen;
