import React from 'react';
import HeaderBottom from '../../components/shared/HeaderBottom/HeaderBottom.component';
import SubHeaderComponent from '../../components/shared/Subheader/subHeader.component';


const AccountStatementDetailScreen = () => {

  return (
    <>
      <SubHeaderComponent isBackButton={true} buttonText={"Regresar"} href={""} logo={false} />
      <HeaderBottom children={<>Estados de cuenta</>} />
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 mx-auto">
                <div className="account-statement">
                  <strong className='d-block mb-5'>Consumo o servicios adquiridos</strong>
                  <div className="account-statement__detail">
                    <div>
                      <strong>
                        Boletos de cine
                        <em>08 / 09 / 2023</em>
                      </strong>
                      <span>$100</span>
                    </div>
                    <div>
                      <strong>
                        Lavado de auto
                        <em>12 / 09 / 2023</em>
                      </strong>
                      <span>$100</span>
                    </div>
                    <div>
                      <strong>
                        Valet Parking
                        <em>12 / 09 / 2023</em>
                      </strong>
                      <span>$100</span>
                    </div>
                    <div>
                      <strong>
                        Boetos de cine
                        <em>17 / 09 / 2023</em>
                      </strong>
                      <span>$100</span>
                    </div>
                    <div>
                      <strong>
                        Cena en Cantina la No.20
                        <em>24 / 09 / 2023</em>
                      </strong>
                      <span>$100</span>
                    </div>
                    <div className="total">
                      <strong>Total</strong>
                      <span>$500</span>
                    </div>
                    <button className="btn btn--type1 btn--100">Enviar por correo</button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}

export default AccountStatementDetailScreen;
