export const routeList = [
  "/favorites-experience",
  "/favorites-places-to-eat",
  "/favorites-studios",
  "/favorites-interiors",
  "/experience-geolocation",
  "/new-profile",
  "/new-profile-success",
  "/select-membership",
];

export const validateArray = (array: any[]) => {
  return array && Array.isArray(array) && array.length > 0;
};

export const hideHeader = (routes: string[]) => {
  const currentPath = window.location.pathname;
  return routes.includes(currentPath);
};
