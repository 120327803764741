import React, { useEffect, useState, useContext } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getCatalogue } from "../../strapi/brands";
import Swal from "sweetalert2";
import { BrandsResult, Reservations } from "../../types/entidades";
import { getUnixTime, set, format, fromUnixTime } from 'date-fns';
import { newReservation } from "../../api/reservationsApi";
import { IUser } from "../../types/user";
import { getCurrentAuthenticatedUser } from "../../utils/utils";
import HeaderBottom from "../../components/shared/HeaderBottom/HeaderBottom.component";
import AppContext from "../../context/appContext";
import LoaderComponent from "../../components/shared/Loader/Loader.component";
import SubHeaderComponent from "../../components/shared/Subheader/subHeader.component";
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import studiosApi from "../../api/studiosApi";
import StudioCard from "../../components/StudioCard/StudioCard.component";

const RequestStudioScreen = () => {
  const [isLoading, setIsloading] = useState<boolean>(true);
  const [brands, setBrands] = useState<BrandsResult[]>();
  const [user, setUser] = useState<Partial<IUser>>({});
  const { userInfo, handleUserInfo } = useContext(AppContext);
  const [resvType, setResv] = useState("");
  const [optionMsgType, setOptionMsgType] = useState("Selecciona una opción");
  const [successRequest, setSuccessRequest] = useState<boolean | null>(null);
  const [placeOptions, setPlaceOptions] = useState<string[]>([]);
  const navigate = useNavigate();
  const { option, type, promo } = useParams();
  const [selectedPromo, setSelectedPromo] = useState<any>(null);
  const [timeRange, setTimeRange] = useState<any>([]);
  const [reservation, setReservation] = useState<Partial<Reservations>>({
    place: promo || '',
    attendees: 1,
    events: {
      people: 1
    }
  });
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [studioSchedules, setStudioSchedules] = useState<any[]>([]);
  const [includeTimes, setIncludeTimes] = useState<any>([]);
  const attr = (type === 'Events') ? 'Title' : 'Name';

  registerLocale("es", es);
  const [time, setTime] = useState<Date | null>(null);

  const setError = (errorMessage: string) => {
    Swal.fire("Error", `${errorMessage}`, "error");
  };

  const handleSelectPromo = (attr: string, options: any, selected?: string) => {
    setSelectedPromo(options.find((b: any) => b[attr] === (selected ? selected : promo)));
  };

  const _getCatalogue = async (category: string) => {
    setIsloading(true);
    const catalogueRes: any[] = [];

    const result = await getCatalogue('brands', category, 1)
      .then((result) => {
        if (result && result.data && result.data.length) {
          catalogueRes.push(...result.data.map((b) => ({
            id: b?.id,
            ...b?.attributes
          })));
        }
        return result.data;
      })
      .catch((error) => setError(`${error}`));

    setBrands(catalogueRes as BrandsResult[]);


    setPlaceOptions(catalogueRes.map((b) => b.Name));
    handleSelectPromo('Name', catalogueRes);

    setReservation((res: any) => ({
      ...res,
      place: 'Personal Shopper'
    }));
    setTimeRange({
      start: catalogueRes.find((b) => b.Name === 'Personal Shopper')?.TimeInitial,
      end: catalogueRes.find((b) => b.Name === 'Personal Shopper')?.TimeFinal
    })

    setIsloading(false);
  };

  const fetchStudioSchedules = async (studioSelected: any) => {
    try {
      const mallId = `${studioSelected?.malls?.data[0].id || ''}`;
      const brandId = `${studioSelected?.id}`;
      const response = await studiosApi.getByStudioId(mallId, brandId);
      if (response) {
        setStudioSchedules(response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCurrentUser = async () => {
    try {
      const result = await getCurrentAuthenticatedUser();
      if (result) {
        const { somaUser, jwtToken } = result;
        if (somaUser) {
          setUser({
            ...somaUser,
            token: jwtToken,
          });
          setReservation(
            (res: any) => ({
              ...res,
              userId: somaUser._id,
              type: 'Studios',
            }));
        }
      }
    } catch (error) {
      console.log(error as any);
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const resReservation = await newReservation(reservation, user.token || "")
      .then((result) => {
        if (result) {
          setSuccessRequest(true);
        }
      })
      .catch((error) => setError(`${error}`));
  };

  const _renderOptions = (options: any) => options.map((opt: any) => {
    // If type is events
    if (promo) {
      if (opt === promo) {
        return (
          <option value={opt} key={opt}>
            {opt}
          </option>
        );
      }

      return (
        <option value={opt} key={opt}>
          {opt}
        </option>
      );
    }

    if (type === 'Brands' && opt === 'Personal Shopper') {
      return (<option value={opt} key={opt}>
        {opt}
      </option>)
    }

    return (
      <option value={opt} key={opt}>
        {opt}
      </option>
    )

  });

  const getHourMinutes = (scheduleTime: string) => {
    const classTime = scheduleTime.replace(/(AM|PM|hrs.)/, '').split(':');
    const classHour = parseInt(classTime[0]);
    const classMinutes = parseInt(classTime[1]);
    const amPM = scheduleTime.includes('AM') ? 'AM' : 'PM';
    const isHrs = scheduleTime.includes('hrs.');
    if (isHrs) {
      return `${classHour}:${classMinutes}`;
    }
    return `${amPM === 'PM' ? classHour + 12 : classHour}:${classMinutes}`;
  };

  const handleOnPress = (studio: any) => {
    console.log('studio', studio)
    setSelectedDate(studio._id);

    setReservation((rsv: any) => ({
      ...rsv,
      place: `${studio.studioId} | ${studio.roomName || studio.instructor || ''} | ${studio.scheduleClass || ''}`,
      date: new Date(studio.date),
      time: studio.unixDate,
    }));
  };

  useEffect(() => {
    getCurrentUser();
    if (type) {
      let category = type;

      if (type === 'Dining') {
        category = 'Restaurants'?.charAt(0).toUpperCase() + 'Restaurants'?.slice(1);
      } else {
        category = type?.charAt(0).toUpperCase() + type?.slice(1);
      }
      _getCatalogue(category);
    }

    if (promo) {
      setReservation((res) => ({ ...res, place: promo, type: 'Studios' }));
    }

    if (type === "shopping") {
      setResv("Brands");
      setOptionMsgType("Elige una marca");
    } else if (type === "Dining") {
      setResv("Restaurants");
      setOptionMsgType("Elige un restaurante");
    } else if (type === "entertainment") {
      setResv("Entretainment");
      setOptionMsgType("Elige un entreteniemiento");
    } else if (type === "studios") {
      setResv("Studios");
      setOptionMsgType("Elige un studio");
    } else if (type === "beauty") {
      setResv("Beautys");
      setOptionMsgType("Elige una salón");
    } else if (type === "Events") {
      setResv("Events");
      setOptionMsgType("Elige un evento");
    } else {
      setResv("Brands");
      setOptionMsgType("Elige una opción");
    }
  }, [type, promo]);


  // If there is no promo or Brand, select the first option
  useEffect(() => {
    if (type && !promo) {
      if (placeOptions.length > 0 && brands && brands?.length > 0) {
        handleSelectPromo(attr, brands, placeOptions[0]);
      }
    }
  }, [type, placeOptions, brands]);

  useEffect(() => {
    if (selectedPromo) {
      // Fetch schedules
      fetchStudioSchedules(selectedPromo);
    }

  }, [selectedPromo]);

  if (isLoading) return <LoaderComponent isLoading={isLoading} />;

  return (
    <>
      <SubHeaderComponent isBackButton logo={false} buttonText="Regresar" href="" />
      <HeaderBottom children={<>Nueva Reservación asdasd</>} />

      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              {!successRequest && (
                <>
                  {type === 'Events' && (
                    <div className="col-md-7 col-12 mx-auto mb-4 mb-md-0">
                      <img className="promo-image" src={selectedPromo?.image?.data?.attributes?.url} alt={selectedPromo?.Title} />
                    </div>
                  )}
                  <div className="col-md-5 col-12 mx-auto d-flex align-items-center">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-12 mb-4">
                          <div className="form-control-select">
                            <select
                              className="form-control"
                              value={reservation?.place}
                              required={true}
                              onChange={(e) => {
                                setReservation({
                                  ...reservation,
                                  place: e.target.value,
                                  type: resvType,
                                });
                                handleSelectPromo(attr, brands, e.target.value);

                              }}
                              disabled={type === 'Brands'}
                            >
                              <option value={""} disabled>{optionMsgType}</option>

                              {placeOptions && _renderOptions(placeOptions)}
                            </select>
                            <span />
                          </div>
                        </div>

                        <div className="studio-list col-12 mb-4">
                          {studioSchedules
                            .filter((s, i) => {
                              const { enabled, unixDate, scheduleTime } = s;
                              const classDate = fromUnixTime(unixDate);
                              const classTime =
                                getHourMinutes(scheduleTime);
                              const classDateWithTime = new Date(
                                classDate.getFullYear(),
                                classDate.getMonth(),
                                classDate.getDate(),
                                parseInt(classTime.split(':')[0]),
                                parseInt(classTime.split(':')[1]),
                              );
                              // date plus hrs. in unix format
                              studioSchedules[i].scheduleTime =
                                format(classDateWithTime, 'hh:mm a');
                              return (
                                enabled &&
                                classDateWithTime.getTime() >
                                new Date().getTime() + 60000 * 60
                              );
                            })
                            .map((s, key) => {
                              return (
                                <StudioCard
                                  key={key}
                                  data={s}
                                  active={selectedDate === s._id}
                                  onClick={() => handleOnPress(s)}
                                />
                              );
                            })}
                        </div>

                        <div className="col-12 mx-auto mt-4">
                          <button className="btn btn--type1 btn--100">
                            Reservar
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </>
              )}
              {successRequest && (
                <div className="row">
                  <figure className="col-12 mx-auto my-4 text-center">
                    <img
                      src="/assets/images/icon/check-success.svg"
                      alt="Success"
                      className="image"
                    />
                  </figure>

                  <div className="col-12 col-md-6 mx-auto mb-5 text-center">
                    <h3>
                      Recibimos tu solicitud, tu concierge estará en contacto
                      a la brevedad con tu información.
                    </h3>
                  </div>

                  <div className="col-12">
                    <div className="col-8 col-md-3 mx-auto mb-5 clearfix">
                      <Link
                        to={"/category/studios"}
                        className="btn btn--type1 btn--100"
                      >
                        Continuar
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default RequestStudioScreen;
