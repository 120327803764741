import api from "./index";
import { IValet, IValetPaginate } from "../types/valetParking";
import { encode, ParsedUrlQueryInput } from "querystring";

const commonHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const searchValet = async (
  limit: any,
  offset: any,
  query: Partial<IValet>,
  token: string
) => {
  const response = await api.post<IValetPaginate>(
    `/valet/search?${encode({
      limit,
      offset,
    } as ParsedUrlQueryInput)}`,
    { query },
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const searchValetByUserId = async (
  limit: any,
  offset: any,
  query: Partial<IValet>,
  token: string
) => {
  const response = await api.post<IValetPaginate>(
    `/valet/search?${encode({
      limit,
      offset,
    } as ParsedUrlQueryInput)}`,
    { query },
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
