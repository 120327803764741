import HeaderComponent from '../../../../components/shared/Header/Header.component';
import SubHeaderComponent from '../../../../components/shared/Subheader/subHeader.component';

const ValetSuccessScreen = () => {
  

  return (
    <>
      
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-12 mx-auto">
                <div className="auth-head">
                  <figure className="auth-head__icon">
                    <img src="/assets/images/icon/check-success.svg" alt="Success" />
                  </figure>
                  <h1 className="h3 text-center">Hemos recibido tu solicitud, ve al estacionamiento A12 para recoger tu carro, estaremos allí en breve.</h1>
                </div>
                <div className="auth-body">
                
                <div className="form-row row--center row--last">
                  <a href="/dashboard" className="btn btn--type1">Continuar</a>
                </div>
                  
                </div>
              </div>
            </div>
          </div>
        </section>
       
      </section>
    </>
  );
}

export default ValetSuccessScreen;
