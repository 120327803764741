import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getAnStrapiEntry } from "../../strapi/contents";
import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm';
import HeaderBottom from "../../components/shared/HeaderBottom/HeaderBottom.component";
import SubHeaderComponent from "../../components/shared/Subheader/subHeader.component";

const SimpleContentScreen = () => {
  const location = useLocation();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState<React.ReactNode | null>(null);

  const getEntry = async (content: string) => {
    const result = await getAnStrapiEntry(content);
    setContent(result.data.attributes.content);
  };

  useEffect(() => {
    if (location.pathname === "/about") {
      getEntry("/about");
      setTitle('Acerca de nosotros');
    } else if (location.pathname === "/terms") {
      getEntry("/term-and-condition");
      setTitle('Términos y condiciones');
    } else if (location.pathname === "/privacy") {
      getEntry("/privacy-notice");
      setTitle('Aviso de privacidad');
    }
  }, [location]);

  return (
    <>
      <SubHeaderComponent isBackButton={true} buttonText={"Regresar"} href={""} logo={false} />
      <HeaderBottom children={<>{title}</>} />
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-12 mx-auto">
                <div className="simple-content mt-5">
                  <ReactMarkdown
                    linkTarget={'_blank'}
                    remarkPlugins={[remarkGfm]}
                  >
                    {`${content || ''}`}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default SimpleContentScreen;
