import React from 'react';
import { Link } from 'react-router-dom';

const Slide = ({ data }: any) => {
  const { id, attributes } = data;

  return (
    <Link to={`/events/detail/Brands/${id}`} className='promo-slide'>
      <img className="promo-slide__image" src={attributes.image.data.attributes.url} alt={data.title} />

      <div className='promo-slide__content'>
        <h3 className="promo-slide__title">{attributes.Title}</h3>
      </div>
    </Link>
  );
};

export default Slide;