import React, { useEffect, useState } from "react";
import PromosSection from "../../components/shared/PromosSection/PromosSection.component";
import { IUser } from "../../types/user";
import { PromosResult } from "../../types/entidades";
import { getPromos } from "../../strapi/promos";
import qs from "qs";
import { getCurrentAuthenticatedUser } from "../../utils/utils";
import { CinemaId, Movie } from "../../types/cinemas";
import { getCinemaMovies, getCinemas, strapiCinema } from "../../api/cinemasApi";

const EntertainmentScreen = () => {
  const [user, setUser] = useState<Partial<IUser>>({});
  const [brands, setBrands] = useState<PromosResult[]>([]);
  const [cinemas, setCinenmas] = useState<CinemaId[]>([]);
  const [selectedCinema, setSelectedCinema] = useState<any>(null);
  const [movies, setMovies] = useState<Movie[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const promos = async () => {
    const query = {
      sort: ["id:asc"],
      populate: "*",
      filters: {
        $and: [{ brand: { category: { Name: { $eq: "Entretainment" } } } }],
      },
      pagination: {
        pageSize: 100,
      },
    };
    const result = await getPromos(qs.stringify({ ...query }));
    setBrands(result.data as PromosResult[]);
  };

  const fetchCinemas = async (jwtToken: string) => {
    try {
      const response = await getCinemas(jwtToken);
      return response;
    } catch (error) { }
  };

  const fetchMovies = async (jwtToken: string, cinemaId: string) => {
    setIsLoading(true);
    try {
      const response = await getCinemaMovies(jwtToken, cinemaId);
      return response;
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const changeCinema = (changeVal: any) => {
    setSelectedCinema(changeVal);
  };

  useEffect(() => {
    promos();
    if (selectedCinema) {
      getCurrentAuthenticatedUser().then((authData) => {
        const token = authData?.jwtToken || "";
        setUser({
          ...authData?.somaUser,
          token,
        });
        fetchCinemas(token).then((res) => {
          if (res) {
            setCinenmas(res);
          }
        });
      });
    }
  }, [selectedCinema]);

  useEffect(() => {
    if (
      user.token !== undefined &&
      selectedCinema !== "" &&
      selectedCinema !== undefined &&
      selectedCinema
    ) {
      fetchMovies(user.token, selectedCinema).then((res) => {
        setMovies(res as Movie[]);
      });
    }
  }, [selectedCinema, user]);

  return (
    <>
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-12 mx-auto mb-5">
                <PromosSection
                  titleSection="Cinemex"
                  subtitleSection="Beneficio de 2x1 disponible una vez al mes por socio"
                  userName={user.nickname || ""}
                  entertainmentFilter
                  cinemas={cinemas}
                  changeCinema={changeCinema}
                  movies={movies}
                  user={user}
                  isLoading={isLoading}
                  option="Entertainment"
                />
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default EntertainmentScreen;
