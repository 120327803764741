import axios from "axios";

export default axios.create({
  baseURL: "https://cms.somaplus.com/api",
  headers: {
    "Content-Type": "application/json",
    Authorization:
      "Bearer 7828b8371fccf5f296b663f67d85220261b5408751404302b14baf699a6cd2261c294c545a9bbd1f1ebfa6032745a5960017fca9d43d7cf504f6d901ed01a665ba8e42b34c75a103e4f59b8c6c052199a8ba76fe555bae32905beee5b93d9b434ad7a57fbb71a4a7281d462ee56655fca13760905f3a35737e3ec8e89fc933fb",
  },
});
