import api from "./index";

const commonHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

const getByStudioId = async (mallId: string, brandId: string) => {
  console.log("------ getByStudioId ------", { mallId, brandId });
  const response = await api.get<any[]>(`/studio/${mallId}/${brandId}`, {
    headers: {
      ...commonHeaders,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export default {
  getByStudioId,
};
