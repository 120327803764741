import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { IUser } from "../../types/user";
import Swal from "sweetalert2";
import { getCurrentAuthenticatedUser } from "../../utils/utils";
import SubHeaderComponent from "../../components/shared/Subheader/subHeader.component";

const EditMembershipScreen = () => {
  const [cancelMembership, setCancelMembership] = useState(false);
  const [membershipCanceled, setMembershipCanceled] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [currentUser, setCurrentUser] = useState<Partial<IUser>>({});

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
  };

  const getCurrentUser = async () => {
    try {
      const result = await getCurrentAuthenticatedUser();
      if (result) {
        const { somaUser, jwtToken } = result;
        if (somaUser) {
          setCurrentUser({ ...somaUser, token: jwtToken });
          const { stripeSubscription } = somaUser;
          const isActive =
            stripeSubscription && stripeSubscription.length
              ? stripeSubscription.some(
                (subscription) =>
                  subscription.status === "active" ||
                  subscription.status === "trialing"
              )
              : false;
          setIsActive(isActive);
        }
      }
    } catch (error) {
      setError(error as any);
    }
  };

  useEffect(() => {
    if (!Object.keys(currentUser).length) {
      getCurrentUser();
    }
  });

  const handleCancelMembership = (e: any) => {
    e.preventDefault();
    setCancelMembership(true);
  };

  if (cancelMembership) {
    return (
      <>
        <SubHeaderComponent isBackButton={true} buttonText={"Regresar"} href={""} logo={false} />
        <section className="dashboard-layout">
          <section className="module">
            <div className="container">
              <div className="row">
                {!membershipCanceled && (
                  <div className="col-md-6 col-lg-4 col-12 mx-auto text-center">
                    <span className="material-icons-outlined icon display-1">
                      error
                    </span>
                    <h3 className="my-5">
                      ¿Estás seguro que deseas cancelar tu membresía?
                    </h3>
                    <p className="my-5">
                      Si cancelas tu membresía ya no podrás tener acceso a tus
                      benbeficios
                    </p>
                    <div className="row">
                      <div className="col-md-6 col-12 mb-3">
                        <button
                          className="btn btn--type1 btn--100"
                          onClick={() => setCancelMembership(false)}
                        >
                          Cancelar
                        </button>
                      </div>
                      <div className="col-md-6 col-12">
                        <button
                          className="btn btn--type1 btn--100"
                          onClick={() => setMembershipCanceled(true)}
                        >
                          Confirmar
                        </button>
                      </div>
                    </div>
                  </div>
                )}
                {membershipCanceled && (
                  <div className="col-md-6 col-lg-4 col-12 mx-auto text-center">
                    <span className="material-icons-outlined icon display-1 text-success">
                      check_circle
                    </span>
                    <h3 className="my-5">¡Es triste verte marchar!</h3>
                    <p className="my-5">
                      Tu membresía fue cancelada, recuerda que puedes regresar
                      en cualquier momento.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </section>
        </section>
      </>
    );
  } else {
    return (
      <>
        <SubHeaderComponent isBackButton={true} buttonText={"Regresar"} href={""} logo={false} />

        <section className="dashboard-layout">
          <section className="module">
            <div className="container">
              <div className="row">
                <div className="col-12 text-center mb-3">
                  <h3>Mi membresía</h3>
                </div>
                <div className="col-lg-5 col-xl-4 col-md-7 col-12 mx-auto">
                  <div className="membership-block">

                    <figure>
                      <img src="/assets/images/logo/soma-white.svg" alt="SOMA + Membership" />
                    </figure>

                    <figure className="text-center py-4">
                      <img src="/assets/images/logo/soma-graph.png" alt="A+" />
                    </figure>

                    <figure className="qr-code">
                      <QRCode
                        value={JSON.stringify({ userId: currentUser._id })}
                        size={178}
                      />
                    </figure>
                    <section className="membership-info">
                      <div className="membership-info__item">
                        <p>
                          <small>Member Id</small>
                        </p>
                        <p className="h3">{`${currentUser.memberId || ""}`}</p>
                      </div>
                      <div className="membership-info__item">
                        <p>
                          <small>Member</small>
                        </p>
                        <p className="h3">{`${currentUser.name}`}</p>
                      </div>
                      <div
                        className={`membership__status ${isActive ? "active" : "inactive"
                          }`}
                      >
                        <i></i>
                        <p>
                          <small>{isActive ? "Activo" : "Inactivo"}</small>
                        </p>
                      </div>
                    </section>
                  </div>
                  <div className="membership-actions">
                    <div className="membership-actions__item">
                      {/*<figure className="logo-label">
                        <a
                          target="__blank"
                          href="https://zxing.org/w/chart?cht=qr&chs=350x350&chld=L&choe=UTF-8&chl=MECARD%3AN%3ARafael+Roig%3BORG%3ASOMA+%2B%3BTEL%3A5533343392%3BURL%3Asoma.group%3BEMAIL%3Arroig%40sma.com.mx%3BADR%3ACorporativo+Antara+II+Piso+3+Av.+Ej%C3%A9rcito+Nacional+843-B+Col.+Granada+C.P.+11520+Ciudad+de+M%C3%A9xico%3BNOTE%3ASOMA+%2B+Membership%3B%3B"
                        >
                          <img
                            src="/assets/images/label/wallet.png"
                            alt="Wallet"
                          />
                        </a>
                      </figure> */}
                      <button className="btn btn--type1">
                        Enviar por correo
                      </button>
                    </div>
                    <div className="membership-actions__item my-5">
                      <p className="my-5">
                        <a
                          href="#cancel"
                          onClick={(e) => handleCancelMembership(e)}
                        >
                          Cancelar mi suscripción
                        </a>
                      </p>
                    </div>
                    <div className="membership-actions__item">
                      <p>Para una mejor experiencia, descarga la aplicación</p>
                    </div>
                    <div className="membership-actions__item">
                      <figure className="logo-label">
                        <a href="https://apps.apple.com/mx/app/soma/id6466131820" target="_blank">
                          <img
                            src="/assets/images/label/apple.png"
                            alt="AppleStore"
                          />
                        </a>
                       
                      </figure>
                      <figure className="logo-label">
                        <a href="https://play.google.com/store/apps/details?id=com.somaplus.app&hl=es_NI" target="_blank">
                          <img
                            src="/assets/images/label/playstore.png"
                            alt="PlayStore"
                          />
                        </a>
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </>
    );
  }
};

export default EditMembershipScreen;
