import { AltCinema, CinemaId, Movie } from "../types/cinemas";
import { GenericResult, Meta, Cinema } from "../types/entidades";
import api from "./index";
import apiStrapi from "../strapi/index";

const commonHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getCinemas = async (token: string) => {
  console.log("------ getCinemas ------");
  const response = await api.get<CinemaId[]>("/cinemas", {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getCinemaMovies = async (token: string, cinemaId: string) => {
  console.log("------ getCinemaMovies ------", { cinemaId });
  const response = await api.get<Movie[]>(`/cinema/${cinemaId}/movies`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getMovie = async (token: string, movieId: string) => {
  console.log("------ getMovie ------", { movieId });
  const response = await api.get<Movie>(`/movie/${movieId}`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const strapiCinema = async (query: string) => {
  const response = await apiStrapi.get<GenericResult>(`/cinemas?${query}`);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data as {
    data: {
      id: number;
      attributes: Cinema;
    }[];
    meta?: Meta;
  };
};
