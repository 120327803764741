import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { uploadData } from "aws-amplify/storage";
import { v4 as uuidv4 } from "uuid";

interface PhotoPicComponentProps {
  onImageSelected: (hasImage: boolean, imageSaved: string) => void;
  avatar: string;
}

const PhotoPicComponent: React.FC<PhotoPicComponentProps> = ({
  onImageSelected,
  avatar,
}) => {
  const [modalShow, setModalShow] = React.useState(false);
  const [cameraModalShow, setCameraModalShow] = useState(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleOpenCameraModal = () => {
    setCameraModalShow(true);
    setModalShow(false);
    startCameraPreview();
  };

  const handleCloseCameraModal = () => {
    setCameraModalShow(false);
    setModalShow(false);
    stopCameraPreview();
  };

  const startCameraPreview = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      if (videoRef.current) {
        videoRef.current.srcObject = mediaStream;
      }
    } catch (error) {
      console.error("Error al acceder a la cámara:", error);
    }
  };

  const stopCameraPreview = () => {
    if (videoRef.current) {
      const mediaStream = videoRef.current.srcObject as MediaStream;
      if (mediaStream) {
        const tracks = mediaStream.getTracks();
        tracks.forEach((track) => {
          track.stop();
        });
      }
    }
  };

  const handleTakePhoto = async () => {
    // Obtén una imagen del video actual del elemento videoRef.current

    // Cierra el modal de la cámara
    handleCloseCameraModal();

    // Obtiene la imagen capturada del video
    const canvas = document.createElement("canvas");
    const video = videoRef.current;

    if (video && video.videoWidth && video.videoHeight) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      const context = canvas.getContext("2d");
      if (context) {
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);

        // Obtiene la imagen capturada como Base64
        const capturedImage = canvas.toDataURL();

        // Coloca la imagen capturada dentro del elemento pic-profile__image
        const imageElement = document.querySelector(".pic-profile__image img");
        if (imageElement) {
          imageElement.setAttribute("src", capturedImage);
        }
        const imageSaved = await savePicture(capturedImage);
        onImageSelected(true, imageSaved); // Actualizar la bandera en el componente padre
      }
    }
  };

  const handleSelectPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const selectedImage = e.target?.result as string;

        const imageElement = document.querySelector(".pic-profile__image img");
        if (imageElement) {
          imageElement.setAttribute("src", selectedImage);
        }

        const imageSaved = await savePicture(selectedImage);
        handleCloseCameraModal();
        onImageSelected(true, imageSaved); // Actualizar la bandera en el componente padre
      };

      reader.readAsDataURL(file);
    }
  };

  const savePicture = async (pathToImageFile: string) => {
    try {
      const fileName = uuidv4();
      const response = await fetch(pathToImageFile);
      const fileExtension = pathToImageFile.split(".").pop();
      const blob = await response.blob();

      const task = uploadData({
        key: `${fileName}.${fileExtension}`,
        data: blob,
        options: {
          contentType: "image/jpeg", // contentType is optional
        },
      });
      const saveImageResponse = await task.result;
      return saveImageResponse.key;
    } catch (err) {
      console.log("Error uploading file:", err);
    }
    return "";
  };

  return (
    <>
      {/* Modal principal */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Seleccionar foto
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="form-photo">
            <li>
              <button type="button" onClick={handleOpenCameraModal}>
                <i className="icon icon--camera"></i> Tomar foto
              </button>
            </li>
            <li>
              <label className="select-file">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleSelectPhoto}
                  style={{ display: "none" }}
                />
                <i className="icon icon--gallery"></i> Seleccionar foto del
                dispositivo
              </label>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <div className="form-row row--center">
            <button
              type="button"
              className="btn btn--type1"
              onClick={() => setModalShow(false)}
            >
              Cancelar
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Modal de la cámara */}
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={cameraModalShow}
        onHide={handleCloseCameraModal}
      >
        {/* Contenido del modal de la cámara */}
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Vista previa de la cámara
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video
            ref={videoRef}
            autoPlay={true}
            className="form-camera-preview"
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="form-row row--center">
            <button
              type="button"
              className="btn btn--type1"
              onClick={handleTakePhoto}
            >
              Tomar foto
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Botón principal para abrir el modal */}
      <div className="pic-profile">
        {/* <p>Selecciona tu foto</p> */}
        <div className="pic-profile__img">
          <button
            type="button"
            className="pic-profile__button"
            onClick={() => setModalShow(true)}
          >
            <figure className="pic-profile__image">
              {avatar ? (
                <img src={`${avatar}`} alt="user" />
              ) : (
                <img src="/assets/images/icons/profile.png" alt="Profile pic" />
              )}
            </figure>
            <div className="pic-profile__choose">
              <img src="/assets/images/icon/camera.svg" alt="Camera" />
            </div>
          </button>
        </div>
      </div>
    </>
  );
};

export default PhotoPicComponent;
