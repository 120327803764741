const StudioCard = ({ data, active, ...props }: any) => {

  const formatDay = (date: string) => {
    const dateObj = new Date(date);
    const day = dateObj.toLocaleString('es-ES', { weekday: 'short' });
    const dayNumber = dateObj.getDate();
    return `${day} ${dayNumber}`;
  };

  const formatMonth = (date: string) => {
    const dateObj = new Date(date);
    const month = dateObj.toLocaleString('es-ES', { month: 'long' });
    const year = dateObj.getFullYear();
    return `${month} ${year}`;
  };

  return (
    <div className={`studio-card ${(active) && 'active'}`} {...props}>
      <div className="studio-card__date">
        <span className="studio-card__day">{formatDay(data?.date)}</span>
        <span className="studio-card__hour">{data?.scheduleTime}</span>
        <span className="studio-card__month">{formatMonth(data?.date)}</span>
      </div>
      <div className="studio-card__content">
        <h3 className="studio-card__name">{data?.scheduleClass}</h3>
        <div>
          <span className="studio-card__instructor">{data?.scheduleInstruc}</span>
        </div>
        <span className="studio-card__duration">{data?.classlength} min.</span>
      </div>
    </div>
  );
};

export default StudioCard;