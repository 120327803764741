const InteractiveMap = () => {

  return (
    <div className="container">
      <iframe
        className="interactive-map"
        src="https://vennue.com.mx/artz-pedregal-soma-plus/"
        width="100%"
        height="100vh"
        allowFullScreen={true}
        loading="lazy"
      />
    </div>
  );
};

export default InteractiveMap;