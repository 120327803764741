
import React from 'react';
import { default as Slick } from "react-slick";
import Slide from './Slide/Slide.component';
import LoaderComponent from '../shared/Loader/Loader.component';

interface PromoSliderProps {
  data?: Array<any>;
  isLoading?: boolean;
};

const PromoSlider = ({ data, isLoading }: any) => {

  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const _renderSlides = (slides: any) => slides.map((slide: any, index: number) => (
    <Slide key={index} data={slide} />
  ));

  if (isLoading) return <LoaderComponent isLoading={isLoading} />;

  return (
    <div className='promo-slider'>
      <Slick {...settings}>
        {_renderSlides(data)}
      </Slick >
    </div>
  );
};

export default PromoSlider;