import SubHeaderComponent from '../../../../components/shared/Subheader/subHeader.component';

const RecoverStep2SuccessScreen = () => {
  

  return (
    <>
      <SubHeaderComponent
        isBackButton
        buttonText={'Regresar'} href={''}        
      />
      <section className="auth-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-12 mx-auto">
              <div className="auth-head">
                <figure className="auth-head__icon">
                  <img src="/assets/images/icon/check-success.svg" alt="Success" />
                </figure>
                <h1 className="h3 text-center">Contraseña actualizada</h1>
              </div>
              <div className="auth-body">
              
               <div className="form-row row--center row--last">
                <a href="/login" className="btn btn--type1">Inciar sesión</a>
               </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RecoverStep2SuccessScreen;
