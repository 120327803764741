import React, { useState } from 'react';
import HeaderBottom from '../../components/shared/HeaderBottom/HeaderBottom.component';
import SubHeaderComponent from '../../components/shared/Subheader/subHeader.component';

const EditLocationScreen = () => {

  const [location, setLocation] = useState(true);

  const handleChangeLocation = () => {

  }

  return (
    <>
      <SubHeaderComponent isBackButton={true} buttonText={"Regresar"} href={""} logo={false} />
      <HeaderBottom children={<>Ubicación</>} />
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 mx-auto">
                <div className="config">
                  <div className="config__item">
                    <span>Ubicación en segundo plano</span>
                    <div className="form-control-switch">
                      <label htmlFor="location" onClick={() => setLocation(loc => !loc)} className={`${location ? "active" : ""}`}>
                        <input type="checkbox" name="location" checked={location} onChange={handleChangeLocation} />
                        <i />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}

export default EditLocationScreen;
