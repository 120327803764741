interface headerProps {
  children?: JSX.Element | string;
}

const HeaderBottom = ({ children }: headerProps) => {
  return (
    <div className="header__bottom">
      <div className="container">{children}</div>
    </div>
  );
};

export default HeaderBottom;
