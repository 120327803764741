import { encode, ParsedUrlQueryInput } from "querystring";
import api from "./index";
import {
  IUser,
  IUserInvitation,
  IUserInvitationPaginate,
  IUserPaginate,
} from "../types/user.d";

const commonHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const userSignUp = async (user: Partial<IUser>, token: string) => {
  console.log("------ userSignUp ------", user);
  const response = await api.post<IUser>("/users", user, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getUser = async (id: string, token: string) => {
  console.log("------ getUser ------", { id, token });
  const response = await api.get<IUser>(`/users/${id}`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const updateUser = async (
  id: string,
  user: Partial<IUser>,
  token: string
) => {
  console.log("------ updateUser ------", { user });
  const response = await api.patch<IUser>(`/users/${id}`, user, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const searchUser = async (
  offset: number,
  limit: number,
  sort: string,
  query: Partial<IUser>,
  token: string
) => {
  console.log("------ searchUser ------", { query });
  const response = await api.post<IUserPaginate>(
    `/users/search?${encode({
      limit,
      offset,
      sort,
    } as ParsedUrlQueryInput)}`,
    query,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const validateUserInvitation = async (
  invCode: string,
  userId: string
) => {
  const response = await api.post<IUserInvitation[]>(
    "/users-invitation/validate",
    { invCode, userId }
  );
  console.log(response);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getUserInvitation = async (token: string, userId: string) => {
  const response = await api.get<IUserInvitationPaginate>(
    `/users-invitation?${encode({ userId } as ParsedUrlQueryInput)}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
