import React, { useState, useEffect } from "react";
import SubHeaderComponent from "../../../components/shared/Subheader/subHeader.component";
import { useLocation } from "react-router-dom";
import {
  signIn,
  fetchAuthSession,
  fetchUserAttributes,
  confirmResetPassword,
} from "aws-amplify/auth";
import OtpInput from "react-otp-input";
import { IUser } from "../../../types/user";
import { getUser } from "../../../api/usersApi";
import Swal from "sweetalert2";
import { searchProfileFavorites } from "../../../api/userBrandsFavorites";
import { getUrl } from "aws-amplify/storage";

const RecoverStep2Screen = () => {
  const location = useLocation();

  const [code, setCode] = useState("");
  const [currentUser, setCurrentUser] = useState<Partial<IUser>>({});

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumberOrSymbol, setHasNumberOrSymbol] = useState(false);

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirmVisibility = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const handlePasswordChange = (event: any) => {
    const newPassword = event.target.value;
    setCurrentUser({
      ...currentUser,
      password: newPassword,
    });
    setPasswordMismatch(newPassword !== currentUser.confirmPassword);

    // Validar las nuevas condiciones de la contraseña
    const hasUpperCaseLetter = /[A-Z]/.test(newPassword);
    const hasLowerCaseLetter = /[a-z]/.test(newPassword);
    const hasNumberOrSymbol = /[0-9!@#$%^&*]/.test(newPassword);

    setHasUpperCase(hasUpperCaseLetter);
    setHasLowerCase(hasLowerCaseLetter);
    setHasNumberOrSymbol(hasNumberOrSymbol);

    // Verificar todas las condiciones para habilitar el botón de envío
    setFormIsValid(
      newPassword.length >= 8 &&
      newPassword === currentUser.confirmPassword &&
      !passwordMismatch &&
      hasUpperCaseLetter &&
      hasLowerCaseLetter &&
      hasNumberOrSymbol
    );
  };

  const handlePasswordConfirmChange = (event: any) => {
    const newPasswordConfirm = event.target.value;
    setCurrentUser({
      ...currentUser,
      confirmPassword: newPasswordConfirm,
    });
    setPasswordMismatch(currentUser.password !== newPasswordConfirm);
    setFormIsValid(
      (currentUser.password || "").length >= 8 &&
      currentUser.password === newPasswordConfirm &&
      !passwordMismatch
    );
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (currentUser.password !== currentUser.confirmPassword) {
      setPasswordMismatch(true);
      return;
    }
    confirmRecover();
  };

  const fetchImage = async (image: string) => {
    const signedUrl = await getUrl({
      key: image,
      options: {
        expiresIn: 3600, // 1 hour
      },
    });

    return signedUrl.url.toString();
  };

  const signAndRedirect = async () => {
    try {
      const { phone, password } = currentUser;
      if (phone && password) {
        localStorage.setItem("auth", JSON.stringify({ phone, password }));

        const signInUser = await signIn({ username: phone, password });
        if (signInUser) {
          const authSession = await fetchAuthSession();
          const userAttributes = await fetchUserAttributes();
          if (authSession && userAttributes) {
            const somaPlusUserId =
              userAttributes["custom:somaPlusUserId"] || "";
            const jwtToken = authSession.tokens?.accessToken.toString() || "";
            const somaPlusUser = await getUser(somaPlusUserId, jwtToken);
            const userFavs = await searchProfileFavorites(
              somaPlusUser._id || "",
              jwtToken
            );

            // User avatar
            let userImage = "";
            if (somaPlusUser.avatar) {
              userImage = await fetchImage(somaPlusUser.avatar || "");
            }

            // Save user info in local storage and context
            localStorage.setItem(
              "userInfo",
              JSON.stringify({
                ...somaPlusUser,
                token: jwtToken,
                signedImage: userImage,
              })
            );

            userFavs && userFavs.length > 0
              ? (window.location.href = "/events")
              : (window.location.href = "/favorites-experience");
            Swal.fire("Contraseña actualizada", "", "success");
          }
        }
      }
    } catch (error) {
      setError(error as any);
    }
  };

  const confirmRecover = async () => {
    const { phone, password, confirmPassword } = currentUser;
    const valid = !!(phone && code && password && confirmPassword);
    const same = password === confirmPassword;
    if (!valid) {
      return setError("Please, fill all the fields");
    }
    if (!same) {
      return setError("Passwords don't match");
    }
    try {
      await confirmResetPassword({
        username: phone,
        confirmationCode: code,
        newPassword: password,
      }); // phone, code, password
      return signAndRedirect();
    } catch (error) {
      return setError(error as any);
    }
  };

  useEffect(() => {
    setCurrentUser({
      ...location.state.user,
    });
  }, []);

  useEffect(() => {
    const isValid =
      code.length === 6 &&
      (currentUser.password || "").length >= 8 &&
      currentUser.password === currentUser.confirmPassword &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumberOrSymbol;

    setFormIsValid(isValid);
  }, [
    code,
    currentUser.password,
    currentUser.confirmPassword,
    hasUpperCase,
    hasLowerCase,
    hasNumberOrSymbol,
  ]);

  const handlePasswordBlur = () => {
    setPasswordTouched(true);
  };

  const handleConfirmPasswordBlur = () => {
    setConfirmPasswordTouched(true);
  };

  return (
    <>
      <SubHeaderComponent href={""} buttonText={"Regresar"} isBackButton />
      <section className="auth-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-12 mx-auto">
              <div className="auth-head">
                <h1 className="h3 text-center">
                  Ingresa el código que recibiste y tu nueva contraseña.
                </h1>
              </div>
              <div className="auth-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <section className="opt-input">
                      <OtpInput
                        value={code}
                        onChange={setCode}
                        numInputs={6}
                        renderInput={(props) => (
                          <input {...props} className="form-control " />
                        )}
                      />
                    </section>
                  </div>
                  <div className="auth-section__instructions mb-0">
                    <p>Requerimientos:</p>
                    <ul>
                      <li>Longitud mínima de 8 caracteres</li>
                      <li>
                        Al menos una letra mayúscula y una letra minúscula
                      </li>
                      <li>Al menos un número o un símbolo</li>
                    </ul>
                  </div>
                  <div className="form-row">
                    <div className="form--password">
                      <button
                        type="button"
                        className="btn--password-show"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <i className="fa-regular fa-eye-slash"></i>
                        ) : (
                          <i className="fa-regular fa-eye"></i>
                        )}
                      </button>
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Nueva contraseña"
                        className="form-control"
                        value={currentUser.password}
                        onChange={handlePasswordChange}
                        onBlur={handlePasswordBlur}
                        required
                        minLength={8}
                      />
                    </div>
                    <div
                      className={`alert alert-danger ${passwordTouched &&
                        ((currentUser.password || "").length < 8 ||
                          !hasUpperCase ||
                          !hasLowerCase ||
                          !hasNumberOrSymbol)
                        ? ""
                        : "hidden"
                        }`}
                    >
                      {passwordTouched &&
                        (currentUser.password || "").length < 8 ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              "La contraseña debe tener al menos <strong>8 caracteres</strong>.",
                          }}
                        ></div>
                      ) : (
                        ""
                      )}
                      {passwordTouched && !hasUpperCase ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              "La contraseña debe contener al menos <strong>una letra mayúscula</strong>. ",
                          }}
                        ></div>
                      ) : (
                        ""
                      )}
                      {passwordTouched && !hasLowerCase ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              "La contraseña debe contener al menos <strong>una letra minúscula</strong>. ",
                          }}
                        ></div>
                      ) : (
                        ""
                      )}
                      {passwordTouched && !hasNumberOrSymbol ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              "La contraseña debe contener al menos <strong>un número o un símbolo</strong>. ",
                          }}
                        ></div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form--password">
                      <button
                        type="button"
                        className="btn--password-show"
                        onClick={togglePasswordConfirmVisibility}
                      >
                        {showPasswordConfirm ? (
                          <i className="fa-regular fa-eye-slash"></i>
                        ) : (
                          <i className="fa-regular fa-eye"></i>
                        )}
                      </button>
                      <input
                        type={showPasswordConfirm ? "text" : "password"}
                        placeholder="Confirmar contraseña"
                        className="form-control"
                        value={currentUser.confirmPassword}
                        onChange={handlePasswordConfirmChange}
                        onBlur={handleConfirmPasswordBlur}
                        required
                        minLength={8}
                      />
                    </div>
                    <div
                      className={`alert alert-danger ${confirmPasswordTouched && passwordMismatch
                        ? ""
                        : "hidden"
                        }`}
                      role="alert"
                    >
                      {confirmPasswordTouched && passwordMismatch
                        ? "Las contraseñas no coinciden."
                        : ""}
                    </div>
                  </div>
                  <div className="form-row row--last row--center">
                    <button
                      disabled={!formIsValid}
                      type="submit"
                      className="btn btn--type1"
                    >
                      Validar código
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RecoverStep2Screen;
