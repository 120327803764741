import React, { useEffect } from "react";
import { signOut } from "aws-amplify/auth";
import { deleteSession } from "../../../utils/helpers";

const LogoutScreen: React.FC = () => {
  useEffect(() => {
    const signOutUser = async () => {
      try {
        await signOut();
        deleteSession();
        localStorage.removeItem("userInfo");
        // Perform any additional actions after sign out
      } catch (error) {
        console.error("Error signing out:", error);
      } finally {
        // Redirect to login screen
        window.location.href = "/";
      }
    };

    signOutUser();
  }, []);

  return <div>Logging out...</div>;
};

export default LogoutScreen;
