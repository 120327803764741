import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getUrl } from "aws-amplify/storage";
import SubHeaderComponent from "../../../components/shared/Subheader/subHeader.component";
import StepperComponent from "../../../components/shared/Stepper/stepper.component";
import PhotoPicComponent from "../../../components/Form/PhotoPic/photoPic";
import { IUser } from "../../../types/user";
import { updateUser } from "../../../api/usersApi";
import Swal from "sweetalert2";
import { getCurrentAuthenticatedUser } from "../../../utils/utils";
import { format } from "date-fns";

const NewProfileScreen = () => {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState<Partial<IUser>>({});
  const [hasImage, setHasImage] = useState(false);
  const [birthday, setBirthday] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [incompleteProfile, setIncompleteProfile] = useState(false);

  const dateRef = useRef<any>();

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
  };

  const handleImageSelected = (hasImage: boolean, imageSaved: string) => {
    setHasImage(hasImage);
    setCurrentUser({
      ...currentUser,
      avatar: imageSaved,
    });
  };

  const handleBirthDayChange = (event: any) => {
    const newBirthday = event.target.value;
    setBirthday(newBirthday);
    setCurrentUser({
      ...currentUser,
      birthday: newBirthday,
    });
  };

  const fetchImage = async (image: string) => {
    const signedUrl = await getUrl({
      key: image,
      options: {
        expiresIn: 3600, // 1 hour
      },
    });
    return signedUrl.url.toString();
  };

  const getCurrentUser = async () => {
    try {
      const result = await getCurrentAuthenticatedUser();
      if (result) {
        const { somaUser, jwtToken } = result;
        if (somaUser) {
          setCurrentUser({
            ...somaUser,
            token: jwtToken,
          });
          setIncompleteProfile(!somaUser.name || !somaUser.lastName);
          if (somaUser.birthday) {
            setBirthday(format(new Date(somaUser.birthday), "yyy-MM-dd"));
          }
          if (somaUser.avatar) {
            setHasImage(true);
            fetchImage(somaUser.avatar).then((signedImage) => {
              setCurrentUser({
                ...somaUser,
                token: jwtToken,
                signedImage,
              });
            });
          }
        }
      }
    } catch (error) {
      console.log(error as any);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    const isValid =
      currentUser?.nickname?.trim() !== "" &&
      birthday.trim() !== "" &&
      currentUser?.address?.street?.trim() !== "" &&
      currentUser?.address?.zipCode?.trim() !== "" &&
      currentUser?.address?.neighborhood?.trim() !== "" &&
      currentUser?.address?.city?.trim() !== "" &&
      currentUser?.gender?.trim() !== "" &&
      hasImage;

    setFormIsValid(isValid);
  }, [
    currentUser.nickname,
    birthday,
    currentUser?.address,
    hasImage,
    currentUser.gender,
  ]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formIsValid && currentUser._id) {
      return updateUser(
        currentUser._id,
        { ...currentUser },
        currentUser.token || ""
      )
        .then((updatedUser) => {
          setCurrentUser({ ...updatedUser });
          navigate("/new-profile-success");
        })
        .catch((error) => setError(`${error}`));
    }
    return setError("All fields are required");
  };

  return (
    <>
      <SubHeaderComponent href={""} buttonText={"Regresar"} isBackButton />

      <section className="favorites-layout">
        <StepperComponent completedSteps={5} />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="auth-head">
                <h1 className="h3 text-center">Queremos consentirte</h1>
              </div>
              <div className="auth-body col-md-6 col-12 col-reset mx-auto">
                <div className="form-row">
                  <PhotoPicComponent
                    onImageSelected={handleImageSelected}
                    avatar={currentUser.signedImage || ""}
                  />
                </div>
                <form onSubmit={handleSubmit}>
                  {incompleteProfile && (
                    <>
                      <div className="form-row">
                        <label>
                          <small>
                            ¿Cómo te llamas? <span className="requierd">*</span>
                          </small>
                        </label>
                        <input
                          type="text"
                          placeholder="Nombre"
                          className="form-control"
                          value={currentUser.name || ""}
                          onChange={(evt) =>
                            setCurrentUser({
                              ...currentUser,
                              name: evt.target.value,
                            })
                          }
                          required
                        />
                        <div className="alert alert-danger hidden" role="alert">
                          El nombre es obligatorio.
                        </div>
                      </div>
                      <div className="form-row">
                        <label>
                          <small>
                            ¿Cuál es tu apellido?{" "}
                            <span className="requierd">*</span>
                          </small>
                        </label>
                        <input
                          type="text"
                          placeholder="Apellido"
                          className="form-control"
                          value={currentUser.lastName || ""}
                          onChange={(evt) =>
                            setCurrentUser({
                              ...currentUser,
                              lastName: evt.target.value,
                            })
                          }
                          required
                        />
                        <div className="alert alert-danger hidden" role="alert">
                          El apellido es obligatorio.
                        </div>
                      </div>
                    </>
                  )}
                  <div className="form-row">
                    <label>
                      <small>
                        ¿Cómo quieres que te llamemos?{" "}
                        <span className="requierd">*</span>
                      </small>
                    </label>
                    <input
                      type="text"
                      placeholder="Shortname"
                      className="form-control"
                      value={currentUser.nickname || ""}
                      onChange={(evt) =>
                        setCurrentUser({
                          ...currentUser,
                          nickname: evt.target.value,
                        })
                      }
                      required
                    />
                    <div className="alert alert-danger hidden" role="alert">
                      El Shortname es obligatorio.
                    </div>
                  </div>
                  <div className="form-row">
                    <label>
                      <small>
                        ¿Cuándo es la fecha de tu nacimiento?{" "}
                        <span className="requierd">*</span>
                      </small>
                    </label>
                    <input
                      type="text"
                      placeholder="Selecciona una fecha"
                      className="form-control"
                      value={birthday}
                      ref={dateRef}
                      onChange={handleBirthDayChange}
                      onFocus={() => (dateRef.current.type = "date")}
                      onBlur={() => (dateRef.current.type = "text")}
                      required
                    />
                    <div className="alert alert-danger hidden" role="alert">
                      La fecha de cumpleaños es obligatoria.
                    </div>
                  </div>
                  <div className="form-row">
                    <label>
                      <small>
                        ¿Cuál es tu género? <span className="requierd">*</span>
                      </small>
                    </label>
                    <select
                      className="form-select"
                      aria-label="genero"
                      value={currentUser.gender}
                      onChange={(evt) =>
                        setCurrentUser({
                          ...currentUser,
                          gender: evt.target.value,
                        })
                      }
                      required
                    >
                      <option selected value="">
                        Seleccionar
                      </option>
                      <option value="Masculino">Masculino</option>
                      <option value="Femenino">Femenino</option>
                      <option value="Otro">Prefiero no decirlo</option>
                    </select>
                    <div className="alert alert-danger hidden" role="alert">
                      El género es obligatorio.
                    </div>
                  </div>
                  <div className="form-row">
                    <label>
                      <small>
                        ¿A dónde podemos mandarte regalos?{" "}
                        <span className="requierd">*</span>
                      </small>
                    </label>
                    <input
                      type="text"
                      placeholder="Calle y número *"
                      className="form-control"
                      value={currentUser.address?.street}
                      onChange={(evt) =>
                        setCurrentUser({
                          ...currentUser,
                          address: {
                            ...currentUser.address,
                            street: evt.target.value,
                          },
                        })
                      }
                      required
                    />
                    <div className="alert alert-danger hidden" role="alert">
                      La dirección es obligatoria.
                    </div>
                  </div>
                  <div className="form-row">
                    <input
                      type="number"
                      placeholder="Código postal *"
                      className="form-control"
                      value={currentUser.address?.zipCode}
                      onChange={(evt) => {
                        const newPostalCode = evt.target.value;
                        const formattedPostalCode = newPostalCode.slice(0, 5);
                        setCurrentUser({
                          ...currentUser,
                          address: {
                            ...currentUser.address,
                            zipCode: formattedPostalCode,
                          },
                        });
                      }}
                      required
                    />
                    <div className="alert alert-danger hidden" role="alert">
                      El código postal es obligatorio.
                    </div>
                  </div>
                  <div className="form-row">
                    <input
                      type="text"
                      placeholder="Colonia *"
                      className="form-control"
                      value={currentUser.address?.neighborhood}
                      onChange={(evt) =>
                        setCurrentUser({
                          ...currentUser,
                          address: {
                            ...currentUser.address,
                            neighborhood: evt.target.value,
                          },
                        })
                      }
                      required
                    />
                    <div className="alert alert-danger hidden" role="alert">
                      La colonia es obligatoria.
                    </div>
                  </div>
                  <div className="form-row">
                    <input
                      type="text"
                      placeholder="Ciudad *"
                      className="form-control"
                      value={currentUser.address?.city}
                      onChange={(evt) =>
                        setCurrentUser({
                          ...currentUser,
                          address: {
                            ...currentUser.address,
                            city: evt.target.value,
                          },
                        })
                      }
                      required
                    />
                    <div className="alert alert-danger hidden" role="alert">
                      La ciudad es obligatoria.
                    </div>
                  </div>
                  <div className="form-row row--last row--center">
                    <button
                      type="submit"
                      className="btn btn--type1"
                      disabled={!formIsValid}
                    >
                      Siguiente
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewProfileScreen;
