import { GenericResult } from "../types/entidades";
import api from "./index";
import qs from "qs";

export const getBrands = async (cate: string, page?: number) => {
  const q = {
    sort: ["id:asc"],
    populate: "*",
    pagination: { pageSize: 100, page: page || 1 },
    filters: {
      $and: [{ category: { Name: { $eq: cate } } }],
    },
  };
  const query = qs.stringify({ ...q });
  const response = await api.get<GenericResult>(`/brands?${query}`);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getCatalogue = async (
  type: any,
  category: string,
  page?: number
) => {
  const q = {
    sort: ["Name:asc"],
    populate: "*",
    pagination: { pageSize: 999, page: page || 1 },
    filters: {
      $and: [{ category: { Name: { $eq: category } } }],
    },
  };
  const query = qs.stringify({ ...q });
  const response = await api.get<GenericResult>(`/${type}?${query}`);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getBrandsSort = async (
  category: string,
  subcategory: string,
  sort: string = "Name:asc"
) => {
  const q = {
    sort: [sort],
    populate: "*",
    pagination: { pageSize: 300, page: 1 },
    filters: {
      category: { Name: { $eq: category } },
      ...(subcategory && { subcategories: { Name: { $eq: subcategory } } }),
    },
  };
  const query = qs.stringify({ ...q });
  const response = await api.get<GenericResult>(`/brands?${query}`);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getCategories = async (query: string) => {
  const response = await api.get<GenericResult>(`/categories?${query}`);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
