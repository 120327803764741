import React, { useState, useEffect, useContext } from "react";
import SubHeaderComponent from "../../../../components/shared/Subheader/subHeader.component";
import { useNavigate } from "react-router-dom";
import StepperComponent from "../../../../components/shared/Stepper/stepper.component";
import { getBrandsSort } from "../../../../strapi/brands";
import Swal from "sweetalert2";
import { BrandsResult, StrapiDoc } from "../../../../types/entidades";
import { profileFavorites } from "../../../../api/userBrandsFavorites";
import LoaderComponent from "../../../../components/shared/Loader/Loader.component";
import { validateArray } from "../../../../utils/validations";
import Tabs from "../../../../components/Tabs/Tabs.component";
import AppContext from "../../../../context/appContext";
import { getCurrentAuthUserAttributes } from "../../../../utils/utils";

const BrandsExperienceScreen = () => {
  const navigate = useNavigate();
  const { userInfo } = useContext(AppContext);
  const [brands, setBrands] = useState<BrandsResult[]>();
  const [filteredBrands, setFilteredBrands] = useState<BrandsResult[]>();
  const [selectedBrands, setSelectedBrands] = useState<StrapiDoc[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState("Luxury");
  const tabList = [
    { name: "LUXURY", filter: "Luxury" },
    { name: "WOMEN", filter: "Women" },
    { name: "MEN", filter: "Men" },
    { name: "KIDS", filter: "Kids" },
    { name: "JEWELRY", filter: "Jewelry" },
    { name: "BEAUTY", filter: "Beauty" },
    { name: "HOME DECOR", filter: "Home Decor" },
    { name: "TECH", filter: "Tech" },
    { name: "SPORT", filter: "Sport" },
    { name: "FOOTWEAR", filter: "Foorwear" },
    { name: "ACCESSORIES", filter: "Accessories" },
  ];

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchKeyword = event.target.value.toLowerCase();
    const filteredBrands = (brands || []).filter((brand) =>
      brand.attributes.Name.toLowerCase().includes(searchKeyword)
    );
    setFilteredBrands(filteredBrands);
  };

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
  };

  const catalogBrands = async () => {
    setIsLoading(true);
    const brandsResult: any[] = [];

    await getBrandsSort("Brands", filter, "Name:asc")
      .then((result) => {
        if (result && result.data && result.data.length) {
          brandsResult.push(...result.data.map((b) => b));
        }
      })
      .catch((error) => setError(`${error}`))
      .finally(() => setIsLoading(false));

    setBrands(brandsResult as BrandsResult[]);
    setFilteredBrands(brandsResult as BrandsResult[]);
  };

  const handleSaveBrands = async () => {
    const userInfo = await getCurrentAuthUserAttributes();
    if (!userInfo) {
      return;
    }
    if (userInfo) {
      const { somaUser, jwtToken } = userInfo;

      try {
        await profileFavorites(
          { brands: selectedBrands, userId: somaUser._id },
          jwtToken
        );
        navigate("/favorites-places-to-eat");
      } catch (error) {
        setError(`${error}`);
      }
    }
  };

  const handleBrandSelection = (brand: StrapiDoc) => {
    if (selectedBrands.some((e) => e.documentId === `${brand.documentId}`)) {
      setSelectedBrands(
        selectedBrands.filter((item) => item.documentId !== brand.documentId)
      );
    } else {
      setSelectedBrands([...selectedBrands, brand]);
    }
  };

  useEffect(() => {
    catalogBrands();
  }, [filter]);

  return (
    <>
      <SubHeaderComponent href={""} buttonText={"Regresar"} isBackButton />
      <section className="favorites-layout">
        <StepperComponent completedSteps={1} />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="auth-head">
                <h1 className="h3 text-center">Select you favorite brands</h1>
              </div>
              <div className="auth-body">
                <div className="col-md-4 col-12 col-reset mx-auto mb-4">
                  <Tabs tabs={tabList} setFilter={setFilter} />
                </div>

                <div className="favorites-search col-md-4 col-12 col-reset mx-auto">
                  <div className="search-filter__group">
                    <input
                      type="text"
                      name="nombre"
                      placeholder="Buscar cualquier marca"
                      className="form-control"
                      onChange={handleSearch}
                    />
                    <span className="search-filter__icon material-icons-outlined">
                      search
                    </span>
                  </div>
                </div>

                <div className="favorites-filter col-md-10 col-12 col-reset mx-auto">
                  <LoaderComponent isLoading={isLoading} />

                  {!isLoading && (
                    <div className="nav-body">
                      {filteredBrands?.length === 0 && (
                        <span className="d-block text-center mx-auto">
                          No se encontraron marcas
                        </span>
                      )}
                      <ul>
                        {(filteredBrands || []).map((brand, index) => (
                          <li key={index}>
                            <div className="pill-check">
                              <input
                                type="checkbox"
                                id={brand.attributes.Name}
                                // data-div={brand.attributes.category.Name.toLowerCase()}
                                checked={
                                  selectedBrands.find(
                                    (e) => e.documentId === `${brand.id}`
                                  )
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  handleBrandSelection({
                                    documentId: `${brand.id}`,
                                    value: brand.attributes.Name,
                                  })
                                }
                              />
                              <div className="pill-check__item">
                                {brand.attributes.Name}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="form-row row--last row--center">
                  <div className="col-12 col-md-4">
                    <button
                      type="submit"
                      className="btn btn--type1 w-100"
                      onClick={handleSaveBrands}
                      disabled={!validateArray(selectedBrands)}
                    >
                      Siguiente
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BrandsExperienceScreen;
