import React from 'react';

interface StepperProps {
  completedSteps: number;
}

const StepperComponent = ({ completedSteps }: StepperProps) => {
  return (
    <div className='container'>
      <section className="stepper col-md-4 col-12 col-reset mx-auto">
        <ul>
          <li className={completedSteps >= 1 ? 'active' : ''}></li>
          <li className={completedSteps >= 2 ? 'active' : ''}></li>
          <li className={completedSteps >= 3 ? 'active' : ''}></li>
          <li className={completedSteps >= 4 ? 'active' : ''}></li>
          <li className={completedSteps >= 5 ? 'active' : ''}></li>
          <li className={completedSteps === 6 ? 'active' : ''}></li>
        </ul>
      </section>
    </div>
  );
};

export default StepperComponent;
