import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { IUser } from "../../types/user";
import Swal from "sweetalert2";
import { getCurrentAuthenticatedUser } from "../../utils/utils";
import { updateUser } from "../../api/usersApi";
import HeaderBottom from "../../components/shared/HeaderBottom/HeaderBottom.component";
import SubHeaderComponent from "../../components/shared/Subheader/subHeader.component";

const EditInfoScreen = () => {
  registerLocale("es", es);

  const navigate = useNavigate();
  const [user, setCurrentUser] = useState<Partial<IUser>>({});
  const [birthday, setBirthday] = useState<Date | null>(null);
  const [formIsValid, setFormIsValid] = useState(false);

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
  };

  const handleBirthDayChange = (event: any) => {
    const newBirthday = new Date(event);
    setBirthday(newBirthday);
    setCurrentUser({
      ...user,
      birthday: newBirthday,
    });
  };

  const getCurrentUser = async () => {
    try {
      const result = await getCurrentAuthenticatedUser();
      if (result) {
        const { somaUser, jwtToken } = result;
        if (somaUser) {
          setCurrentUser({
            ...somaUser,
            token: jwtToken,
          });
          if (somaUser.birthday) {
            setBirthday(new Date(somaUser.birthday));
          }
        }
      }
    } catch (error) {
      console.log(error as any);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    const isValid =
      user?.name?.trim() !== "" &&
      user?.nickname?.trim() !== "" &&
      birthday !== null;

    setFormIsValid(isValid);
  }, [user.name, user.nickname, birthday]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (formIsValid && user._id) {
      return updateUser(user._id, { ...user }, user.token || "")
        .then((updatedUser) => {
          setCurrentUser({ ...updatedUser });
          Swal.fire("Perfil actualizado", "", "success");
          navigate("/mi-perfil");
        })
        .catch((error) => setError(`${error}`));
    }
    return setError("All fields are required");
  };

  return (
    <>
      <SubHeaderComponent isBackButton={true} buttonText={"Regresar"} href={""} logo={false} />
      <HeaderBottom>
        Editar Información
      </HeaderBottom>
      <section className="dashboard-layout">
        <section className="module">
          <div className="container container--profile-form">
            <div className="row">
              <div className="col-12 mx-auto">
                <form onSubmit={handleSubmit}>
                  <div className="row gap-3">
                    <div className="col-12 mb-4">
                      <div className="form-row">
                        <label>
                          <small>Nombre</small>
                        </label>
                        <input
                          disabled
                          type="text"
                          className="form-control"
                          value={user.name}
                          onChange={(e: any) =>
                            setCurrentUser({ ...user, name: e.target.value })
                          }
                          required
                        />
                        <div className="alert alert-danger hidden" role="alert">
                          El nombre es obligatorio
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <div className="form-row">
                        <label>
                          <small>Shortname</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={user.nickname}
                          placeholder="¿Cómo quieres que nos dirijamos a ti?"
                          onChange={(e: any) =>
                            setCurrentUser({
                              ...user,
                              nickname: e.target.value,
                            })
                          }
                          required
                        />
                        <div className="alert alert-danger hidden" role="alert">
                          El nombre es obligatorio
                        </div>
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <label>
                        <small>¿Cúando es tu cumpleaños?</small>
                      </label>
                      <div className="form-row form-control-picker">
                        <DatePicker
                          selected={birthday}
                          onChange={handleBirthDayChange}
                          className="form-control"
                          placeholderText="Selecciona una fecha"
                          required={true}
                          locale={"es"}
                        />
                        <span className="icon date" />
                        <div className="alert alert-danger hidden" role="alert">
                          El cumpleaños es obligatorio
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-12 col-md-6 mb-4">
                        <label>
                            <small>Botana de preferencia</small>
                        </label>
                        <div className="form-control-select">
                          <select className="form-control" value={snack} required={true} onChange={(e) => setSnack(e.target.value)}>
                            <option value={""}>Selecciona una opción</option>
                            <option value={"Palomitas"}>Palomitas</option>
                            <option value={"Papas"}>Papas</option>
                          </select>
                          <span />
                        </div>
                      </div>
                      <div className="col-12 col-md-6 mb-4">
                        <label>
                            <small>Bebida de preferencia</small>
                        </label>
                        <div className="form-control-select">
                          <select className="form-control" value={drink} required={true} onChange={(e) => setDrink(e.target.value)}>
                            <option value={""}>Selecciona una opción</option>
                            <option value={"Agua"}>Agua</option>
                            <option value={"Cerveza"}>Cerveza</option>
                          </select>
                          <span />
                        </div>
                      </div> */}
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <i className="note text-center">Si deseas cambiar tu nombre o apellido, comuníquese con Concierge</i>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mt-4">
                      <button
                        className="btn btn--type1 btn--100"
                        type="reset"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Cancelar
                      </button>
                    </div>
                    <div className="col-6 mt-4">
                      <button className="btn btn--type1 btn--100" type="submit">
                        Guardar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default EditInfoScreen;
