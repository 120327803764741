import React from 'react';

const SimpleTable = ({ data, className, ...props }: any) => {
  const { rows } = data;

  const _renderColumns = (columns: any) => columns.map((column: any, index: number) => {
    if (index === 0) return <th scope="row">{column}</th>;
    return <td className='text-end'>{column}</td>;
  });

  const _renderRows = (rows: any) => rows.map((row: any) =>
    <tr>
      {_renderColumns(row)}
    </tr>
  );

  if (!rows) return null;

  return (
    <table className={`table ${className}`} {...props}>
      <tbody>
        {_renderRows(rows)}
      </tbody>
    </table>
  );
};

export default SimpleTable;