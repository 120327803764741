import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IUser } from "../../types/user";
import Swal from "sweetalert2";
import { getCurrentAuthenticatedUser } from "../../utils/utils";
import { updateUser } from "../../api/usersApi";
import OTPInput from "react-otp-input";
import HeaderBottom from "../../components/shared/HeaderBottom/HeaderBottom.component";
import {
  sendUserAttributeVerificationCode,
  updateUserAttributes,
  confirmUserAttribute,
} from "aws-amplify/auth";
import SubHeaderComponent from "../../components/shared/Subheader/subHeader.component";

const EditPhoneMailScreen = () => {
  const navigate = useNavigate();
  const [user, setCurrentUser] = useState<Partial<IUser>>({});
  const [formIsValid, setFormIsValid] = useState(false);

  const [userCopy, setUserCopy] = useState<Partial<IUser>>({});
  const [verificationCode, setVerificationCode] = useState("");
  const [showVerify, setShowVerify] = useState(false);

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
  };

  // const updateAmplifyUser = async (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   if (showVerify && verificationCode.length === 6) {
  //     try {
  //       await confirmUserAttribute({
  //         userAttributeKey: "email",
  //         confirmationCode: verificationCode,
  //       });
  //       return submitUser();
  //     } catch (error) {
  //       setError(`${error}`);
  //     }
  //   } else {
  //     const originalEmail = userCopy.email;
  //     const newEmail = user.email;
  //     if (originalEmail !== newEmail) {
  //       try {
  //         setShowVerify(true);
  //         await updateUserAttributes({
  //           userAttributes: {
  //             email: newEmail,
  //           },
  //         });
  //         await sendUserAttributeVerificationCode({
  //           userAttributeKey: "email",
  //         });
  //       } catch (error) {
  //         setError(`${error}`);
  //       }
  //     } else {
  //       submitUser();
  //     }
  //   }
  // };

  const getCurrentUser = async () => {
    try {
      const result = await getCurrentAuthenticatedUser();
      if (result) {
        const { somaUser, jwtToken } = result;
        if (somaUser) {
          setCurrentUser({
            ...somaUser,
            token: jwtToken,
          });
          setUserCopy({
            ...somaUser,
            token: jwtToken,
          });
        }
      }
    } catch (error) {
      console.log(error as any);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, []);

  useEffect(() => {
    const isValid = user?.email?.trim() !== "" && user?.phone?.trim() !== "";
    setFormIsValid(isValid);
  }, [user.email, user.phone]);

  const submitUser = () => {
    if (user._id) {
      return updateUser(user._id, { ...user }, user.token || "")
        .then((updatedUser) => {
          setCurrentUser({ ...updatedUser });
          Swal.fire("Perfil actualizado", "", "success");
          navigate("/mi-perfil");
        })
        .catch((error) => setError(`${error}`));
    }
    return setError("Error, try again");
  };

  return (
    <>
      <SubHeaderComponent isBackButton={true} buttonText={"Regresar"} href={""} logo={false} />
      <HeaderBottom>Teléfono / Correo electrónico</HeaderBottom>
      <section className="dashboard-layout">
        <section className="module">
          <div className="container container--profile-form">
            <div className="row">
              {!showVerify ? (
                <div className="col-12 mx-auto">
                  <form onSubmit={() => { }}>
                    <div className="row">
                      <div className="col-12 mb-4">
                        <div className="form-row">
                          <label>
                            <small>Teléfono</small>
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            value={user.phone}
                            required
                            onChange={(e: any) =>
                              setCurrentUser({
                                ...user,
                                phone: e.target.value,
                              })
                            }
                          />
                          <div
                            className="alert alert-danger hidden"
                            role="alert"
                          >
                            El teléfono es obligatorio
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-4">
                        <div className="form-row">
                          <label>
                            <small>Correo electrónico</small>
                          </label>
                          <input
                            disabled
                            type="text"
                            className="form-control"
                            value={user.email}
                            onChange={(e: any) =>
                              setCurrentUser({
                                ...user,
                                email: e.target.value,
                              })
                            }
                            required
                          />
                          <div
                            className="alert alert-danger hidden"
                            role="alert"
                          >
                            El nombre es obligatorio
                          </div>
                        </div>
                      </div>
                      <div className="col-12 mb-4">
                        <i className="note text-center">Si desea cambiar su número de teléfono o correo electrónico, comuniquese con Consierge</i>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-6 mt-4">
                        <button
                          className="btn btn--type1 btn--100"
                          type="reset"
                          onClick={() => {
                            navigate(-1);
                          }}
                        >
                          Cancelar
                        </button>
                      </div>
                      <div className="col-6 mt-4">
                        <button
                          className="btn btn--type1 btn--100"
                          type="submit"
                        >
                          Guardar
                        </button>
                      </div>
                    </div> */}
                  </form>
                </div>
              ) : (
                <div className="col-md-4 col-12 mx-auto">
                  <div className="auth-body">
                    <article className="mb40 col-12 col-reset">
                      <p className="text-center">
                        Por favor, escribe el código de verificación enviado al
                        <br /> {user.email}.
                      </p>
                    </article>
                    <form onSubmit={() => { }}>
                      <section className="opt-input">
                        <OTPInput
                          value={verificationCode}
                          onChange={setVerificationCode}
                          numInputs={6}
                          renderInput={(props) => (
                            <input {...props} className="form-control " />
                          )}
                        />
                      </section>
                      <div className="form-row row--last row--center">
                        <button
                          disabled={!formIsValid}
                          type="submit"
                          className="btn btn--type1"
                        >
                          Validar código
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default EditPhoneMailScreen;
