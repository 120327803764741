import { useState, useEffect, useContext } from "react";
import MainSlider from "../../components/MainSlider/MainSlider.component";
import { getEvents } from "../../strapi/events";
import qs from "qs";
import { getPromos } from "../../strapi/promos";
import PromoSlider from "../../components/PromoSlider/PromoSlider.component";
import AppContext from "../../context/appContext";
import { Link } from "react-router-dom";
import {
  checkAndAskForInviteCode,
  getCurrentAuthUserAttributes,
} from "../../utils/utils";

const EventsScreen = () => {
  const { userInfo } = useContext(AppContext);
  const [events, setEvents] = useState<any>([]);
  const [promos, setPromos] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingPromos, setIsLoadingPromos] = useState(true);

  const fetchEvents = async () => {
    setIsLoading(true);

    try {
      const queryString = qs.stringify({
        sort: "Date:ASC",
        populate: { image: { populate: "*" }, name: { populate: "*" } },
      });
      const response = await getEvents(queryString);
      console.log("events", response.data);
      setEvents(response.data);
    } catch (error) {
      console.error("Error al obtener eventos:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPromos = async () => {
    setIsLoadingPromos(true);
    try {
      const queryString = qs.stringify({
        sort: "Title:ASC",
        populate: { image: { populate: "*" }, name: { populate: "*" } },
        pagination: {
          pageSize: 100,
        },
      });
      const response = await getPromos(queryString);
      const filteredPromos = response.data.filter(
        (brand: any) => brand.attributes.isNew === true
      );
      setPromos(filteredPromos.slice(0, 5));
      console.log("promos", filteredPromos.slice(0, 5));
    } catch (error) {
      console.error("Error al obtener brands:", error);
    } finally {
      setIsLoadingPromos(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchEvents();
      fetchPromos();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getCurrentAuthUserAttributes();
      if (user) {
        const { somaUser } = user;
        const { jwtToken } = user;
        await checkAndAskForInviteCode(somaUser, jwtToken);
      }
    };
    fetchUser();
  }, []);

  return (
    <div className="container">
      <div className="row py-4">
        <div className="col-12 px-4">
          <h3 className="mb-0">¡Hola, {userInfo?.name}!</h3>
        </div>
      </div>

      <MainSlider data={events} isLoading={isLoading} />

      <div className="row py-4">
        <div className="col-12 px-4 py-4 text-center">
          <Link to="/events/Eventos" className="btn btn--type1 mx-auto">
            Todos los eventos
          </Link>
        </div>
      </div>

      <div className="row pt-4">
        <div className="col-12  px-4 pt-4 pb-2">
          <h4 className="subtitle mb-0">Nuevos Beneficios</h4>
        </div>
      </div>

      <PromoSlider data={promos} isLoading={isLoadingPromos} />
    </div>
  );
};

export default EventsScreen;
