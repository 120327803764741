import React from 'react';

export interface LoaderProps {
  isLoading: boolean;
}

const LoaderComponent = ({ isLoading, ...props }: LoaderProps) => {

  if (!isLoading) return null;

  return (
    <div className='loader-container'>
      <div className='loader' />
    </div>
  );
};

export default LoaderComponent;