
import React from 'react';
import { default as Slick } from "react-slick";
import Slide from './Slide/Slide.component';
import LoaderComponent from '../shared/Loader/Loader.component';

interface MainSliderProps {
  data?: Array<any>;
  isLoading?: boolean;
};

const MainSlider = ({ data, isLoading }: any) => {

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <img src="/assets/images/icons/arrow_forward.png" width={24} height="auto" />,
    prevArrow: <img src="/assets/images/icons/arrow_back.png" width={24} height="auto" />,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  const _renderSlides = (slides: any) => slides.map((slide: any, index: number) => (
    <Slide key={index} data={slide} />
  ));

  if (isLoading) return <LoaderComponent isLoading={isLoading} />;

  return (
    <div className='main-slider'>
      <Slick {...settings}>
        {_renderSlides(data)}
      </Slick >
    </div>
  );
};

export default MainSlider;