import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getPromos } from "../../strapi/promos";
import qs from "qs";
import { Promos } from "../../types/entidades";
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import HeaderBottom from "../../components/shared/HeaderBottom/HeaderBottom.component";
import LoaderComponent from "../../components/shared/Loader/Loader.component";
import { getEvents } from "../../strapi/events";
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

interface PromoDetailScreenProps {
  showInColumns: boolean;
}

const PromoDetailScreen: React.FC<PromoDetailScreenProps> = ({ showInColumns }) => {
  const navigate = useNavigate();
  const { option, category, id: idPromo } = useParams();
  const [promo, setPromo] = useState<Partial<Promos>>();
  const [type, setType] = useState<string>("");
  const [showterms, setShowterms] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const goBack = () => {
    navigate(-1);
  };

  const goBooking = () => {
    if (promo?.Title === "Cinemex Platino") {
      navigate(`/entertainment`, { state: { openMovies: true } })
    }
    else if (option === 'Studios') {
      navigate(`/${option}/request-studios/${type}/${promo?.Title}`, { state: { place: promo?.brand?.data.attributes.Name } })

    }
    else {
      navigate(`/${option}/request/${type}/${promo?.Title}`, { state: { place: promo?.brand?.data.attributes.Name } })
    }
  }

  const getPromo = async () => {
    setIsLoading(true);

    const query = {
      sort: ["id:asc"],
      populate: ["brand.category", "image", "imageDetail", "name"],
      filters: {
        id: idPromo
      }
    };

    let result;

    if (category === "Events") {
      result = await getEvents(qs.stringify({ ...query })).finally(() => setIsLoading(false));
    } else {
      result = await getPromos(qs.stringify({ ...query })).finally(() => setIsLoading(false));
    }

    if (result.data.length) {

      setPromo(result.data[0].attributes as Promos);

      if (result?.data[0]?.attributes?.brand) {
        let categoryAtt = (result.data[0].attributes as any).brand.data.attributes
          .category.data.attributes.Name;
        if (categoryAtt === "Entretainment") categoryAtt = "Entertainment"
        setType(
          categoryAtt === "Restaurants"
            ? "Dining"
            : categoryAtt === "Brands" || categoryAtt === "Interiors"
              ? "Shopping"
              : categoryAtt
        );
      } else {
        setType("Events");
      }
    } else {
      console.warn('Evento no encontrado');
    }
  };

  const EventInfo = ({ data }: any) => {
    const capitalizeFirstLetter = (string: string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
    const dateEnd: Date | undefined = data?.Date ? parseISO(data?.Date) : undefined;
    const formattedDate = capitalizeFirstLetter(dateEnd ? format(dateEnd, "EEEE, dd 'de' MMMM 'de' yyyy", { locale: es }) : '');
    const timeParts = data?.Time?.split(':') ?? [];
    let hours = parseInt(timeParts[0]);
    const amOrPm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const formattedTime = `${hours}:${timeParts[1]} ${amOrPm}`;
    const description = data?.description
    const mallsName = data?.name.data.attributes.name;

    return (
      <div className="event-info">
        <div className="d-flex flex-column">
          <span className="d-flex flex-row gap-2 align-items-center mb-3">
            <img width={24} height="auto" src="/assets/images/icon/date-white.svg" />
            {formattedDate}
          </span>
          <span className="d-flex flex-row gap-2 align-items-center mb-3">
            <img width={24} height="auto" src="/assets/images/icon/clock-white.svg" />
            {formattedTime}
          </span>
          <span className="d-flex flex-row gap-2 align-items-center mb-3">
            <img width={24} height="auto" src="/assets/images/icon/location-white.svg" />
            {mallsName}
          </span>
        </div>
        <div className="promo__description mb-4">
          {description}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (category && idPromo) {
      getPromo();
    }
  }, []);

  if (!promo || isLoading) return <LoaderComponent isLoading={isLoading} />

  return (
    <>
      <HeaderBottom
        children={
          <Link to="#" className="back-link" onClick={goBack}>
            <span className="material-icons-outlined">chevron_left</span>
            Regresar
          </Link>
        }
      />
      <section className="dashboard-layout">
        <section className="module">
          <div className="container promo pb-5">
            <div className="row">
              <div className="col-md-6 col-12 mx-auto mb-4">
                <h3 className="text-center">{promo?.Title}</h3>
                {promo?.ContentTitle && (
                  <h1 className="h2 mb-5 text-center">
                    {promo?.ContentTitle}
                  </h1>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12 mx-auto">
                <figure
                  className={`promo__image ${showInColumns ? "col-md-5" : ""}`}
                >
                  <img
                    src={promo?.imageDetail?.data.attributes.url}
                    alt={promo?.Title}
                    className="image"
                  />
                </figure>
              </div>
              <article
                className={`mb-5 ${!showInColumns ? "col-md-5 mx-auto d-flex flex-column justify-content-center" : "row"
                  }`}
              >
                <div
                  className={`promo__description mb-4 ${showInColumns ? "col-md-7" : ""
                    }`}
                >
                  {promo?.content && <Markdown remarkPlugins={[remarkGfm]}>{String(promo?.content)}</Markdown>}
                </div>

                {promo && category === 'Events' && <EventInfo data={promo} />}


                {promo?.AvailableToBook && (
                  <button className="btn btn--type1 btn--100  mb-4" onClick={() => goBooking()}>
                    {option === 'Studios' ? 'Agendar clase' : 'RSVP'}
                  </button>
                )}

                <div className="promo__terms">
                  <h4 className="dropdown mb-4" onClick={() => setShowterms((val) => !val)}>
                    Términos y condiciones
                  </h4>
                  {showterms && (
                    <div>
                      {promo?.terms && <Markdown remarkPlugins={[remarkGfm]}>{String(promo?.terms)}</Markdown>}
                    </div>
                  )}
                </div>

              </article>
            </div>
            {showInColumns && (
              <div className="row mb-5">
                <div className="col-md-4 mx-auto mb-5">
                  <button className="btn btn--type1 btn--100" onClick={() => goBooking()}>Reservar</button>
                </div>
              </div>
            )}
          </div>
        </section>
      </section>
    </>
  );
};

export default PromoDetailScreen;
