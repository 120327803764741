import api from "./index";
import { IPromosFavorites } from "../types/favorites";

const commonHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const promosFavorites = async (
  body: IPromosFavorites,
  token: string
) => {
  console.log("------ promosFavorites ------");
  const response = await api.post<IPromosFavorites>("/promosFavorites", body, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const searchPromosFavorites = async (body: any, token: string) => {
  console.log("------ searchPromosFavorites ------");
  const response = await api.post<IPromosFavorites[]>(
    `/promosFavorites/search`,
    body,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
