import React, { useState, useEffect } from "react";
import SubHeaderComponent from "../../../components/shared/Subheader/subHeader.component";
import { useLocation, useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import { IUser } from "../../../types/user";
import {
  resendSignUpCode,
  updateUserAttributes,
  confirmSignUp,
  signIn,
} from "aws-amplify/auth";
import Swal from "sweetalert2";
import { searchUser, userSignUp } from "../../../api/usersApi";
import { getCurrentAuthenticatedUser } from "../../../utils/utils";

const AuthCodeScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [user, setUser] = useState<Partial<IUser>>({});
  const [isLoading, setIsLoading] = useState(false);

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
    setIsLoading(false);
  };

  const resendSignUp = async () => {
    setIsLoading(true);
    try {
      const { phone } = user;
      if (phone) {
        await resendSignUpCode({ username: phone });
      }
    } catch (error) {
      setError((error as any).message);
    }
  };

  useEffect(() => {
    setFormIsValid(otp.length === 6);
  }, [otp]);

  useEffect(() => {
    setUser({
      ...location.state.user,
    });
  }, [location]);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    confirmSignUpUser(otp);
  };

  const confirmSignUpUser = async (code: string) => {
    setIsLoading(true);
    try {
      const { phone, password } = user;
      if (phone) {
        const confirmed = await confirmSignUp({
          username: phone,
          confirmationCode: code,
        });
        console.log(confirmed.isSignUpComplete);
        if (confirmed) {
          await signIn({ username: phone, password });
          const userData = await getCurrentAuthenticatedUser();

          console.log(userData);
          if (userData) {
            setIsLoading(false);
            navigate("/auth-code-success");
          }
        } else {
          navigate("/auth-code-error", {
            state: {
              user: {
                ...user,
              },
            },
          });
        }
      }
    } catch (error) {
      console.error(error);
      setError("Error creating user");
    }
  };

  return (
    <>
      <SubHeaderComponent href={""} buttonText={"Regresar"} isBackButton />
      <section className="auth-layout">
        {isLoading && (
          <div className="loader">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )}
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-12 mx-auto">
              <div className="auth-head">
                <h1 className="h3 text-center">Código de verificación</h1>
              </div>
              <div className="auth-body">
                <article className="mb40 col-12 col-reset">
                  <p className="text-center">
                    Por favor, escribe el código de verificación enviado al
                    <br /> {user.phone}.
                  </p>
                </article>
                <form onSubmit={handleSubmit}>
                  <section className="opt-input">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderInput={(props) => (
                        <input {...props} className="form-control " />
                      )}
                    />
                  </section>
                  <article className="mt32 col-12 col-reset">
                    <p className="text-center">
                      <button className="btn--link" onClick={resendSignUp}>
                        Reenviar código
                      </button>
                    </p>
                  </article>
                  <div className="form-row row--last row--center">
                    <button
                      disabled={!formIsValid}
                      type="submit"
                      className="btn btn--type1"
                    >
                      Validar código
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AuthCodeScreen;
