import React, { useState, useContext, useEffect } from "react";
import AppContext from "../../context/appContext";
import TitleSection from "../../components/shared/TitleSection/titleSection";
import { IUser } from "../../types/user";
import { useNavigate } from "react-router-dom";
import { getCurrentAuthenticatedUser } from '../../utils/utils';
import { conciergeOptions } from "./conciergeData";
import InfoModal from "../../components/shared/Modal/InfoModal/InfoModal.component";
import { getConcierge } from "../../strapi/concierge";
import qs from 'qs';
import { Attributes } from '../../types/cinemas';

const ConciergeScreen = () => {
  const { handleConcierge, userInfo } = useContext(AppContext);
  const [somaUser, setSomaUser] = useState<Partial<IUser>>({});
  const [token, setToken] = useState<string>('');
  const navigate = useNavigate();
  const phoneNumber = "+525566097673";
  const [modalContent, setModalContent] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [concierge, setConcierge] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const handlePress = async () => {
    let msj = "I would like to ";
    let action = "";

    const whatsappUrl = `whatsapp://send?phone=${phoneNumber}&text=¡Hi! ${msj} ${action} \nMy name is ${somaUser.name || ""
      } - ${somaUser._id || ""}`;

    window.open(whatsappUrl, "_blank");
  };

  const handleOnClick = async (data: any) => {
    setModalContent(data);
    setShowModal(true);
  };

  const getAuth = async () => {
    const authUser = await getCurrentAuthenticatedUser();
    return {
      somaUser: authUser?.somaUser || {},
      token: authUser?.jwtToken || '',
    };
  };

  const _renderOptions = (options: any) => options.map((option: any, index: number) => (
    <button
      key={index}
      className="concierge__card"
      onClick={() => {
        handleOnClick(option);
      }}
    >
      <img src={option?.attributes?.image?.data?.attributes?.url} alt={option.title} />
      <span>{option?.attributes?.Title}</span>
    </button>
  )
  );

  const fetchConcierge = async () => {
    setIsLoading(true);
    try {
      let queryString = qs.stringify({
        sort: 'createdAt:ASC',
        populate: {
          image: { populate: '*' },
        }
      });

      const response = await getConcierge(queryString);
      console.log('CONTENT CONCIERGE', response.data);

      const sortedConcierge = response.data.sort((a: any, b: any) => {
        const orderA = a?.attributes?.Order;
        const orderB = b?.attributes?.Order;
        return orderA - orderB;
      });

      setConcierge(sortedConcierge);
    } catch (error) {
      console.error('Error al obtener concierge:', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAuth().then((authData) => {
      const { somaUser, token } = authData;
      setSomaUser(somaUser);
      setToken(token);
    });

    fetchConcierge();
  }, []);

  return (
    <>
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-12 mx-auto justify-content-center">
                <TitleSection text={"Concierge"} />
                <article className="mb-5 text-center">
                  <p>
                    Agenda una cita, aclara tus dudas o reporta cualquier incidente con tan solo un mensaje.
                  </p>
                </article>
                <section className="concierge__links">
                  {concierge && _renderOptions(concierge)}
                </section>
                {/* <div className="col-12 col-md-4 mx-auto mb-5">
                  <button
                    className="btn btn--type1 btn--100  mb-5"
                    onClick={handlePress}
                  >
                    Empieza una conversación
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </section>

      <InfoModal data={{
        ...modalContent,
        show: showModal,
        setShow: setShowModal,
      }} />
    </>
  );
};

export default ConciergeScreen;
