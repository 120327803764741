import React, { useEffect, useState } from 'react';
import { CardProps } from '../CardPromo/CardPromo.component';
import CardPromo from '../CardPromo/CardPromo.component';
import TitleSection from '../TitleSection/titleSection';
import { IUser } from '../../../types/user';
import { CinemaId, Movie } from '../../../types/cinemas';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import Searcher from '../../Searcher/Searcher.component';
import LoaderComponent from '../Loader/Loader.component';
import { Category } from '../../../types/entidades';
import Tabs from '../../Tabs/Tabs.component';
import { strapiCinema } from '../../../api/cinemasApi';
import qs from 'qs';

export interface PromosSectionProps {
  titleSection: string;
  subtitleSection?: string;
  userName: string;
  hasFavoriteFilter?: boolean;
  entertainmentFilter?: boolean;
  promoCards?: CardProps["card"][];
  cinemas?: CinemaId[];
  changeCinema?: (cinema: any) => void;
  movies?: Movie[];
  user: Partial<IUser>;
  setSort?: any;
  isLoading: boolean;
  category?: string;
  setSubCategory?: any;
  subCategory?: string;
  setReload?: any;
  option?: string;
}

const PromosSection: React.FC<PromosSectionProps> = (promoSection) => {
  const { category, setSubCategory, subCategory, setReload } = promoSection;
  const location = useLocation();
  const navigate = useNavigate();
  const [filterFavorite, setFilterFavorite] = useState<boolean>(false);
  const [promosFiltered, setPromosFiltered] = useState<CardProps["card"][]>(
    promoSection?.promoCards ? promoSection?.promoCards : []
  );
  const [filterMovies, setFilterMovies] = useState<boolean>(true);
  const [selectedCinema, setSelectedCinema] = useState<string>(String(promoSection.cinemas?.[0]?._id));
  const type = location.pathname.replace('/', '');
  const tabList = [
    { name: "TODOS", filter: false },
    { name: "MIS FAVORITOS", filter: true }
  ];
  const [cinemaTabs, setCinemaTabs] = useState<any[]>([]);

  const fetchCinemasTabs = async () => {
    try {
      const query = {
        sort: ["id:asc"],
        populate: "*",
        filters: {
          $and: [{ enabled: true }],
        },
        pagination: {
          pageSize: 100,
        },
      };
      const response = await strapiCinema(qs.stringify({ ...query }));

      let tabsData = response?.data.map((cinema: any) => {
        const { name, apiId } = cinema.attributes;
        return {
          name: `${name.slice(name.lastIndexOf(' ') + 1)}`,
          filter: `${apiId}`,
          value: `${apiId}`,
        };
      });

      setSelectedCinema(tabsData[0].filter);
      setCinemaTabs(tabsData)
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const handleCategory = (subCategory: string) => {
    if (setSubCategory) setSubCategory(subCategory);
  };

  const handleTabClick = async (sort: string) => {
    setFilterFavorite((fav) => !fav);
  };

  useEffect(() => {
    if (promoSection?.promoCards) {
      if (filterFavorite || subCategory === "favorite") {
        const favoritedItems = promoSection?.promoCards.filter(
          (item) => item.attributes.isFavorite
        );
        setPromosFiltered(favoritedItems);
      } else {
        setPromosFiltered(promoSection?.promoCards);
      }
    }
  }, [subCategory, filterFavorite, promoSection.promoCards]);

  useEffect(() => {
    if (promoSection.changeCinema) promoSection.changeCinema(selectedCinema);
  }, [selectedCinema]);

  useEffect(() => {
    if (promoSection?.cinemas) {
      fetchCinemasTabs();
    }
  }, [])

  return (
    <>
      <div className='col-12 col-md-4 mx-auto'>
        <TitleSection text={promoSection.titleSection} />
        {promoSection?.subtitleSection && (
          <p className='text-center'>{promoSection?.subtitleSection}</p>
        )}
      </div>

      {promoSection.hasFavoriteFilter && category !== 'dinner' && (
        <div className='col-12 col-md-4 mb-4 mx-auto'>
          <Tabs tabs={tabList} setFilter={setFilterFavorite} />
        </div>
      )}

      {promoSection.entertainmentFilter && (
        <div className='col-12 col-md-4 mb-4 mx-auto'>
          {(cinemaTabs && selectedCinema) && <Tabs tabs={cinemaTabs} setFilter={setSelectedCinema} />}
        </div>
      )}

      {category === "dinner" && (
        <div className="favorite-filter">
          <div
            className={`option ${subCategory === "all" ? "active" : ""}`}
            onClick={() => handleCategory("all")}
          >
            {`${!filterFavorite ? "(" : ""} ALL ${!filterFavorite ? ")" : ""}`}
          </div>
          <div
            className={`option ${subCategory === "Casual Dinning" ? "active" : ""
              }`}
            onClick={() => handleCategory("Casual Dinning")}
          >
            {`${!filterFavorite ? "(" : ""} CASUAL DINNING ${!filterFavorite ? ")" : ""
              }`}
          </div>
          <div
            className={`option ${subCategory === "Fine Dinning" ? "active" : ""
              }`}
            onClick={() => handleCategory("Fine Dinning")}
          >
            {`${!filterFavorite ? "(" : ""} FINE DINNING ${!filterFavorite ? ")" : ""
              }`}
          </div>
          <div
            className={`option ${subCategory === "favorite" ? "active" : ""}`}
            onClick={() => handleCategory("favorite")}
          >
            {`${filterFavorite ? "(" : ""} FAVORITES ${filterFavorite ? ")" : ""
              }`}
          </div>
        </div>
      )}

      {!promoSection?.movies && (
        <>
          {promoSection.isLoading && (
            <div className="mb-4">
              <LoaderComponent isLoading={promoSection.isLoading} />
            </div>
          )}

          {!promoSection.isLoading && (
            <div className="promo-list justify-content-center">
              {promosFiltered.map((cardData) => (
                <CardPromo
                  key={cardData.id}
                  card={cardData}
                  showFavorite={promoSection.hasFavoriteFilter}
                  user={promoSection.user}
                  setReload={setReload}
                  option={promoSection?.option}
                />
              ))}
            </div>
          )}
        </>
      )}

      {promoSection?.movies && (
        <>
          {promoSection.isLoading && (
            <div className="mb-4">
              <LoaderComponent isLoading={promoSection.isLoading} />
            </div>
          )}

          {!promoSection.isLoading && (
            <div className="movie-list">
              {promoSection.movies &&
                promoSection.movies.length > 0 &&
                promoSection.movies.map((movie) => (
                  <div
                    className="movie-card"
                    key={movie._id}
                    onClick={() => navigate(`/Entertainment/request/movie/${movie._id}`)}
                  >
                    <figure>
                      <img src={movie.cover} alt={movie.name} />
                    </figure>
                    <figcaption>
                      <strong>{movie.name}</strong>
                      <div>
                        <span className="rating">{movie.info.rating}</span>
                        <span className="duration">{movie.info.duration}</span>
                      </div>
                      <div className="genres">
                        {movie.info.genre.slice(0, 3).map((genre) => (
                          <span>{genre}</span>
                        ))}
                      </div>
                    </figcaption>
                  </div>
                ))}
            </div>
          )}
        </>
      )}
      {/* <div className="col-md-4 mx-auto mb-5">
        <a href={`/request/${type}`} className="btn btn--type1 btn--100">Reservar</a>
      </div> */}
    </>
  );
};

export default PromosSection;
