import React, { useEffect, useState } from 'react';
import { CardProps } from '../CardPromo/CardPromo.component';
import CardPromo from '../CardPromo/CardPromo.component';
import TitleSection from '../TitleSection/titleSection';
import { IUser } from '../../../types/user';
import { CinemaId, Movie } from '../../../types/cinemas';
import Select from 'react-select';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Searcher from '../../Searcher/Searcher.component';
import LoaderComponent from '../Loader/Loader.component';
import { Category } from '../../../types/entidades';
import Tabs from '../../Tabs/Tabs.component';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

export interface EventsSectionProps {
  titleSection: string;
  userName: string;
  hasFavoriteFilter?: boolean;
  entertainmentFilter?: boolean;
  promoCards: CardProps["card"][];
  cinemas?: CinemaId[];
  changeCinema?: (cinema: any) => void;
  movies?: Movie[];
  user: Partial<IUser>;
  setSort?: any;
  isLoading: boolean;
  category?: string;
  setSubCategory?: any;
  subCategory?: string;
  setReload?: any;
}

const EventsSection: React.FC<EventsSectionProps> = (promoSection) => {
  const { category, setSubCategory, subCategory, setReload } = promoSection;
  const location = useLocation();
  const navigate = useNavigate();
  const openMovies = location.state ? true : false;
  const [filterFavorite, setFilterFavorite] = useState<boolean>(false);
  const [promosFiltered, setPromosFiltered] = useState<CardProps["card"][]>(
    promoSection.promoCards
  );
  const [filterMovies, setFilterMovies] = useState<boolean>(openMovies);
  const [selectedCinema, setSelectedCinema] = useState<string>(String(promoSection.cinemas?.[0]?._id));
  const type = location.pathname.replace('/', '');
  const tabList = [
    { name: "TODOS", filter: false },
    { name: "MIS FAVORITOS", filter: true }
  ];


  const handleCategory = (subCategory: string) => {
    if (setSubCategory) setSubCategory(subCategory);
  };

  const handleTabClick = async (sort: string) => {
    setFilterFavorite((fav) => !fav);
  };



  const _renderEvents = (events: any) => events.map((event: any, index: number) => {

    const date = parseISO(event.attributes.Date);
    const capitalizeFirstLetter = (string: string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
    const formattedDate = capitalizeFirstLetter(format(date, "E, dd MMM", { locale: es }));

    return (
      <div className="d-flex col-12 col-md-6 mb-4" key={index}>
        <Link className={`card-event`} to={`/Events/detail/Events/${event.id}`}>
          <figure>
            <img
              src={event?.attributes?.image.data.attributes.url}
              alt={event?.attributes?.Title}
            />
          </figure>
          <figcaption>
            <span className='card-event-date'>{formattedDate}</span>
            <span className='card-event-title'>{event?.attributes?.Title}</span>
            <span className='card-event-location'>
              <img src='/assets/images/icon/location.svg' alt='location' />
              <span>{event.attributes.name.data.attributes.name}</span>
            </span>
          </figcaption>
        </Link>
      </div>
    );
  });

  useEffect(() => {
    if (filterFavorite || subCategory === "favorite") {
      const favoritedItems = promoSection.promoCards.filter(
        (item) => item.attributes.isFavorite
      );
      setPromosFiltered(favoritedItems);
    } else {
      setPromosFiltered(promoSection.promoCards);
    }
  }, [subCategory, filterFavorite, promoSection.promoCards]);

  useEffect(() => {
    if (promoSection.changeCinema) promoSection.changeCinema(selectedCinema);
  }, [selectedCinema, promoSection]);

  return (
    <>
      <div className='col-12 col-md-4 mx-auto'>
        <TitleSection text={promoSection.titleSection} />
      </div>

      {promoSection.isLoading && (
        <div className="mb-4">
          <LoaderComponent isLoading={promoSection.isLoading} />
        </div>
      )}


      {!promoSection.isLoading && (
        <>
          <div className="searcher-container col-12 col-md-4 mx-auto mb-4 pb-4">
            <Searcher
              data={promoSection.promoCards}
              section={filterFavorite ? "favorite" : "all"}
              setData={setPromosFiltered}
              setSort={promoSection.setSort}
              sortOptions={false}
              className="searcher--events"
              label="Buscar eventos"
            />
          </div>

          <div className="row">
            {_renderEvents(promosFiltered)}
          </div>
        </>
      )}
    </>
  );
};

export default EventsSection;
