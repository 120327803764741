import React, { useState, useEffect } from 'react';
import SubHeaderComponent from '../../../components/shared/Subheader/subHeader.component';
import StepperComponent from '../../../components/shared/Stepper/stepper.component';

const GeolocationExperienceScreen = () => {
  const [locationPermission, setLocationPermission] = useState(false);

  useEffect(() => {
    const isLocationAllowed = localStorage.getItem('locationAllowed');
    if (isLocationAllowed === 'true') {
      setLocationPermission(true);
    }
  }, []);

  const handlePermissionAccept = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        localStorage.setItem('locationAllowed', 'true');
        setLocationPermission(true);
      });
    } else {
      alert('La geolocalización no está disponible en este navegador.');
    }
  };

  return (
    <>
      <SubHeaderComponent href={''} buttonText={'Regresar'} isBackButton />
      <section className="favorites-layout">
      <StepperComponent completedSteps={5} />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="auth-head">
                <h1 className="h3 text-center">Mejora tu experiencia</h1>
              </div>
              <div className="auth-body">
                <div className="text-center">
                  <article className="mb40">
                    <p>
                      <strong>Comparte tu ubicación</strong>
                    </p>
                    <p>
                      Permite que Soma Plus rastree tu ubicación para mejorar tu experiencia.
                    </p>
                  </article>
                  {locationPermission ? (
                    <div className="col-12 col-reset mb-4">
                      <div className="mb32 alert alert-success" role="alert">
                      Has permitido el acceso a tu ubicación.</div>
                      <div className="col-12 col-reset text-center">
                        <a href="/new-profile" className="btn btn--type1">Continuar</a>
                      </div>
                    </div>
                  ) : (
                    <div className="col-12 col-reset text-center">
                      <div className="col-12 col-reset mb-4">
                        <button
                          type="button"
                          className="btn btn--type1"
                          onClick={handlePermissionAccept}
                        >
                          Aceptar
                        </button>
                      </div>
                      <div className="col-12 col-reset">
                        <a href="/new-profile" className="btn--bold">
                          ¡No gracias, en otro momento!
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GeolocationExperienceScreen;
