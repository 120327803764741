import React, { useState, useEffect, useContext } from "react";
import SubHeaderComponent from "../../../../components/shared/Subheader/subHeader.component";
import { useNavigate } from "react-router-dom";
import StepperComponent from "../../../../components/shared/Stepper/stepper.component";
import { BrandsResult, StrapiDoc } from "../../../../types/entidades";
import Swal from "sweetalert2";
import { getBrands } from "../../../../strapi/brands";
import { profileFavorites } from "../../../../api/userBrandsFavorites";
import { IUser } from "../../../../types/user";
import {
  getCurrentAuthUserAttributes,
  getCurrentAuthenticatedUser,
} from "../../../../utils/utils";
import LoaderComponent from "../../../../components/shared/Loader/Loader.component";
import { validateArray } from "../../../../utils/validations";
import Tabs from "../../../../components/Tabs/Tabs.component";
import AppContext from "../../../../context/appContext";

const InteriorsExperienceScreen = () => {
  const navigate = useNavigate();
  const { userInfo } = useContext(AppContext);
  const [brands, setBrands] = useState<BrandsResult[]>();
  const [filteredBrands, setFilteredBrands] = useState<BrandsResult[]>();
  const [selectedBrands, setSelectedBrands] = useState<StrapiDoc[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchKeyword = event.target.value.toLowerCase();
    const filteredBrands = (brands || []).filter((brand) =>
      brand.attributes.Name.toLowerCase().includes(searchKeyword)
    );
    setFilteredBrands(filteredBrands);
  };

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
    setIsLoading(false);
  };

  const catalogBrands = async () => {
    setIsLoading(true);

    const result = await getBrands("Interiors")
      .then((result) => {
        setBrands(result.data as BrandsResult[]);
        setFilteredBrands(result.data as BrandsResult[]);
      })
      .catch((error) => setError(`${error}`))
      .finally(() => setIsLoading(false));
  };

  const handleSaveBrands = async () => {
    const userInfo = await getCurrentAuthUserAttributes();
    if (!userInfo) {
      return;
    }
    if (userInfo) {
      const { somaUser, jwtToken } = userInfo;
      try {
        await profileFavorites(
          { interiors: selectedBrands, userId: somaUser._id },
          jwtToken
        );
        navigate("/experience-geolocation");
      } catch (error) {
        setError(`${error}`);
      }
    }
  };

  const handleBrandSelection = (brand: StrapiDoc) => {
    if (selectedBrands.some((e) => e.documentId === `${brand.documentId}`)) {
      setSelectedBrands(
        selectedBrands.filter((item) => item.documentId !== brand.documentId)
      );
    } else {
      setSelectedBrands([...selectedBrands, brand]);
    }
  };

  useEffect(() => {
    catalogBrands();
  }, []);

  return (
    <>
      <SubHeaderComponent href={""} buttonText={"Regresar"} isBackButton />
      <section className="favorites-layout">
        <StepperComponent completedSteps={4} />
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="auth-head">
                <h1 className="h3 text-center">Select you favorite brands</h1>
              </div>
              <div className="auth-body">
                <div className="favorites-search col-md-4 col-12 col-reset mx-auto">
                  <div className="search-filter__group">
                    <input
                      type="text"
                      name="nombre"
                      placeholder="Buscar cualquier marca"
                      className="form-control"
                      onChange={handleSearch}
                    />
                    <span className="search-filter__icon material-icons-outlined">
                      search
                    </span>
                  </div>
                </div>

                <div className="favorites-filter col-md-10 col-12 col-reset mx-auto">
                  <LoaderComponent isLoading={isLoading} />

                  {!isLoading && (
                    <div className="nav-body">
                      {filteredBrands?.length === 0 && (
                        <span className="d-block text-center mx-auto">
                          No se encontraron marcas
                        </span>
                      )}
                      <ul>
                        {(filteredBrands || []).map((brand, index) => (
                          <li key={index}>
                            <div className="pill-check">
                              <input
                                type="checkbox"
                                id={brand.attributes.Name}
                                // data-div={brand.attributes.category.Name.toLowerCase()}
                                checked={
                                  selectedBrands.find(
                                    (e) => e.documentId === `${brand.id}`
                                  )
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  handleBrandSelection({
                                    documentId: `${brand.id}`,
                                    value: brand.attributes.Name,
                                  })
                                }
                              />
                              <div className="pill-check__item">
                                {brand.attributes.Name}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
                <div className="form-row row--last row--center">
                  <div className="col-12 col-md-4">
                    <button
                      type="submit"
                      className="btn btn--type1 w-100"
                      onClick={handleSaveBrands}
                      disabled={!validateArray(selectedBrands)}
                    >
                      Siguiente
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InteriorsExperienceScreen;
