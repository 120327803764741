import { GenericResult } from "../types/entidades";
import api from "./index";

export const getEvents = async (query: string) => {
  const response = await api.get<any>(`/events?${query}`);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
