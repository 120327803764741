import React, { useEffect, useState } from "react";
import HeaderComponent from "../../components/shared/Header/Header.component";
import PromosSection from "../../components/shared/PromosSection/PromosSection.component";
import { IUser } from "../../types/user";
import { PromosResult } from "../../types/entidades";
import { IPromosFavorites } from "../../types/favorites";
import { searchPromosFavorites } from "../../api/promosFavorites";
import qs from "qs";
import { getPromos } from "../../strapi/promos";
import { getCurrentAuthenticatedUser } from "../../utils/utils";

const BeautyScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [user, setUser] = useState<Partial<IUser>>({});
  const [sort, setSort] = useState<string>("id:asc");
  const [reload, setReload] = useState<string>("");
  const [brands, setBrands] = useState<PromosResult[]>([]);
  const [listFav, setListFav] = useState<Partial<IPromosFavorites[]>>([]);

  const promos = async (
    somaUser: any,
    token: string,
    loader: boolean = true
  ) => {
    if (loader) setIsLoading(true);
    const query = {
      sort: [sort || "id:asc"],
      populate: "*",
      filters: {
        $or: [{ brand: { category: { Name: { $eq: "Beauty" } } } }],
      },
      pagination: {
        pageSize: 100,
      },
    };
    const result = await getPromos(qs.stringify({ ...query })).finally(() => {
      setIsLoading(false);
    });

    // setBrands(result.data as PromosResult[]);
    getFavorites(somaUser, token, result.data as PromosResult[]);
  };

  const getFavorites = async (
    somaUser: any,
    token: string,
    resultBrands: PromosResult[]
  ) => {
    console.log({ userId: somaUser._id, isFavorite: true });
    searchPromosFavorites(
      { userId: somaUser._id, isFavorite: true },
      token
    ).then((pFavorite) => {
      console.log("favorites", pFavorite);
      setListFav(pFavorite);
      resultBrands.forEach((brand) => {
        const isFavorite = pFavorite.some(
          (favorite) => favorite.promoId === brand?.id
        );
        brand.attributes.isFavorite = isFavorite;
      });
      console.log(resultBrands);
      setBrands(resultBrands);
      console.log(brands);
    });
  };

  useEffect(() => {
    getCurrentAuthenticatedUser().then((authData) => {
      const token = authData?.jwtToken || "";
      setUser({
        ...authData?.somaUser,
        token,
      });
      promos(authData?.somaUser, token, false);
    });
  }, [sort, reload]);

  return (
    <>
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-12 mx-auto mb-5">
                <PromosSection
                  titleSection="Belleza"
                  userName={user.nickname || ""}
                  hasFavoriteFilter={true}
                  promoCards={brands}
                  user={user}
                  setSort={setSort}
                  isLoading={isLoading}
                  setReload={setReload}
                />
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default BeautyScreen;
