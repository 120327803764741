import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import Swal from "sweetalert2";
import { signInWithRedirect, signOut, signUp } from "aws-amplify/auth";
import { Hub } from "aws-amplify/utils";
import SubHeaderComponent from "../../../components/shared/Subheader/subHeader.component";
import { IUser } from "../../../types/user";
import { socialAuthEvent } from "../../../utils/utils";

const SignUpCompleteScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasNumberOrSymbol, setHasNumberOrSymbol] = useState(false);
  const [user, setUser] = useState<Partial<IUser>>({});
  const [isLoading, setIsLoading] = useState(true);

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
    setIsLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordConfirmVisibility = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };

  const handlePasswordChange = (event: any) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setUser({
      ...user,
      password: newPassword,
    });
    setPasswordMismatch(newPassword !== passwordConfirm);

    // Validar las nuevas condiciones de la contraseña
    const hasUpperCaseLetter = /[A-Z]/.test(newPassword);
    const hasLowerCaseLetter = /[a-z]/.test(newPassword);
    const hasNumberOrSymbol = /[0-9!@#$%^&*]/.test(newPassword);

    setHasUpperCase(hasUpperCaseLetter);
    setHasLowerCase(hasLowerCaseLetter);
    setHasNumberOrSymbol(hasNumberOrSymbol);

    // Verificar todas las condiciones para habilitar el botón de envío
    setFormIsValid(
      newPassword.length >= 8 &&
        newPassword === passwordConfirm &&
        !passwordMismatch &&
        hasUpperCaseLetter &&
        hasLowerCaseLetter &&
        hasNumberOrSymbol
    );
  };

  const handlePasswordConfirmChange = (event: any) => {
    const newPasswordConfirm = event.target.value;
    setPasswordConfirm(newPasswordConfirm);
    setUser({
      ...user,
      confirmPassword: newPasswordConfirm,
    });
    setPasswordMismatch(password !== newPasswordConfirm);
    setFormIsValid(
      password.length >= 8 &&
        password === newPasswordConfirm &&
        !passwordMismatch
    );
  };

  useEffect(() => {
    const isValid =
      user.name?.trim() !== "" &&
      user.email?.trim() !== "" &&
      user.phone?.trim() !== "" &&
      user.terms !== false &&
      password.length >= 8 &&
      password === passwordConfirm &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumberOrSymbol;

    setFormIsValid(isValid);
  }, [
    user.name,
    user.email,
    user.phone,
    user.terms,
    password,
    passwordConfirm,
    hasUpperCase,
    hasLowerCase,
    hasNumberOrSymbol,
  ]);

  useEffect(() => {
    if (location.state) {
      setUser({
        ...location.state.user,
        phone: "+52" + location.state.user.phone,
        terms: false,
        invCode: location.state.invCode,
      });
    }
  }, []);

  useEffect(() => {
    Hub.listen("auth", async (evenData) => {
      console.log("evenData", evenData);
      await socialAuthEvent(evenData, setIsLoading, setError, navigate);
    });
  }, []);

  const handlePasswordBlur = () => {
    setPasswordTouched(true);
  };

  const handleConfirmPasswordBlur = () => {
    setConfirmPasswordTouched(true);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    try {
      const { phone, email, password, confirmPassword, terms } = user;
      if (formIsValid) {
        if (phone && password) {
          await signUp({
            username: phone,
            password,
            options: {
              userAttributes: { email },
              autoSignIn: true,
            },
          });
          navigate("/auth-code", {
            state: {
              user: {
                ...user,
              },
              invCode: location.state.invCode,
            },
          });
        }
      } else {
        if (!terms) {
          setError("Please agree terms and conditions");
        } else if (password !== confirmPassword) {
          setPasswordMismatch(true);
          setError("Passwords don't match");
        } else {
          setError("Please fill all fields");
        }
      }
    } catch (err) {
      console.error(err);
      if ((err as any).code === "UserNotConfirmedException") {
        setError("Tu cuenta aún no ha sido verificada");
      } else if ((err as any).code === "PasswordResetRequiredException") {
        setError("Por favor, reinicia tu contraseña");
      } else if ((err as any).code === "NotAuthorizedException") {
        setError("¿Has olvidado tu contraseña?");
      } else if ((err as any).code === "UserNotFoundException") {
        setError("El usuario no existe");
      } else {
        setError((err as any).message);
      }
    }
  };

  return (
    <>
      <SubHeaderComponent href={""} buttonText={"Regresar"} isBackButton />
      <section className="auth-layout">
        <div className="container">
          {isLoading && (
            <div className="loading">
              <div className="loading__icon">
                <img src="/assets/images/icon/loading.svg" alt="Loading" />
              </div>
            </div>
          )}
          <div className="row">
            <div className="mx-auto col-md-4 col-12">
              <div className="auth-head">
                <figure className="auth-head__icon">
                  <img src="/assets/images/icon/lock_open.svg" alt="Auth" />
                </figure>
                <h1 className="text-center h3">Registro</h1>
              </div>
              <div className="auth-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <input
                      type="text"
                      placeholder="Nombre"
                      className="form-control"
                      value={user.name || ""}
                      onChange={(evt) => {
                        setUser({
                          ...user,
                          name: evt.target.value,
                        });
                      }}
                      required
                    />
                    <div className="hidden alert alert-danger" role="alert">
                      El nombre es obligatorio.
                    </div>
                  </div>
                  <div className="form-row">
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={user.phone || ""}
                      defaultCountry="MX"
                      international
                      smartCaret
                      className="form-control"
                      countryCallingCodeEditable={false}
                      limitMaxLength={true}
                      onChange={(evt) => {
                        setUser({
                          ...user,
                          phone: evt,
                        });
                      }}
                    />
                    <div className="hidden alert alert-danger" role="alert">
                      El teléfono es obligatorio.
                    </div>
                  </div>
                  <div className="form-row">
                    <input
                      type="email"
                      placeholder="Correo electrónico"
                      className="form-control"
                      value={user.email || ""}
                      onChange={(evt) => {
                        setUser({
                          ...user,
                          email: evt.target.value,
                        });
                      }}
                      required
                    />
                    <div className="hidden alert alert-danger" role="alert">
                      El correo electrónico es obligatorio.
                    </div>
                  </div>
                  <div className="mb-0 auth-section__instructions">
                    <p>Requerimientos:</p>
                    <ul>
                      <li>Longitud mínima de 8 caracteres</li>
                      <li>
                        Al menos una letra mayúscula y una letra minúscula
                      </li>
                      <li>Al menos un número o un símbolo</li>
                    </ul>
                  </div>
                  <div className="form-row">
                    <div className="form--password">
                      <button
                        type="button"
                        className="btn--password-show"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <i className="fa-regular fa-eye-slash"></i>
                        ) : (
                          <i className="fa-regular fa-eye"></i>
                        )}
                      </button>
                      <input
                        type={showPassword ? "text" : "password"}
                        placeholder="Contraseña"
                        className="form-control"
                        value={password}
                        onChange={handlePasswordChange}
                        onBlur={handlePasswordBlur}
                        required
                        minLength={8}
                      />
                    </div>
                    <div
                      className={`alert alert-danger ${
                        passwordTouched &&
                        (password.length < 8 ||
                          !hasUpperCase ||
                          !hasLowerCase ||
                          !hasNumberOrSymbol)
                          ? ""
                          : "hidden"
                      }`}
                    >
                      {passwordTouched && password.length < 8 ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              "La contraseña debe tener al menos <strong>8 caracteres</strong>.",
                          }}
                        ></div>
                      ) : (
                        ""
                      )}
                      {passwordTouched && !hasUpperCase ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              "La contraseña debe contener al menos <strong>una letra mayúscula</strong>. ",
                          }}
                        ></div>
                      ) : (
                        ""
                      )}
                      {passwordTouched && !hasLowerCase ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              "La contraseña debe contener al menos <strong>una letra minúscula</strong>. ",
                          }}
                        ></div>
                      ) : (
                        ""
                      )}
                      {passwordTouched && !hasNumberOrSymbol ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              "La contraseña debe contener al menos <strong>un número o un símbolo</strong>. ",
                          }}
                        ></div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form--password">
                      <button
                        type="button"
                        className="btn--password-show"
                        onClick={togglePasswordConfirmVisibility}
                      >
                        {showPasswordConfirm ? (
                          <i className="fa-regular fa-eye-slash"></i>
                        ) : (
                          <i className="fa-regular fa-eye"></i>
                        )}
                      </button>
                      <input
                        type={showPasswordConfirm ? "text" : "password"}
                        placeholder="Confirmar contraseña"
                        className="form-control"
                        value={passwordConfirm}
                        onChange={handlePasswordConfirmChange}
                        onBlur={handleConfirmPasswordBlur}
                        required
                        minLength={8}
                      />
                    </div>
                    <div
                      className={`alert alert-danger ${
                        confirmPasswordTouched && passwordMismatch
                          ? ""
                          : "hidden"
                      }`}
                      role="alert"
                    >
                      {confirmPasswordTouched && passwordMismatch
                        ? "Las contraseñas no coinciden."
                        : ""}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="checkDefault"
                        onChange={(evt) => {
                          setUser({
                            ...user,
                            terms: evt.target.checked,
                          });
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="checkDefault"
                      >
                        Acepto los Términos y condiciones
                      </label>
                    </div>
                  </div>
                  <div className="form-row row--center row--last">
                    <button
                      type="submit"
                      className="btn btn--type1 btn--100"
                      disabled={!formIsValid}
                    >
                      Registrar
                    </button>
                  </div>
                  <div className="form-row">
                    <div className="social-signup">
                      <p>o inicia sesión con</p>
                      <button
                        className="btn btn--social"
                        onClick={(e) => {
                          e.preventDefault();
                          signInWithRedirect({
                            provider: "Facebook",
                          }).catch((err) => {
                            signOut({
                              global: true,
                            });
                            console.error(err);
                            setError("Error signing in with Facebook");
                          });
                        }}
                      >
                        <i className="icon icon--facebook"></i>
                      </button>
                      <button
                        className="btn btn--social"
                        onClick={(e) => {
                          e.preventDefault();
                          signInWithRedirect({
                            provider: "Google",
                          }).catch((err) => {
                            signOut({
                              global: true,
                            });
                            console.error(err);
                            setError("Error signing in with Facebook");
                          });
                        }}
                      >
                        <i className="icon icon--google"></i>
                      </button>
                      <button
                        className="btn btn--social"
                        onClick={(e) => {
                          e.preventDefault();
                          signInWithRedirect({
                            provider: "Apple",
                          }).catch((err) => {
                            signOut({
                              global: true,
                            });
                            console.error(err);
                            setError("Error signing in with Facebook");
                          });
                        }}
                      >
                        <i className="icon icon--apple"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUpCompleteScreen;
