import React, { useState } from "react";
import SubHeaderComponent from "../../../components/shared/Subheader/subHeader.component";
import { NavLink, useNavigate } from "react-router-dom";
import OnboardingComponent from "../../../components/shared/Onboarding/onboarding.component";
import { validateUserInvitation } from "../../../api/usersApi";
import { IUser } from "../../../types/user";
import Swal from "sweetalert2";

const SignUpCodeScreen = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [formIsValid, setFormIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<Partial<IUser>>({
    email: "",
    phone: "",
    name: "",
  });

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Sorry!", `${errorMessage}`, "error");
    setIsLoading(false);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const errorMessage =
      "Sorry! We don't recognize the code, try again later or call us if the problem persists";
    try {
      validateUserInvitation(code, "")
        .then((res) => {
          setUser({
            email: res[0].email,
            phone: res[0].phone,
            name: res[0].name,
          });
          navigate("/signup-success", {
            state: {
              user: {
                email: res[0].email,
                phone: res[0].phone,
                name: res[0].name,
              },
              invCode: code,
            },
          });
        })
        .catch((err) => {
          console.log(err);
          setError(errorMessage);
        });
    } catch (err) {
      setError(errorMessage);
    }
  };

  const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
    setFormIsValid(event.target.value.trim() !== "");
  };

  return (
    <>
      <OnboardingComponent />
      <SubHeaderComponent
        href={"https://somaplus.com/"}
        buttonText={"Regresar a SOMA+"}
      />
      <section className="auth-layout">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 mx-auto">
              <div className="auth-head">
                <div className="auth-tabs">
                  <h3 className="text-center">Registro</h3>
                </div>
              </div>
              <div className="auth-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-row">
                    <label htmlFor="code">
                      ¿Tienes un código de invitación?
                    </label>
                    <input
                      id="code"
                      type="number"
                      placeholder="Ingresa código de invitación"
                      className="form-control"
                      required
                      value={code}
                      onChange={handleCodeChange}
                    />
                    <div className="alert alert-danger hidden" role="alert">
                      Código de invitación inválido
                    </div>
                  </div>
                  <div className="form-row row--center row--last">
                    <button
                      type="submit"
                      className="btn btn--type1"
                      disabled={!formIsValid}
                    >
                      Enviar
                    </button>
                  </div>

                  <div className="form-row text-center">
                    <NavLink to="/login" className="text-center">
                      Iniciar sesión
                    </NavLink>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SignUpCodeScreen;
