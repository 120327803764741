import React, { useState } from "react";
import Tab from "./Tab/Tab.component";

interface TabsProps {
  tabs: any;
  setFilter: any;
};

const Tabs = ({ tabs, setFilter }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(tabs[0]?.filter);

  const handleFilter = async (filter: string, setFilter: any) => {
    setFilter(filter);
    setActiveTab(filter);
  };

  const _renderTabs = (tabs: any) => tabs.map((tab: any, index: number) => {
    return (
      <Tab
        key={index}
        isActive={activeTab === tab?.filter}
        onClick={() => handleFilter(tab?.filter, setFilter)}
      >
        {tab?.name}
      </Tab>
    );
  });


  if (!tabs) return null;

  return (
    <div className="tabs">
      {_renderTabs(tabs)}
    </div>
  );
};

export default Tabs;