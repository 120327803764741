import { GenericResult } from '../types/entidades';
import api from './index';

export const getBanners = async (query: string) => {
  const response = await api.get<GenericResult>(`/banners?${query}`);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
