import { useLocation, useNavigate } from "react-router-dom";
import SubHeaderComponent from "../../../../components/shared/Subheader/subHeader.component";
import { useEffect, useState } from "react";
import { IUser } from "../../../../types/user";

const RecoverStep1SuccessScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState<Partial<IUser>>({});

  const nextPage = () => {
    navigate("/recover-password-step2", {
      state: {
        ...location.state
      },
    });
  };

  useEffect(() => {
    setUser({
      ...location.state.user,
    });
  }, []);

  return (
    <>
      <SubHeaderComponent isBackButton buttonText={"Regresar"} href={""} />
      <section className="auth-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-12 mx-auto">
              <div className="auth-head">
                <figure className="auth-head__icon">
                  <img
                    src="/assets/images/icon/check-success.svg"
                    alt="Success"
                  />
                </figure>
                <h1 className="h3 text-center">
                  Te hemos enviado un código al correo electrónico {location?.state?.email?.CodeDeliveryDetails?.Destination} vinculado al número {user.phone}
                </h1>
              </div>
              <div className="auth-body">
                <div className="form-row row--center row--last">
                  <button className="btn btn--type1" onClick={() => nextPage()}>
                    Continuar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RecoverStep1SuccessScreen;
