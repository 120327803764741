import {
  PaymentMethodDetails,
  StripeProduct,
  StripeSetupIntentResult,
  StripeSubscriptionResult,
} from "../types/stripe";
import api from "./index";

const commonHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const getStripeProducts = async (token: string) => {
  console.log("------ getStripeProducts ------");
  const response = await api.get<StripeProduct[]>("/stripe/products", {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const newStripeSubscription = async (token: string, body: any) => {
  console.log("------ newStripeSubscription ------", { body });
  const response = await api.post<StripeSubscriptionResult>(
    "/stripe/subscription",
    body,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const newStripeSetupIntent = async (token: string, body: any) => {
  console.log("------ newStripeSetupIntent ------", { body });
  const response = await api.post<StripeSetupIntentResult>(
    "/stripe/setup-intent",
    body,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  console.log("------ newStripeSetupIntent response ------", response.data);
  return response.data;
};

export const newStripeCheckoutSession = async (token: string, body: any) => {
  console.log("------ newStripeCheckoutSession ------", { body });
  const response = await api.post<{
    sessionUrl: string | null;
    ephemeralKey: string | undefined;
    customer: any;
    publishableKey: string;
  }>("/stripe/checkout-session", body, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }

  return response.data;
};

export const getCustomerPaymentMethods = async (
  token: string,
  customerId: string
) => {
  console.log("------ getCustomerPaymentMethods ------");
  const response = await api.get<{
    object: string;
    data: PaymentMethodDetails[];
    has_more: boolean;
    url: string;
  }>(`/stripe/customer/${customerId}/payment-methods`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  console.log(
    "------ getCustomerPaymentMethods response ------",
    response.data
  );
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};

export const getCustomerPaymentIntent = async (
  token: string,
  customerId: string
) => {
  console.log("------ getCustomerPaymentIntent ------");
  const response = await api.get<{
    paymentIntent: string;
    ephemeralKey: string;
    customer: string;
  }>(`/stripe/customer/${customerId}/payment-intent`, {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
  });
  console.log("------ getCustomerPaymentIntent response ------", response.data);
  if (response.status !== 200) {
    throw Error((response.data as any).error);
  }
  return response.data;
};
