import { useContext, useRef } from "react";
import { motion, useDragControls } from "framer-motion"
import AppContext from "../../context/appContext";


const Layout = ({ children }: any) => {
  const constraintsRef = useRef(null);
  const controls = useDragControls()
  const { userInfo } = useContext(AppContext);

  const whatsappUrl = `whatsapp://send?phone=+525566097673&text=Hola, soy ${userInfo?.name + ' ' + userInfo?.lastName || ''} - (${userInfo?._id || ''}) quiero hablar con mi Concierge.`;

  return (
    <main className="app-main" ref={constraintsRef}>
      {children}

      <motion.a className="chat" drag dragControls={controls} href={whatsappUrl} dragConstraints={constraintsRef}>
        <img src="/assets/images/icon/chat.svg" alt="Chat" />
      </motion.a>
    </main>
  );
};

export default Layout;