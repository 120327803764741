import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PromosResult } from "../../../types/entidades";
import { promosFavorites } from "../../../api/promosFavorites";
import { IUser } from "../../../types/user";
import { category } from '../../../pages/Category/CategoryData';

export interface CardProps {
  card: PromosResult;
  showFavorite?: boolean;
  user: Partial<IUser>;
  //useState<string>
  setReload?: any;
  className?: string;
  category?: string;
  option?: string;
}

const CardPromo: React.FC<CardProps> = ({
  card,
  showFavorite,
  user,
  setReload,
  className,
  category,
  option
}) => {
  const [promo, setPromo] = useState<Partial<PromosResult>>({ ...card });

  const toggleFill = async () => {
    setPromo({
      ...card,
      attributes: {
        ...card?.attributes,
        isFavorite: !promo?.attributes?.isFavorite,
      },
    });
    const data: any = await promosFavorites(
      {
        userId: user._id || "",
        isFavorite: !promo?.attributes?.isFavorite,
        promoId: card.id,
      },
      user.token || ""
    );

    if (setReload) {
      if (data) setReload(data.updatedAt);
    }
  };

  return (
    <div className={`card-promo ${className}`}>
      <figure>
        <img
          src={promo?.attributes?.image.data.attributes.url}
          alt={promo?.attributes?.Title}
        />
      </figure>
      <figcaption>
        <span>{promo?.attributes?.Title}</span>
        {/* {promo?.attributes?.brand && <span>{promo?.attributes?.brand}</span>} */}
      </figcaption>
      <Link to={`/${option}/detail/${category ? category : 'Brands'}/${promo.id}`} />
      {showFavorite && (
        <button
          className={`card-promo__favorite ${promo?.attributes?.isFavorite ? "active" : ""
            } `}
          onClick={toggleFill}
        >
          <i className="fas fa-heart"></i>
        </button>
      )}
    </div>
  );
};

export default CardPromo;
