import React, { useState } from 'react';
import Slider from "react-slick";
import { useCookies } from 'react-cookie';


const OnboardingComponent = () => {
  const [cookies, setCookie] = useCookies(['onboardingViewed']);

  const [hideComponent, setHideComponent] = useState(false);

  const handleSkip = () => {
    setHideComponent(true);
    setCookie('onboardingViewed', true, { path: '/' });
  };

  // Check if the onboarding has been viewed already
  if (cookies.onboardingViewed || hideComponent) {
    return null; // Return null to hide the component
  }

  const Arrow = ({ direction, className, style, onClick }: any) =>
    <div className={`arrow arrow-${direction} ${className}`} {...style ? style : ''} onClick={onClick}>
      <img src={`/assets/images/icon/${direction}.svg`} alt={direction} />
    </div>
    ;

  var settings = {
    dots: true,
    arrows: true,
    dotsClass: "slick-dots slick-numbered-dots", // Agrega esta línea para agregar una clase personalizada
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow direction='forward' />,
    prevArrow: <Arrow direction='backward' />,
    customPaging: function (i: any) {
      return <span className="slick-number">{i + 1}</span>; // Muestra el número de cada slide
    },
  };

  return (
    <>
      <div className="onboarding">
        <div className="onboarding__inner">
          <button
            type="button"
            className="btn--bold mx-3 my-2"
            onClick={handleSkip}
          >Saltar</button>
          <Slider {...settings}>
            <div className="onboarding__item">
              <h3>Servicio exclusivo de Concierge</h3>
              <figure className="onboarding__image">
                <img src="/assets/images/onboarding/slide-1.png" alt="Onboarding" />
              </figure>
              <article className="onboarding__article">
                <p>Estás a solo un mensaje de recibir la atención más personalizada.</p>
              </article>
            </div>
            <div className="onboarding__item">
              <h3>Eleva tu experiencia de compra</h3>
              <figure className="onboarding__image">
                <img src="/assets/images/onboarding/slide-2.png" alt="Onboarding" />
              </figure>
              <article className="onboarding__article">
                <p>Disfruta de beneficios exclusivos en marcas de lujo. Agenda tu cita con un personal shopper o un interiorista y haz que tu experiencia sea inolvidable.</p>
              </article>
            </div>
            <div className="onboarding__item">
              <h3>Estacionamiento y valet parking de cortesía</h3>
              <figure className="onboarding__image">
                <img src="/assets/images/onboarding/slide-3.png" alt="Onboarding" />
              </figure>
              <article className="onboarding__article">
                <p>Disfruta de tu visita desde el primer momento y despreocúpate por encontrar dónde estacionarte.</p>
              </article>
            </div>
            <div className="onboarding__item">
              <h3>Tu mesa te está esperando</h3>
              <figure className="onboarding__image">
                <img src="/assets/images/onboarding/slide-4.png" alt="Onboarding" />
              </figure>
              <article className="onboarding__article">
                <p>Reserva con nosotros y déjate consentir mientras disfrutas de la cocina más especializada.</p>
              </article>
            </div>
            <div className="onboarding__item">
              <h3>Entra con SOMA+</h3>
              <figure className="onboarding__image">
                <img src="/assets/images/onboarding/slide-5.png" alt="Onboarding" />
              </figure>
              <article className="onboarding__article">
                <p>Reserva tu lugar en los mejores Fitness Studios y disfruta de clases incluidas y precios preferenciales.</p>
              </article>

            </div>
            <div className="onboarding__item">
              <h3>Consiénte y cuida de tu cuerpo</h3>
              <figure className="onboarding__image">
                <img src="/assets/images/onboarding/slide-6.png" alt="Onboarding" />
              </figure>
              <article className="onboarding__article">
                <p>Explora la gran variedad de marcas y Beauty Studios que tenemos para ti.</p>
              </article>
            </div>
            <div className="onboarding__item">
              <h3>Disfruta con SOMA+</h3>
              <figure className="onboarding__image">
                <img src="/assets/images/onboarding/slide-7.png" alt="Onboarding" />
              </figure>
              <article className="onboarding__article">
                <p>2x1 en cines y más beneficios de entretenimiento.</p>
              </article>
            </div>
            <div className="onboarding__item">
              <h3>Eventos exclusivos</h3>
              <figure className="onboarding__image">
                <img src="/assets/images/onboarding/slide-8.png" alt="Onboarding" />
              </figure>
              <article className="onboarding__article">
                <p>Reserva tu lugar en eventos únicos sobre wellness, gatronomía, moda, arte y beauty.</p>
              </article>
              <button
                type='button'
                onClick={handleSkip}
                className="btn btn--type1"
              >Continuar</button>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default OnboardingComponent;
