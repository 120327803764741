import React, { createContext } from 'react';
import { IUser } from "../types/user";

export interface AppContextType {
  showDrawer: boolean;
  handleShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  loadingAction: boolean;
  handleLoadingAction: React.Dispatch<React.SetStateAction<boolean>>;
  userInfo: IUser | null;
  handleUserInfo: React.Dispatch<React.SetStateAction<IUser | null>>;
  concierge: any;
  handleConcierge: React.Dispatch<React.SetStateAction<any>>;
}

const AppContext = createContext<AppContextType>({
  showDrawer: false,
  handleShowDrawer: () => { },
  loadingAction: false,
  handleLoadingAction: () => { },
  userInfo: null,
  handleUserInfo: () => { },
  concierge: {},
  handleConcierge: () => { },
});

export default AppContext;