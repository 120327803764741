import SubHeaderComponent from '../../../../components/shared/Subheader/subHeader.component';

const RecoverStep1ErrorScreen = () => {
  

  return (
    <>
      <SubHeaderComponent
        isBackButton
        buttonText={'Regresar'} href={''}        
      />
      <section className="auth-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-4 col-12 mx-auto">
              <div className="auth-head">
                <figure className="auth-head__icon">
                  <img src="/assets/images/icon/error.svg" alt="Error" />
                </figure>
                <h1 className="h3 text-center">Disculpa, tu número teléfonico y/o correo electrónico parece ser incorrecto. Por favor, inténtalo de nuevo.</h1>
              </div>
              <div className="auth-body">
              <article>
                  <p className="gray-color text-center">Si el problema continúa, visítanos en el mostrador de conserjería en ARTZ para que podamos brindarte más ayuda.</p>
               </article>
               <div className="form-row row--center row--last">
                <a href="/login" className="btn btn--type1">Regresar</a>
               </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default RecoverStep1ErrorScreen;
