import React, { useEffect } from 'react'

type Props = {}

const DetectDeviceScreen = (props: Props) => {


  useEffect(() => {
    const userAgent = navigator.userAgent;
    console.log(userAgent, "Useragent")

    if (/iPad|iPhone|iPod/.test(userAgent)) {
      window.location.href = 'https://apps.apple.com/mx/app/soma/id6466131820?l=en-GB';
      console.log("Apple");
    } 
    // Detectar dispositivo Android
    else if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.somaplus.app&hl=es_NI';
      console.log("Android");
    }
  }, []);


  return (
    <section className="auth-layout">
      <div className="container">
        <div className="row">
          <div className="mx-auto col-lg-4 col-md-6 col-12">
            <div className="auth-head">
              <div className="auth-tabs">
                <h3 className="text-center">Bienvenido</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DetectDeviceScreen