import api from './index';
import { Reservations, ReservationsResult } from '../types/entidades';

const commonHeaders = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
};

export const newReservation = async (
	body: Partial<Reservations>,
	token: string,
) => {
	console.log('------ createReservations ------');
	const response = await api.post('/reservation', body, {
		headers: {
			...commonHeaders,
			Authorization: `Bearer ${token}`,
		},
	});
	if (response.status !== 200) {
		throw Error((response.data as any).error);
	}
	return response.data;
};

export const searchReservations = async (body: any, token: string) => {
	console.log('------ searchReservations ------');
	const response = await api.post<ReservationsResult[]>(
		`/search/reservation`,
		body,
		{
			headers: {
				...commonHeaders,
				Authorization: `Bearer ${token}`,
			},
		},
	);
	if (response.status !== 200) {
		throw Error((response.data as any).error);
	}
	return response.data;
};

export const deleteReservation = async (id: any, token: string) => {
	console.log('------ deleteReservations ------');
	const response = await api.delete<ReservationsResult[]>(
		`/reservation/${id}`,
		{
			headers: {
				...commonHeaders,
				Authorization: `Bearer ${token}`,
			},
		},
	);
	if (response.status !== 200) {
		throw Error((response.data as any).error);
	}
	return response.data;
};
