import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { category } from './CategoryData';
import { getBanners } from '../../strapi/banner';
import qs from 'qs';
import TitleSection from '../../components/shared/TitleSection/titleSection';
import LoaderComponent from '../../components/shared/Loader/Loader.component';

const CategoryScreen = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [banner, setBanner] = useState<any>(null);

  const _renderSubcategories = (subcategories: any) => subcategories.map((subcategory: any, index: number) => (
    <Link className='category-option' key={index} to={subcategory.action.screen}>
      <figure className='option-icon'>
        <img src={subcategory.icon} alt={subcategory.name} />
      </figure>
      {subcategory.name}
    </Link>
  ));

  const fetchBanners = async () => {
    setBanner(null);
    setIsLoading(true);
    try {
      let queryString = qs.stringify({
        sort: 'Title:ASC',
        filters: {
          Title: {
            $containsi: id ? category[id].name : '',
          },
        },
        populate: {
          image: { populate: '*' },
        },
      });

      const response = await getBanners(queryString);
      setBanner(response.data[0]);
    } catch (error) {
      console.error('Error al obtener banners:', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (id) {
        fetchBanners();
      }
    }

    return () => { isMounted = false; };
  }, [id]);

  return (
    <>
      <div className="category-title">
        <h1>{banner?.attributes?.Title}</h1>
      </div>

      <div className="category-banner">
        <figure>
          <LoaderComponent isLoading={isLoading} />
          {banner && <img src={banner.attributes.image.data.attributes.url} alt={banner.attributes.Title} />}
        </figure>
      </div>

      <div className='container'>
        <div className="category-options col-12 col-md-9 mx-auto">
          {id && _renderSubcategories(category[id].subcategories)}
        </div>

        {id && category[id].action && (
          <div className="col-12 text-center mb-5 pb-5">
            <Link to={category[id].action?.screen} className="btn btn--type1 px-4 mx-auto">{category[id].action?.label}</Link>
          </div>
        )}
      </div>
    </>
  );
};

export default CategoryScreen;