import { useEffect, useState } from "react";
import HeaderBottom from "../../components/shared/HeaderBottom/HeaderBottom.component";
import SubHeaderComponent from "../../components/shared/Subheader/subHeader.component";
// import addNotification from 'react-push-notification';
// import { Notifications } from 'react-push-notification';

const Settings = (props: any) => {
  const [promo, setPromo] = useState(true);
  const [message, setMessage] = useState(true);
  const [reminder, setReminder] = useState(true);
  const [location, setLocation] = useState(true);

  const handleChangePromo = () => {
    setPromo(val => {
      const newVal = !val;

      if (newVal) {
        // addNotification({
        //   title: 'Warning',
        //   native: true
        // })
      }

      return newVal
    });
  }

  const handleChangeMessage = () => {
    setMessage(val => {
      const newVal = !val;

      if (newVal) {
        // addNotification({
        //   title: 'Warning',
        //   native: true
        // })
      }

      return newVal
    });
  };

  const handleChangeReminder = () => {
    if (reminder) {
      // addNotification({
      //   title: 'Warning',
      //   native: true
      // })
    }
  }

  const handleChangeLocation = () => {
  }

  useEffect(() => {
  }, [])

  return (
    <>
      <SubHeaderComponent isBackButton={true} buttonText={"Regresar"} href={""} logo={false} />
      <HeaderBottom>Ajustes</HeaderBottom >
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 mx-auto">
                <div className="config">
                  <div className="text-left mb-2">
                    Notificaciones
                  </div>

                  <div className="config__item">
                    <span>Promo Push Notifications</span>
                    <div className="form-control-switch">
                      <label htmlFor="promo" onClick={() => {
                        handleChangePromo();
                      }} className={`${promo ? "active" : ""}`}>
                        <input type="checkbox" name="promo" checked={promo} onChange={handleChangePromo} />
                        <i />
                      </label>
                    </div>
                  </div>

                  <div className="config__item">
                    <span>Message Push Notifications</span>
                    <div className="form-control-switch">
                      <label htmlFor="message" onClick={handleChangeMessage} className={`${message ? "active" : ""}`}>
                        <input type="checkbox" name="message" checked={message} />
                        <i />
                      </label>
                    </div>
                  </div>

                  <div className="config__item">
                    <span>Reminder Push Notifications</span>
                    <div className="form-control-switch">
                      <label htmlFor="reminder" onClick={() => setReminder(reminder => !reminder)} className={`${reminder ? "active" : ""}`}>
                        <input type="checkbox" name="reminder" checked={reminder} onChange={handleChangeReminder} />
                        <i />
                      </label>
                    </div>
                  </div>

                  <div className="text-left mb-2">
                    Ubicación
                  </div>

                  <div className="config__item">
                    <span>Ubicación en segundo plano</span>
                    <div className="form-control-switch">
                      <label htmlFor="location" onClick={() => setLocation(loc => !loc)} className={`${location ? "active" : ""}`}>
                        <input type="checkbox" name="location" checked={location} onChange={handleChangeLocation} />
                        <i />
                      </label>
                    </div>
                  </div>

                  <div className="note">
                    SOMA+ solicita permiso para rastrear tu ubicación con el fin de ofrecerte un servicio personalizado y mejorar tu experiencia.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Settings