import Cookies from "js-cookie";
import { addHours } from "date-fns";
import { IUser } from "../types/user";

const COOKIE_NAME = "SomaPlusCookie";

export const saveSession = async (user: Partial<IUser>) => {
  const expireDate = addHours(new Date(), 5);
  Cookies.set(
    COOKIE_NAME,
    JSON.stringify({
      ...user,
    }),
    {
      expires: expireDate,
    }
  );
  localStorage.setItem("userInfo", JSON.stringify(user));
};

export const getSession = () => {
  const data = Cookies.get(COOKIE_NAME); // sin sesión
  return data ? (JSON.parse(data) as IUser) : null;
};

export const updateSession = (user: IUser) => {
  Cookies.remove(COOKIE_NAME);
  Cookies.set(COOKIE_NAME, JSON.stringify({ ...user }));
};

export const deleteSession = () => {
  Cookies.remove(COOKIE_NAME);
};
