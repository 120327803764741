import React, { useState } from 'react';
import HeaderBottom from '../../components/shared/HeaderBottom/HeaderBottom.component';
import SubHeaderComponent from '../../components/shared/Subheader/subHeader.component';

const EditNotificationsScreen = () => {

  const [promo, setPromo] = useState(true);
  const [message, setMessage] = useState(true);
  const [reminder, setReminder] = useState(true);

  const handleChangePromo = () => {

  }
  const handleChangeMessage = () => {

  }
  const handleChangeReminder = () => {

  }


  return (
    <>
      <SubHeaderComponent isBackButton={true} buttonText={"Regresar"} href={""} logo={false} />
      <HeaderBottom>Notificaciones</HeaderBottom >
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-12 mx-auto">
                <div className="config">
                  <div className="config__item">
                    <span>Promo Push Notifications</span>
                    <div className="form-control-switch">
                      <label htmlFor="promo" onClick={() => setPromo(promo => !promo)} className={`${promo ? "active" : ""}`}>
                        <input type="checkbox" name="promo" checked={promo} onChange={handleChangePromo} />
                        <i />
                      </label>
                    </div>
                  </div>
                  <div className="config__item">
                    <span>Message Push Notifications</span>
                    <div className="form-control-switch">
                      <label htmlFor="message" onClick={() => setMessage(message => !message)} className={`${message ? "active" : ""}`}>
                        <input type="checkbox" name="message" checked={message} onChange={handleChangeMessage} />
                        <i />
                      </label>
                    </div>
                  </div>
                  <div className="config__item">
                    <span>Reminder Push Notifications</span>
                    <div className="form-control-switch">
                      <label htmlFor="reminder" onClick={() => setReminder(reminder => !reminder)} className={`${reminder ? "active" : ""}`}>
                        <input type="checkbox" name="reminder" checked={reminder} onChange={handleChangeReminder} />
                        <i />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}

export default EditNotificationsScreen;
