import { useContext, useEffect, useState } from "react";
import { PromosResult } from "../../types/entidades";
import { IPromosFavorites } from "../../types/favorites";
import qs from "qs";
import { Link, useParams, useNavigate } from "react-router-dom";
import AppContext from "../../context/appContext";
import HeaderBottom from "../../components/shared/HeaderBottom/HeaderBottom.component";
import { getEvents } from '../../strapi/events';
import EventsSection from "../../components/shared/EventsSection/EventsSection.component";

const AllEventsScreen = () => {
  const navigate = useNavigate();
  const { category } = useParams();
  const { userInfo } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sort, setSort] = useState<string>('id:asc');
  const [reload, setReload] = useState<string>('');
  const [events, setEvents] = useState<PromosResult[]>([]);
  const [listFav, setListFav] = useState<Partial<IPromosFavorites[]>>([]);

  const fetchEvents = async () => {
    setIsLoading(true);

    try {
      const queryString = qs.stringify({
        sort: 'Date:ASC',
        populate: { image: { populate: '*' }, name: { populate: '*' } },
      });
      const response = await getEvents(queryString);
      setEvents(response.data);
      console.log('events', response.data);
    } catch (error) {
      console.error('Error al obtener eventos:', error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      fetchEvents();
    }
  }, [category, userInfo, sort, reload]);

  return (
    <>
      <HeaderBottom
        children={
          <Link to="#" className="back-link" onClick={goBack}>
            <span className="material-icons-outlined">chevron_left</span>
            Regresar
          </Link>
        }
      />

      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-12 mx-auto mb-5">
                <EventsSection
                  titleSection={category || ""}
                  userName={userInfo?.name || ''}
                  hasFavoriteFilter={false}
                  promoCards={events}
                  user={userInfo || {}}
                  setSort={setSort}
                  isLoading={isLoading}
                  setReload={setReload}
                />
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default AllEventsScreen;