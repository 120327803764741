import { useContext } from "react";
import AppContext from "../../../context/appContext";
import { signOut } from "aws-amplify/auth";
import { useState, useEffect } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { IUser } from "../../../types/user";
import { deleteSession } from "../../../utils/helpers";
import { searchReservations } from "../../../api/reservationsApi";
import { menuOptions } from "./HeaderData";
import { routeList, hideHeader } from "../../../utils/validations";
import { getCurrentAuthenticatedUser } from "../../../utils/utils";

interface headerProps {
  updateUserInfo?: (info: any) => void;
}

const HeaderComponent = ({ updateUserInfo }: headerProps) => {
  const [userOptionsActive, setUserOptionsActive] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth <= 768;
  const [currentUser, setCurrentUser] = useState<Partial<IUser>>({});
  const { userInfo, handleUserInfo } = useContext(AppContext);
  const { option } = useParams();

  const getNotifications = async () => {
    const today = new Date().toISOString();

    const body = {
      userId: userInfo?._id || "",
      date: today,
    };

    try {
      const data = await searchReservations(body, userInfo?.token || "");
      if (userInfo) {
        if (userInfo.notificationCount !== data.length) {
          handleUserInfo({
            ...userInfo,
            notificationCount: data.length,
          });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleActive = (slug: string) => {
    // console.log('OPTIONS', option)
    if (option === slug) {
      return "active";
    }
  };

  const _renderMenuOptions = (options: any) => options.map((option: any, index: number) => (
    <li key={index}>
      <NavLink to={option.slug} className={`${handleActive(option.name)}`}>
        <img src={option?.image} alt={option?.name} />
        <span>{option.name}</span>
      </NavLink>
    </li>
  ));

  // Handle user info
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      const savedUserInfo = localStorage.getItem("userInfo");
      if (!userInfo && savedUserInfo) {
        handleUserInfo(JSON.parse(savedUserInfo));
      }
    }

    return () => {
      isMounted = false;
    };
  });

  // Handle notifications
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (userInfo && userInfo.token && userInfo._id) {
        getNotifications();
      }
    }

    return () => {
      isMounted = false;
    };
  });

  useEffect(() => {
    if (!currentUser._id) {
      getCurrentAuthenticatedUser().then((authUser) => {
        if (authUser) {
          setCurrentUser(authUser.somaUser);
        }
      });
    }
  });

  const handleUserButtonClick = () => {
    setUserOptionsActive(!userOptionsActive);
  };

  const handleUserOptionsMouseLeave = () => {
    setUserOptionsActive(false);
  };

  useEffect(() => {
    // Función para manejar el evento de cambio de tamaño de pantalla
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Agregar el listener al evento de cambio de tamaño de pantalla
    window.addEventListener("resize", handleResize);

    // Eliminar el listener cuando el componente se desmonte
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const whatsappUrl = `whatsapp://send?phone=+525566097673&text=Hola, soy ${userInfo?.name + ' ' + userInfo?.lastName || ''} - (${userInfo?._id || ''}) quiero hablar con mi Concierge.`;


  // if (!userInfo || hideHeader(routeList)) return null;

  return (
    <>
      {userInfo && (
        <header className="header header--main">
          <div className="container">
            <div className="row header__row-bottom">
              <div className="col-12">
                <nav className="main-nav">
                  <ul>
                    {_renderMenuOptions(menuOptions)}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default HeaderComponent;
