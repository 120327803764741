import { SingleGenericResult } from '../types/entidades';
import api from './index';
import qs from 'qs';

export const getAnStrapiEntry = async (route: string) => {
	const query = {
		populate: '*',
	};
	const response = await api.get<SingleGenericResult>(
		`${route}?${qs.stringify(query)}`,
	);
	if (response.status !== 200) {
		throw Error((response.data as any).error);
	}
	return response.data;
};
