import React, { useContext, useEffect, useState } from "react";
import PromosSection from "../../components/shared/PromosSection/PromosSection.component";
import { PromosResult } from "../../types/entidades";
import { IPromosFavorites } from "../../types/favorites";
import { searchPromosFavorites } from "../../api/promosFavorites";
import qs from "qs";
import { getPromos } from "../../strapi/promos";
import { Link, useParams, useNavigate } from "react-router-dom";
import AppContext from "../../context/appContext";
import HeaderBottom from "../../components/shared/HeaderBottom/HeaderBottom.component";

const SubcategoryScreen = () => {
  const navigate = useNavigate();
  const { category, subcategory, option } = useParams();
  const { userInfo } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [sort, setSort] = useState<string>('id:asc');
  const [reload, setReload] = useState<string>('');
  const [brands, setBrands] = useState<PromosResult[]>([]);
  const [listFav, setListFav] = useState<Partial<IPromosFavorites[]>>([]);

  const promos = async (user: any, token: string, loader: boolean = true) => {
    if (loader) setIsLoading(true);
    let ands = {
      brand: {
        category: { Name: { $eq: category } },
        subcategories: { Name: { $eq: subcategory } }
      }
    };

    const query = {
      sort: [sort || "id:asc"],
      populate: "*",
      filters: ands,
      pagination: {
        pageSize: 300,
      },
    };
    const result = await getPromos(qs.stringify({ ...query }));

    // setBrands(result.data as PromosResult[]);
    getFavorites(user, token, result.data as PromosResult[]);
  };

  const getFavorites = async (somaUser: any, token: string, resultBrands: PromosResult[]) => {
    searchPromosFavorites(
      { userId: somaUser._id, isFavorite: true },
      token
    ).then((pFavorite) => {
      console.log('favorites', pFavorite);
      setListFav(pFavorite);
      resultBrands.forEach((brand) => {
        const isFavorite = pFavorite.some(
          (favorite) => favorite.promoId === brand?.id
        );
        brand.attributes.isFavorite = isFavorite;
      });
      setBrands(resultBrands);
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      if (userInfo && userInfo.token && category && subcategory) {
        promos(userInfo, userInfo.token, false);
      }
    }
  }, [category, subcategory, userInfo, sort, reload]);

  return (
    <>
      <HeaderBottom
        children={
          <Link to="#" className="back-link" onClick={goBack}>
            <span className="material-icons-outlined">chevron_left</span>
            Regresar
          </Link>
        }
      />

      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-12 mx-auto mb-5">
                <PromosSection
                  titleSection={subcategory || ""}
                  userName={userInfo?.name || ''}
                  hasFavoriteFilter={true}
                  promoCards={brands}
                  user={userInfo || {}}
                  setSort={setSort}
                  isLoading={isLoading}
                  setReload={setReload}
                  option={option}
                />
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default SubcategoryScreen;