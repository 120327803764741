import { useEffect, useState } from 'react';
import TitleSection from '../../components/shared/TitleSection/titleSection';
import { useContext } from 'react';
import AppContext from '../../context/appContext';
import LoaderComponent from '../../components/shared/Loader/Loader.component';
import { searchValetByUserId } from '../../api/valetParkingApi';
import { IUser } from '../../types/user';
import { IValet } from '../../types/valetParking';
import SimpleTable from '../../components/Table/SimpleTable/SimpleTable';
import { Attributes } from '../../types/cinemas';
import { getAnStrapiEntry } from '../../strapi/contents';
import { set } from 'date-fns';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'

const ValetParkingScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null | any>(null);
  const { userInfo } = useContext(AppContext);
  const [valetData, setValetData] = useState<Partial<IValet>>({} as IValet);
  const [entry, setEntry] = useState<any>(null);

  const getEntry = async () => {
    try {
      const result = await getAnStrapiEntry('/valet-parking');

      if (result.data.attributes) {
        const valetParking = Object.values(result.data.attributes);
        setEntry(result.data.attributes);
      }

    }
    catch (error) {
      console.log('ERROR', error);
    }
  };

  // Get valet data
  const getValetData = async (userInfo: IUser | null) => {
    //  if user token exists
    if (userInfo && userInfo.token) {
      try {
        const data = await searchValetByUserId(1, 0, { userId: userInfo._id, salida: 0 }, userInfo.token);
        setValetData(data.docs[0]);
        setError(null);
      }
      catch (error) {
        setError(error);
        console.log('ERROR', error);
      }
      finally {
        setIsLoading(false);
      }

      return;
    }

    setIsLoading(false);
  };

  // If user has valet, show valet data
  const hasValet = (valet: Partial<IValet>) => {
    if (userInfo && userInfo._id) {
      return valet &&
        Object.keys(valet).length > 0 &&
        valet.userId === userInfo._id;
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getValetData(userInfo);
      getEntry();
    }

    return () => { isMounted = false; };
  }, [userInfo]);

  // Show loader while fetching data
  if (isLoading) return (<LoaderComponent isLoading={isLoading} />);


  // If user has no valet, show valet parking request
  if (!hasValet(valetData)) return (
    <section className="dashboard-layout">
      <section className="module">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 mx-auto">
              <TitleSection text={'Valet Parking'} />
              <article className="mb-5">
                <h1 className="h3 mb-5">{entry?.ValetInText}</h1>
                <Markdown remarkPlugins={[remarkGfm]}>{entry?.ValetIn}</Markdown>
              </article>
            </div>
          </div>
        </div>
      </section>
    </section>
  );

  // If user has valet, show valet data
  return (
    <section className="dashboard-layout">
      <section className="module">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-12 mx-auto">
              <TitleSection text={'Valet Parking'} />
              <article className="mb-5">
                <h1 className="h3 mb-5">{entry?.ValetOutTitle}</h1>
                <Markdown remarkPlugins={[remarkGfm]}>{entry?.ValetOut}</Markdown>
              </article>

              <p>Información del coche</p>
              <SimpleTable className="mb-5" data={{
                rows: [
                  ['Marca:', valetData?.auto?.marca],
                  ['Modelo:', valetData?.auto?.modelo],
                  ['Color:', valetData?.auto?.color],
                  ['Placas:', valetData?.auto?.placas],
                ]
              }} />

              <a href="/valet-parking-success" className="btn btn--type1 btn--100">Pedir mi carro</a>

            </div>
          </div>
        </div>
      </section>
    </section>
  );
}

export default ValetParkingScreen;
