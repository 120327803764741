import React, { useContext, useEffect, useState } from "react";
import TitleSection from "../../components/shared/TitleSection/titleSection";
import { getCurrentAuthenticatedUser } from "../../utils/utils";
import { IUser } from "../../types/user";
import {
  deleteReservation,
  searchReservations,
} from "../../api/reservationsApi";
import { ReservationsResult } from "../../types/entidades";
import { format, fromUnixTime } from "date-fns";
import AppContext from "../../context/appContext";

const NotificationsScreen = () => {
  const [notification, setNotification] = useState<
    Partial<ReservationsResult[]>
  >([]);
  const [user, setUser] = useState<Partial<IUser>>({});
  const { userInfo, handleUserInfo } = useContext(AppContext);

  const searchReservation = (somaUser?: any, t?: string) => {
    const sUser = somaUser || user;
    const token = t || user.token;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const body = {
      userId: sUser._id,
      date: today.toString(),
    };

    searchReservations(body, token || "").then((res) => {
      // Sort by coming date and hour
      const resSort = res.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        if (dateA > dateB) return 1;
        if (dateA < dateB) return -1;
        if (a.time > b.time) return 1;
        if (a.time < b.time) return -1;
        return 0;
      });

      console.log(res);
      setNotification(resSort);
    });
  };

  const handleDeleteNotification = async (idReserv: any) => {
    deleteReservation(idReserv, user.token || "").then((res) => {
      searchReservation();
      if (userInfo) handleUserInfo({ ...userInfo });
    });
  };

  useEffect(() => {
    getCurrentAuthenticatedUser().then((authUser) => {
      const token = authUser?.jwtToken || "";
      setUser({
        ...authUser?.somaUser,
        token,
      });
      searchReservation(authUser?.somaUser, token);
    });
  }, []);

  return (
    <>
      <section className="dashboard-layout">
        <section className="module">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12 mx-auto">
                <TitleSection text={"Notificaciones"} />
                <div className="notifications-list">
                  {notification.length === 0 && (
                    <p className="text-center">No tienes notificaciones</p>
                  )}

                  {notification.map((notf, k) => (
                    <div className="notifications-list__item" key={k}>
                      {notf?.date && (
                        <>
                          {/* <button
                            className="notifications__delete"
                            onClick={() => {
                              handleDeleteNotification(notf._id);
                            }}
                          >
                            <span className="material-icons-outlined">
                              delete
                            </span>
                          </button> */}
                          <h6>{`${notf?.place}`}</h6>
                          <article>
                            <p>
                              Tu solicitud de reservación se realizó correctamente en{" "}
                              {notf?.place}
                            </p>
                          </article>

                          <div className="notification-item__status mb-3">
                            {notf?.isConfirm && (
                              <>
                                <span className="point active" />
                                <span className="text">Tu reservación ha sido confirmada.</span>
                              </>
                            )}

                            {notf?.isConfirm === undefined && (
                              <>
                                <span className="point" />
                                <span className="text">Reservacion pendiente de confirmación.</span>
                              </>
                            )}

                            {notf?.isConfirm === false && (
                              <>
                                <span className="point canceled" />
                                <span className="text">Tu reservación ha sido cancelada.</span>
                              </>
                            )}
                          </div>

                          <div className="notification-item__date">
                            <p>
                              <small>{`${format(
                                new Date(notf.date),
                                "dd/MM/yyy"
                              )}`}</small>
                            </p>
                            <p>
                              <small>{`${format(
                                fromUnixTime(notf.time),
                                "HH:mm"
                              )}`}</small>
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default NotificationsScreen;
