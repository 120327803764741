import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { resetPassword } from "aws-amplify/auth";
import { IUser } from "../../types/user";
import { getCurrentAuthenticatedUser } from "../../utils/utils";
import HeaderBottom from "../../components/shared/HeaderBottom/HeaderBottom.component";
import SubHeaderComponent from "../../components/shared/Subheader/subHeader.component";

const EditPasswordScreen = () => {
  const navigate = useNavigate();
  const [coincidence, setCoincidence] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [pswdError, setPswdError] = useState(<></>);
  const [currentUser, setCurrentUser] = useState<Partial<IUser>>({});

  const setError = (errorMessage: string) => {
    console.log("errorMessage", errorMessage);
    Swal.fire("Error", `${errorMessage}`, "error");
  };

  const updateUserPassword = async () => {
    const { password, confirmPassword } = currentUser;
    if (!passwordValid) {
      return setError("Please fill all fields");
    }
    if (password !== confirmPassword) {
      return setError("Passwords don't match");
    }
    try {
      const user = await getCurrentAuthenticatedUser();
      await resetPassword({
        username: user?.somaUser.phone || "",
        options: {
          password,
        },
      });
      Swal.fire("Contraseña actualizada", "", "success");
      setTimeout(() => {
        navigate("/mi-perfil");
      }, 1000);
    } catch (error) {
      setError(`${error}`);
    }
  };

  useEffect(() => {
    if (!Object.keys(currentUser).length) {
      getCurrentAuthenticatedUser()
        .then((result) => {
          if (result) {
            const { somaUser, jwtToken } = result;
            if (somaUser) {
              setCurrentUser({ ...somaUser, token: jwtToken });
            }
          }
        })
        .catch((error) => {
          setError(error as any);
        });
    }
  });

  const handlePasswordChange = (event: any) => {
    const newPassword = event.target.value;
    setCurrentUser({
      ...currentUser,
      password: newPassword,
    });

    // Validar las nuevas condiciones de la contraseña
    const hasUpperCaseLetter = /[A-Z]/.test(newPassword);
    const hasLowerCaseLetter = /[a-z]/.test(newPassword);
    const hasNumberOrSymbol = /[0-9!@#$%^&*]/.test(newPassword);

    setPasswordValid(
      newPassword.length >= 8 &&
      hasUpperCaseLetter &&
      hasLowerCaseLetter &&
      hasNumberOrSymbol
    );

    if (newPassword.length < 8)
      setPswdError(
        <>
          La contraseña debe tener al menos <strong>8 caracteres</strong>
        </>
      );
    if (!hasUpperCaseLetter)
      setPswdError(
        <>
          La contraseña debe contener al menos{" "}
          <strong>una letra mayuscula</strong>
        </>
      );
    if (!hasLowerCaseLetter)
      setPswdError(
        <>
          La contraseña debe contener al menos{" "}
          <strong>una letra minúscula</strong>
        </>
      );
    if (!hasNumberOrSymbol)
      setPswdError(
        <>
          La contraseña debe contener al menos{" "}
          <strong>un número o un símbolo</strong>
        </>
      );
  };

  const handleConfirmChange = (event: any) => {
    const newConfirm = event.target.value;
    setCurrentUser({
      ...currentUser,
      confirmPassword: newConfirm,
    });
    if (newConfirm !== currentUser.password) {
      setCoincidence(false);
    } else {
      setCoincidence(true);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    updateUserPassword();
  };

  return (
    <>
      <SubHeaderComponent isBackButton={true} buttonText={"Regresar"} href={""} logo={false} />
      <HeaderBottom>Contraseña</HeaderBottom>
      <section className="dashboard-layout">
        <section className="module">
          <div className="container container--profile-form">
            <div className="row">
              <div className=" col-12 mx-auto">
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 mb-4">
                      <div className="form-row">
                        <label>
                          <small>Nueva Contraseña</small>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          value={currentUser.password}
                          onChange={(e: any) => handlePasswordChange(e)}
                          placeholder="Contraseña"
                          required
                        />
                        {!passwordValid && (
                          <div className="alert alert-danger">{pswdError}</div>
                        )}
                      </div>
                    </div>
                    <div className="col-12 mb-4">
                      <div className="form-row">
                        <label>
                          <small>Confirmar contraseña</small>
                        </label>
                        <input
                          type="password"
                          className="form-control"
                          value={currentUser.confirmPassword}
                          onChange={(e: any) => handleConfirmChange(e)}
                          placeholder="Confirmar contraseña"
                          required
                        />
                        <div
                          className={`alert alert-danger ${coincidence ? "hidden" : ""
                            }`}
                        >
                          Las contraseñas no coinciden
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 mt-4">
                      <button
                        className="btn btn--type1 btn--100"
                        type="reset"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Cancelar
                      </button>
                    </div>
                    <div className="col-6 mt-4">
                      <button className="btn btn--type1 btn--100" type="submit">
                        Guardar
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default EditPasswordScreen;
