import React, { useState, useEffect } from "react";

const Searcher = ({ label, section, data, setData, setSort, sortOptions = true, className }: any) => {
  const [query, setQuery] = useState<string>("");

  const handleChange = (event: any) => {
    setQuery(event.target.value);
  };

  const handleSortChange = (event: any) => {
    if (setSort) setSort(event.target.value);
  };

  const handleSearch = () => {
    // Favorites
    if (section === "favorite") {
      const filteredData = data.filter((item: any) => {
        if (item.attributes.isFavorite) {
          return item.attributes.Title.toLowerCase().includes(
            query.toLowerCase()
          );
        }
      });

      if (setData) setData(filteredData);
      return filteredData;
    }

    const filteredData = data.filter((item: any) => {
      return item.attributes.Title.toLowerCase().includes(query.toLowerCase());
    });

    if (setData) setData(filteredData);
    return filteredData;
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      handleSearch();
    }
    return () => {
      isMounted = false;
    };
  });

  return (
    <div className={`form-row input-group searcher mb-4 ${className}`}>
      <input
        type="text"
        className="form-control"
        placeholder={label || "Search any brand"}
        onChange={handleChange}
      />
      {sortOptions && (
        <select
          className="form-select"
          aria-label="Sort"
          onChange={handleSortChange}
        >
          <option selected>Sort</option>
          <option value="Title:asc">A-Z</option>
          <option value="Title:desc">Z-A</option>
          <option value="id:desc">Newest</option>
          <option value="id:asc">Oldest</option>
        </select>
      )}
    </div>
  );
};

export default Searcher;
