interface TabProps {
  children: any;
  onClick: () => void;
  isActive: boolean;
};

const Tab = ({ children, onClick, isActive }: TabProps) => {
  return <button
    type="button"
    className={`tab ${isActive ? "active" : ""}`}
    onClick={onClick}
  >
    {children}
  </button>
};

export default Tab;